import { useQuery } from "@tanstack/react-query";
import React from "react";
import { GetRoleIndexApi } from "../../apis/getroleindexapi/GetRoleIndexApi";
import SelectTTPPage from "../ttpppage/SelectTTPPage";
import PendingApplications from "../ttpppage/PendingApplications";
import SubmittedMessage from "../ttpppage/SubmittedMessage";

function IndiaGetRole() {
  var loginemail = JSON.parse(localStorage.getItem("india_user"))?.email;
  // console.log(loginemail, "login");

  const { data } = useQuery(
    ["application-after-coordinator", loginemail],
    GetRoleIndexApi,
    {
      select: (data) => data?.data,
    }
  );

  var messagedata = data?.message;

  if (data?.message === 0) {
    return (
      <div>
        <SelectTTPPage></SelectTTPPage>
      </div>
    );
  } else if (data?.message === 2) {
    return (
      <div>
        <SubmittedMessage />
      </div>
    );
  } else {
    return (
      <div>
        <PendingApplications data={messagedata} />
      </div>
    );
  }
}

export default IndiaGetRole;

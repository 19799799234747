import React, { useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import image1 from "../../assets/images/404image.png";
function NotFound() {
  const role = parseInt(localStorage.getItem("role"));
  console.log(role, "role in 404");
  const navigate = useNavigate();
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (role == 1) {
        navigate("/Index");
      } else if (role == 2) {
        navigate("/RecommendedTeacher_page1");
      } else if (role == 3) {
        navigate("/EvaluatorPage1");
      } else if (role == 4) {
        navigate("/country_coordinatorpage1");
      } else if (role == 5) {
        navigate("/india_sahaj_Coordinatorpage1");
      } else if (role == 6) {
        navigate("/TtpDeskPage1");
      } else {
        navigate("/");
      }
    }, 2000);
    return () => {
      clearTimeout(timeoutId);
    };
  });

  return (
    <div className="flex flex-col h-screen items-center bg-[#daf2f2]">
      <div className="flex w-[600px] flex-col mt-20 bg-[#daf2f2] p-8">
        <div className="flex justify-end text-[90px] text-[#ef9058]">Sorry</div>
        <div className="flex justify-end text-[25px]">
          You do not have access to this page
        </div>
        <div className="flex justify-end text-[20px] text-[#4460D1]">
          Go To Your Dashboard
        </div>
        <div className="mt-5">
          <img src={image1}></img>
        </div>
      </div>
    </div>
  );
}

export default NotFound;

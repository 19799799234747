import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  statecountry: "",
};

export const StateDropdownReducer = createSlice({
  name: "statecountry",
  initialState: initialState,
  reducers: {
    CountryValue: (state, action) => {
      state.statecountry = action.payload;
    },
  },
});

export const { CountryValue } = StateDropdownReducer.actions;
export default StateDropdownReducer.reducer;
/**
 * this component is used to display the info of how to upload the audio and video link in the application form
 */
import * as React from "react";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import { tooltipClasses } from "@mui/material/Tooltip";

export default function ImportantNote({ note }) {
  const CustomWidthTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))({
    [`& .${tooltipClasses.tooltip}`]: {
      maxWidth: 800,
      fontSize: 16,
    },
  });

  const Info = ({ note }) => {
    return (
      <div className="p-2">
        <div className="font-bold">Instructions to upload {note} link</div>
        <div className="mt-1">1. Go to https://drive.google.com</div>
        <div>
          2. Login to your Google account, if no account then create one.
        </div>
        <div>3. Upload the file in the Google drive.</div>
        <div>
          4. Once uploaded, copy and paste the link in the space provided.
        </div>
      </div>
    );
  };

  return (
    <CustomWidthTooltip title={<Info note={note} />} placement="right" arrow>
      <IconButton>
        <InfoOutlinedIcon fontSize="large" />
      </IconButton>
    </CustomWidthTooltip>
  );
}

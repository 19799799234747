import React from "react";
import Formfield from "../../commomComponents/form/Formfield";
import Textinput from "../../commomComponents/form/textinputfield/Textinput";
import logo from "../../assets/images/artofliving_logo.png";
import { Button } from "@mui/material";
import * as yup from "yup";
import swal from "sweetalert";
import { useMutation } from "@tanstack/react-query";
import { IndiaLoginApi } from "../../apis/auth/IndiaLoginApi";
import { useNavigate } from "react-router";
import { getRole } from "../../apis/auth/RoleApi";
import { RoleData } from "../../redux/reducer/roledata/RoleReducer";
import { useDispatch } from "react-redux";
import { Navigate } from "react-router-dom";
import IndiaGetRole from "../getrole/IndiaGetRole";

function IndiaLoginPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { mutateAsync } = useMutation(IndiaLoginApi);
  const IndiaLoginValidation = yup.object().shape({
    TeacherCode: yup.string().required(),
    Password: yup.string().required(),
  });

  const handleSubmit = async (data) => {
    const postData = {
      name: data?.TeacherCode,
      pass: data?.Password,
    };
    try {
      const data = await mutateAsync(postData);
      
      if (data?.data?.login == "Success") {
        const params = {
          email: data?.data?.email,
          name: data?.data?.name,
          phone: data?.data?.phone,
          country: "India",
        };

        localStorage.setItem("india_user", JSON.stringify(params));
        localStorage.setItem("user_type", true);

        const { data: response } = await getRole(params);

        const { role } = response;
        localStorage.setItem("india_role", JSON.stringify(role));
        dispatch(RoleData(role));

        if (role === 1) {
          return <IndiaGetRole />;
        } else if (role === 2) {
          return <Navigate to="/RecommendedTeacher_page1" />;
        } else if (role === 3) {
          return <Navigate to="/EvaluatorPage1" />;
        } else if (role === 4) {
          return <Navigate to="/sahaj_coordinator_page1" />;
        } else if (role === 5) {
          return <Navigate to="/country_Coordinatorpage1" />;
        } else if (role === 6) {
          return <Navigate to="/india_sahaj_Coordinatorpage1" />;
        } else if (role === 7) {
          return <Navigate to="/TtpDeskPage1" />;
        } else {
          return <Navigate to="/" />;
        }
      } else {
        await swal("Invalid Email/Password/Country Name", { icon: "error" });
        navigate(0);
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div className="m-auto bg-[pink] mt-[40px]" id="india-form-section">
      <div
        className="flex flex-col w-[460px] h-auto shadow-lg items-center justify-center p-6 rounded-[5px] bg-[white]"
        id="india-login-form-section"
      >
        <img src={logo} alt="logo" id="india-logo-img"></img>

        <hr className="w-[420px] mt-4" id="divider" />

        <Formfield onSubmit={handleSubmit} schema={IndiaLoginValidation}>
          <div id="india-form-feilds">
            <div className="text-[#898989] mt-4" id="teacherCode-label">
              Teacher Code
            </div>
            <Textinput
              name="TeacherCode"
              className="w-[420px] mt-4"
              size="small"
              id="teacherCode-input"
            />
          </div>

          <div id="login-password-fields">
            <div className="text-[#898989] mt-4" id="password-label">
              Password
            </div>
            <Textinput
              name="Password"
              label="Password"
              className="w-[420px] mt-8"
              size="small"
              type="password"
              id="india-password-input"
            />
          </div>

          {/* <div
            className="flex w-full justify-end py-4 text-[18px] text-[#1976d2]"
            id="form-forgotfield"
            onClick={handleForgotPassword}
          >
            <div className="cursor-pointer" id="forgot-password">
              Forgot Password?
            </div>
          </div> */}

          <Button
            variant="contained"
            className="w-[420px] h-[40px] text[16px] !mt-[20px]"
            type="submit"
            id="login-submit-button"
          >
            Login
          </Button>
        </Formfield>
      </div>
    </div>
  );
}

export default IndiaLoginPage;

import axios from "axios";
import { BASE_URL_NODE } from "../../config";

export const TrackApplicationsApi = async (params) => {
  const Searchvalue = params.queryKey[1];
  return await axios.get(
    `${BASE_URL_NODE}/trackapplications`,
    { params: { email: Searchvalue } }
  );
};

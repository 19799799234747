import * as React from "react";
import "./Recommendedteacherpage1.css";
import { useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import Navbar from "../../components/navbar/Navbar";
import CustomNoRowsOverlay from "../../commomComponents/form/noformdata/NoFormData";
import { PendingRecommendations } from "../../apis/pendingrecommendations/PendingRecommendations";

function Recommendedteacherpage1() {
  const email =
    JSON.parse(localStorage.getItem("user"))?.username ||
    JSON.parse(localStorage?.getItem("india_user"))?.email;

  const { data = [] } = useQuery(
    [`application-data-recommended-${email}`, email],
    PendingRecommendations,
    {
      select: (data) => data?.data,
    }
  );

  const navigate = useNavigate();

  return (
    <div className="flex flex-col w-[100%] h-screen">
      <div className="flex">
        <Navbar track={5}></Navbar>
      </div>
      <div className=" flex flex-col items-center h-auto justify-center">
        {data.length === 0 ? (
          <div className="flex text-[20px] font-semibold mt-40 justify-center ">
            <CustomNoRowsOverlay theory="You don't have any pending recommendations" />
          </div>
        ) : (
          <div className="flex  flex-col items-center h-auto justify-center">
            <div className="flex text-[20px] font-semibold  mt-8 -ml-[300px]">
              Pending Recommendations
            </div>
            <div className="w-[600px] h-[700px] overflow-x-auto scrollbar mt-10 ml-10">
              <table>
                <tr>
                  <th>Applicant Name</th>
                  <th>Country</th>
                  <th>Application</th>
                </tr>
                <tbody>
                  {data.map((item) => (
                    <tr key={item.id}>
                      <td>{item.name}</td>
                      <td>{item.country}</td>
                      <td>
                        <a
                          href={`/RecommendedTeacher_page2?email=${item?.harmony_email}`}
                          className="text-[skyblue] underline cursor-pointer"
                          target="_blank"
                          rel="noopener noreferrer"
                          onClick={(e) => {
                            e.preventDefault();
                            // localStorage.setItem(
                            //   "emailData",
                            //   JSON.stringify(item?.harmony_email)
                            // );
                            window.open(`/RecommendedTeacher_page2?email=${item?.harmony_email}`, "_blank");
                          }}
                        >
                          View application & recommend applicant
                        </a>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Recommendedteacherpage1;

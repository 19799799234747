import React from "react";
import { Select, MenuItem, FormControl, FormHelperText } from "@mui/material";
import { useController } from "react-hook-form";
import { useQuery } from "@tanstack/react-query";

function SelectDropdown({
  register,
  id,
  label,
  name,
  control,
  className,
  options,
  placeholder,
  params,
  getoptionpromise = () => {},
  getOptionLabel,
  getOptionValue,
  ...rest
}) {
  const { data: option = {} } = useQuery([name, params], getoptionpromise, {
    select: (data) => data.data,
  });
  const {
    field: { value, onChange },
    fieldState: { error },
  } = useController({
    name,
    control,
    rules: { required: true },
  });

  return (
    <div className="">
      <FormControl error={false}>
        <Select
          {...rest}
          onChange={(e) => {
            // onChange(e.target.value);
            onChange(e.target.value);
          }}
          value={value}
          error={error}
          placeholder={placeholder}
          size="small"
          id={id}
          className={className}
          MenuProps={{ PaperProps: { style: { maxHeight: "200px" } } }}
        >
          {Object.keys(option)?.map((opt, Index) => {
            return (
              <MenuItem value={getOptionValue(option, opt)} key={Index}>
                {option[opt]}
              </MenuItem>
            );
          })}
        </Select>
        <FormHelperText>
          {error && <div className="text-[#D43535]">{error.message}</div>}
        </FormHelperText>
      </FormControl>
    </div>
  );
}

export default SelectDropdown;

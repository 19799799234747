import React from "react";

import PagesFooter from "../PagesFooter";
import { Phonecodecomponent } from "./PhonecodeComponent";

import Textinput from "../../../commomComponents/form/textinputfield/Textinput";
import Radiogroup from "../../../commomComponents/form/radiobuttons/Radiogroup";
import Datefield from "../../../commomComponents/form/basicdatepicker/BasicDatePicker";
import SelectDropdown from "../../../commomComponents/form/singleselectdropdown/SelectDropdown";
import StateDropdownData from "../../../commomComponents/form/singleselectdropdown/StateDropdownData";

import { CountryDropdownApi } from "../../../apis/countrydropdownapi/CountryDropdownApi";
// import ImageField from "../../../commomComponents/form/imagefield/ImageField";
import CropImageField from "../../../commomComponents/form/imagefield/CropImageField";

const Page1 = () => {
  const options = [
    { label: "Male", value: "male" },
    { label: "Female", value: "female" },
    { label: "Other", value: "other" },
  ];

  return (
    <div id="page1">
      <div id="page1field">
        <div className="flex flex-col gap-6 px-2" id="page1form-fields">
          <div className="flex items-center mt-2" id="applicant-name-field">
            <div className="text-[16px] flex w-64" id="applicant-name-label">
              Applicant's Name*
            </div>
            <Textinput
              name="name"
              label="Applicant's Name"
              className="w-[300px]"
              size="small"
              id="applicant-name-input"
            />
          </div>

          <div className="flex items-center mt-2" id="date-of-birth-field">
            <div className="flex w-64" id="date-of-birth-label">
              Date Of Birth*
            </div>
            <Datefield
              name="date_of_birth"
              label="DateOfBirth"
              className="w-[300px]"
              size="small"
              disableFuture={true}
              disablepast={false}
              id="date-of-birth-input"
            />
          </div>

          <div className="flex" id="gender-field">
            <div className="flex w-64" id="gender-label">
              Gender*
            </div>
            <Radiogroup
              name="gender"
              label="Gender"
              options={options}
              id="gender-radiogroup"
            ></Radiogroup>
          </div>

          <div className="flex items-center mt-2" id="applicant-name-field">
            <div className="text-[16px] flex w-64" id="applicant-name-label">
              Upload Image*
            </div>
            {/* <ImageField
              name="image"
              type="file"
              className="w-[300px]"
              size="small"
              id="applicant-image"
            /> */}
            <CropImageField
              name="image"
              type="file"
              className="w-[300px]"
              size="small"
              id="applicant-image"
            />
          </div>

          <div className="flex mt-1" id="address-field">
            <div className="text-[16px] flex w-64" id="address-label">
              Address*
            </div>
            <Textinput
              name="address"
              label="Address"
              placeholder="Address"
              className="w-[400px]"
              size="small"
              rows={2}
              max={2}
              lines={true}
              id="address-input"
            />
          </div>

          <div className="flex items-center mt-4" id="country-field">
            <div className="text-[16px] flex w-64" id="country-label">
              Country*
            </div>
            <SelectDropdown
              name="country"
              label="Select Country"
              placeholder="Select country"
              className="w-[300px] mt-2"
              getoptionpromise={CountryDropdownApi}
              getOptionValue={(options, opt) => options[opt]}
              id="page1-country-dropdown"
            />
          </div>

          <div className="flex items-center mt-4" id="state-field">
            <div className="text-[16px] flex w-64" id="state-label">
              State*
            </div>
            <StateDropdownData id="state-dropdown" />
          </div>

          <div className="flex items-center mt-4" id="city-field">
            <div className="text-[16px] flex w-64" id="city-label">
              City*
            </div>
            <Textinput
              name="city"
              placeholder="Enter the city name"
              className="w-[300px]"
              size="small"
              id="city-input"
            />
          </div>

          <div className="flex items-center mt-2" id="postal-field">
            <div className="text-[16px] flex w-64" id="postal-label">
              Postal Code*
            </div>
            <Textinput
              name="postal_code"
              className="w-[300px]"
              size="small"
              id="postal-input"
            />
          </div>

          <div className="flex items-center mt-2" id="email-field">
            <div className="text-[16px] flex w-64" id="email-label">
              Email*
            </div>
            <Textinput
              name="email"
              label="email"
              className="w-[300px]"
              size="small"
              id="email-input"
            />
          </div>

          <div className="flex flex-col mt-2" id="phone-field">
            <div className="flex" id="phonecode-field">
              <div className="text-[16px] flex w-64" id="phone-label">
                Phone*
              </div>
              <Phonecodecomponent name="country_code" id="phone-code-field" />
              <Textinput
                name="phone"
                label="Phone"
                className="w-[240px]"
                size="small"
                type="number"
                onKeyDown={(evt) => evt.key === "e" && evt.preventDefault()}
                id="phone-input"
              />
            </div>
            <div className="text-[14px]" id="message-field">
              <i>***Preferably Whatsapp Number</i>
            </div>
          </div>

          <div id="page1-footer-field">
            <PagesFooter page={1} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Page1;

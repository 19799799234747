import React from "react";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import Navbar from "../../components/navbar/Navbar";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import "./PendingApplication.css";

const columns = [
  {
    field: "id",
    headerName: "Id",
    width: 100,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "name",
    headerName: "TTP Description",
    width: 250,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "start_date",
    headerName: "Starting Date",
    width: 200,
    headerClassName: "super-app-theme--header",
  },
  {
    field: "harmony_email",
    headerName: "Email",
    width: 200,
    headerClassName: "super-app-theme--header",
    editable: true,
  },
];

const customFooterStyles = `
  .MuiDataGrid-footerContainer {
    display: none;
  }
`;
const footerStyles = {
  "& .MuiDataGrid-footer": {
    display: "none",
  },
};

function PendingApplications({ data }) {
  const navigate = useNavigate();

  return (
    <div className="w-[100%] h-screen bg-[whitesmoke]">
      <Navbar track={1}></Navbar>
      <div className="flex flex-col justify-center items-center gap-8">
        <div className="flex justify-center items-center mt-28">
          <div className="flex">
            <Box
              sx={{
                height: 164,
                width: "100%",
                "& .super-app-theme--header": {
                  backgroundColor: "rgba(0.5, 0.5, 0.5, 0.2)",
                },
                fontWeight: 900,
                fontSize: 16,
                ...footerStyles,
              }}
            >
              <style>{customFooterStyles}</style>
              {data ? (
                <DataGrid
                  rows={data}
                  columns={columns}
                  pageSize={1}
                  showPagination={false}
                  pagination={false}
                  hideFooterPagination
                  hideFooter
                  disableColumnMenu
                  rowCount={data?.length}
                  hideFooterSelectedRowCount={true}
                />
              ) : (
                "No Application Found"
              )}
            </Box>
          </div>
        </div>
        <div>
          <Button
            variant="contained"
            size="large"
            color="primary"
            onClick={() => navigate("/Index")}
          >
            Resume Application
          </Button>
        </div>
      </div>
    </div>
  );
}

export default PendingApplications;

import * as yup from "yup";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import logo from "../../assets/images/artofliving_logo.png";

import Formfield from "../../commomComponents/form/Formfield";
import SelectDropdown from "../../commomComponents/form/singleselectdropdown/SelectDropdown";

import Navbar from "../navbar/Navbar";
import { Button } from "@mui/material";
import { CircularProgress } from "@mui/material";
import { TtpInfoApi } from "../../apis/ttpinfoapi/TtpInfoApi";

const schema = yup.object().shape({
  ttpinfo: yup.string().required("Select TTP is required field"),
});

function SelectTTPPage() {
  const navigate = useNavigate();
  const { isLoading } = useMutation();

  const onSubmit = async (data) => {
    const role_info = data?.ttpinfo;
    localStorage.setItem("role_info", role_info);
    navigate("/Index");
  };
  

  return (
    <div className="flex h-auto min-h-screen bg-[whitesmoke]">
      <div className=" flex flex-col w-[100%] h-auto">
        <div>
          {" "}
          <Navbar track={1}></Navbar>
        </div>
        <div className="m-auto">
          <div className="flex flex-col w-[460px] h-auto shadow-lg items-center p-6 rounded-[10px] bg-[white]">
            <img src={logo} alt="logo"></img>
            <hr className="w-[420px] mt-4" />
            <Formfield onSubmit={onSubmit} schema={schema}>
              <div className="mt-16">
                <div className="text-[#898989] mt-4">Select TTP*</div>
                <SelectDropdown
                  name="ttpinfo"
                  label="Select Country"
                  className="w-[420px] h-[40px] mt-2"
                  getoptionpromise={TtpInfoApi}
                  getOptionValue={(options, opt) => opt}
                />
              </div>

              <div className="mt-8">
                <Button
                  variant="contained"
                  className="w-[420px] h-[40px] text-[16px]"
                  type="submit"
                  disabled={isLoading}
                >
                  {isLoading ? <CircularProgress size={24} /> : "Apply"}
                </Button>
              </div>
            </Formfield>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SelectTTPPage;

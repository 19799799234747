import { request } from "../../utils/AxiosInstance";
import { CountryDropdownApi } from "../countrydropdownapi/CountryDropdownApi";

export const TtpInfoApi = async () => {
  const country = JSON.parse(localStorage.getItem("user"))?.db_country;
  const { data: countryresponse } = await CountryDropdownApi();
  const countryName = countryresponse[country] || "India"; // when the user is login with the india directly the countryName is india other wise the countryName is getting from the country api dropdown example : we getting db_country in short cut
  return request({
    url: `/ttpinfo/${countryName}`,
  });
};

import React from "react";
import Textinput from "../../commomComponents/form/textinputfield/Textinput";
import { useController } from "react-hook-form";
import { FormControl } from "@mui/material";
function Textfieldpage1({ name, dependentName, disabled, id }) {
  const {
    field: { value },
  } = useController({ name: dependentName });

  return (
    <div>
      {value === true && (
        <FormControl disabled={disabled} error={true}>
          <Textinput
            name={name}
            id={id}
            label="Course date, Venue, Teacher Name"
            placeholder="Course date, Venue, Teacher Name"
            className="w-[300px]"
            size="small"
            disabled={disabled}
          />
        </FormControl>
      )}
    </div>
  );
}

export default Textfieldpage1;

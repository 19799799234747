import React from "react";
import "../src/App.css";
import { useSelector } from "react-redux";
import {
  Routes,
  Route,
  Navigate,
  useLocation,
  useNavigate,
} from "react-router-dom";

import ForgotPassword from "../src/components/forgotpassword/ForgotPassword";
import IndiaForgotPassword from "../src/components/forgotpassword/IndiaForgotPassword";
import TrackApplication from "../src/ttpdesk/trackapplication/TrackApplication.";
import SubmitApplicationPage from "./components/ttcapplicationform/submitapplicationpage/SubmitApplicationPage";

import TtcApplicationFormIndexPage from "./components/ttcapplicationform";

import EvaluatorPage1 from "../src/evaluator/evaluatorapplicationpage1/EvaluatorPage1";
import EvaluatorApplication from "./evaluator/EvaluatorApplication/EvaluatorApplication";
import EvaluatorHistoryForm from "./evaluator/evaluatorhistoryform/EvaluatorHistoryForm";

import RecommendedTeacherPage1 from "./recommendedteacher/recommendedteacherpage1/RecommendedTeacherPage1";
import RecommendedTeacherPage2 from "./recommendedteacher/recommendedteacherpage2/RecommendedTeacherPage2";

import CountryCoordinatorPage1 from "./country_coordinator/country_coordinatorpage1/Country_CoordinatorPage1";
import CountryCoordinatorPage2 from "./country_coordinator/country_coordinatorpage2/Country_CoordinatorPage2";

import IndiaSahajDeskCoordinator from "./indiansahajttp/india_sahaj_desk_coordinator/IndiaSahajDeskCoordinator";
import IndianSahajDeskCoordinatorPage1 from "./indiansahajttp/india_sahaj_desk_coordinator_page1/IndianSahajDeskCoordinatorPage1";

import TtpDeskPage1 from "./ttpdesk/ttpdeskpage1/TtpDeskPage1";
import TtpDeskPage2 from "./ttpdesk/ttpdeskpage2/TtpDeskPage2";

import SahajCoordinatorPage1 from "./sahajcoordinator/sahajcoordinatorpage1/SahajCoordinatorPage1";
import SahajCoordinatorPage2 from "./sahajcoordinator/sahajcoordinatorpage2/SahajCoordinatorPage2";

import NotFound from "./pages/404/404";

import Navbar from "./components/navbar/Navbar";
import AssignRole from "./ttpdesk/assignrole/AssignRole";
import AnnounceTTP from "./ttpdesk/announcettp/AnnounceTTP";
import AnnounceTtpTable from "./ttpdesk/announcettptable/AnnonceTtpTable";
import SelectTTPPage from "./components/ttpppage/SelectTTPPage";
import GetRole from "./components/getrole/GetRole";
import HistoryForm from "./commomComponents/historyform/HistoryForm";
import SelectCountry from "./components/login/SelectCountry";
import IndiaGetRole from "./components/getrole/IndiaGetRole";
import AssignEvaluator from "./ttpdesk/assignevaluator/AssignEvaluator";

export default function App() {
  const role =
    parseInt(localStorage.getItem("role")) ||
    parseInt(localStorage.getItem("india_role"));
  // const role = ;

  const { role_data } = useSelector((state) => state.role_data);
  console.log(role_data, "roleeeeeee");

  return (
    <div>
      <Routes>
        <Route
          path="/"
          element={
            <CustomComponent>
              <SelectCountry />
            </CustomComponent>
          }
        ></Route>
        <Route path="*" element={<NotFound />}></Route>
        <Route path="/Forgot_Password" element={<ForgotPassword />}></Route>
        <Route
          path="/India_Forgot_Password"
          element={<IndiaForgotPassword />}
        ></Route>

        {role === 1 ? (
          <>
            {" "}
            <Route path="/Navbar" element={<Navbar />}></Route>
            <Route path="/role_page" element={<SelectTTPPage />}></Route>
            <Route
              path="/Index"
              element={
                <CustomComponent>
                  <TtcApplicationFormIndexPage />
                  {/* <div>hiiii</div> */}
                </CustomComponent>
              }
            ></Route>
            <Route
              path="/submitsuccessfully"
              element={<SubmitApplicationPage />}
            ></Route>
          </>
        ) : (
          ""
        )}

        {role === 2 ? (
          <>
            <Route path="/Navbar" element={<Navbar />}></Route>
            <Route
              path="RecommendedTeacher_page1"
              element={<RecommendedTeacherPage1 />}
            ></Route>
            <Route
              path="RecommendedTeacher_page2"
              element={<RecommendedTeacherPage2 />}
            ></Route>
            <Route path="/history" element={<HistoryForm />}></Route>
          </>
        ) : (
          ""
        )}

        {role === 3 ? (
          <>
            <Route path="/Navbar" element={<Navbar />}></Route>
            <Route path="/EvaluatorPage1" element={<EvaluatorPage1 />}></Route>
            <Route
              path="/EvaluatorApplication"
              element={<EvaluatorApplication />}
            ></Route>
            <Route
              path="/evaluation_history"
              element={<EvaluatorHistoryForm />}
            ></Route>
          </>
        ) : (
          ""
        )}

        {role === 4 ? (
          <>
            <Route path="/Navbar" element={<Navbar />}></Route>
            <Route
              path="/sahaj_coordinator_page1"
              element={<SahajCoordinatorPage1 />}
            ></Route>
            <Route
              path="/sahaj_coordinator_page2"
              element={<SahajCoordinatorPage2 />}
            ></Route>
            <Route path="/history" element={<HistoryForm />}></Route>
          </>
        ) : (
          ""
        )}

        {role === 5 ? (
          <>
            <Route path="/Navbar" element={<Navbar />}></Route>
            <Route
              path="country_Coordinatorpage1"
              element={<CountryCoordinatorPage1 />}
            ></Route>
            <Route
              path="country_Coordinatorpage2"
              element={<CountryCoordinatorPage2 />}
            ></Route>
            <Route path="/history" element={<HistoryForm />}></Route>
          </>
        ) : (
          ""
        )}

        {role === 6 ? (
          <>
            <Route path="/Navbar" element={<Navbar />}></Route>
            <Route
              path="india_sahaj_Coordinatorpage1"
              element={<IndianSahajDeskCoordinatorPage1 />}
            ></Route>
            <Route
              path="india_sahaj_Coordinatorpage2"
              element={<IndiaSahajDeskCoordinator />}
            ></Route>
            <Route path="/history" element={<HistoryForm />}></Route>
          </>
        ) : (
          ""
        )}

        {role === 7 ? (
          <>
            <Route path="Navbar" element={<Navbar />}></Route>
            <Route path="assignEvalautor" element={<AssignEvaluator />}></Route>
            <Route path="Assignrole" element={<AssignRole />}></Route>
            <Route path="Announcettp" element={<AnnounceTTP />}></Route>
            <Route
              path="Announcedttp_programs"
              element={<AnnounceTtpTable />}
            ></Route>
            <Route path="TtpDeskPage1" element={<TtpDeskPage1 />}></Route>
            <Route path="TtpDeskPage2" element={<TtpDeskPage2 />}></Route>
            <Route
              path="TrackApplication"
              element={<TrackApplication />}
            ></Route>
          </>
        ) : (
          ""
        )}
      </Routes>
    </div>
  );
}
const CustomComponent = ({ children }) => {
  // const navigate = useNavigate;
  const params = useLocation();
  let userType = localStorage.getItem("user_type");
  // other than india
  if (userType === null || userType === false) {
    const role = parseInt(localStorage.getItem("role"));
    if (localStorage.getItem("user")) {
      if (params.pathname === "/") {
        if (role === 1) {
          return <GetRole />;
        } else if (role === 2) {
          return <Navigate to="/RecommendedTeacher_page1" />;
        } else if (role === 3) {
          return <Navigate to="/EvaluatorPage1" />;
        } else if (role === 4) {
          return <Navigate to="/sahaj_coordinator_page1" />;
        } else if (role === 5) {
          return <Navigate to="/country_Coordinatorpage1" />;
        } else if (role === 6) {
          return <Navigate to="/india_sahaj_Coordinatorpage1" />;
        } else if (role === 7) {
          return <Navigate to="/TtpDeskPage1" />;
        } else {
          return <Navigate to="/" />;
        }
      } else {
        return <div>{children}</div>;
      }
    } else {
      if (params.pathname === "/") {
        return <div>{children}</div>;
      }
      return <Navigate to="/" />;
    }
  } else {
    const role = parseInt(localStorage.getItem("india_role"));
    if (localStorage.getItem("india_user")) {
      if (params.pathname === "/") {
        if (role === 1) {
          return <IndiaGetRole />;
        } else if (role === 2) {
          return <Navigate to="/RecommendedTeacher_page1" />;
        } else if (role === 3) {
          return <Navigate to="/EvaluatorPage1" />;
        } else if (role === 4) {
          return <Navigate to="/sahaj_coordinator_page1" />;
        } else if (role === 5) {
          return <Navigate to="/country_Coordinatorpage1" />;
        } else if (role === 6) {
          return <Navigate to="/india_sahaj_Coordinatorpage1" />;
        } else if (role === 7) {
          return <Navigate to="/TtpDeskPage1" />;
        } else {
          return <Navigate to="/" />;
        }
      } else {
        return <div>{children}</div>;
      }
    } else {
      if (params.pathname === "/") {
        return <div>{children}</div>;
      }
      return <Navigate to="/" />;
    }
  }
};

import axios from "axios";
import { BASE_URL_NODE } from "../config";
// import { getAccessToken } from "./getAccessToken";

const client = axios.create({ baseURL: BASE_URL_NODE});
// const defaultAccessToken = null;

export const request = async ({ ...options }) => {
  //   const accessToken = defaultAccessToken || getAccessToken();

  //   client.defaults.headers.common.authorization = `Bearer ${accessToken}`;

  const onSuccess = (response) => {
    return Promise.resolve({
      data: response.data,
    });
  };

  const onError = (error) => {
    return Promise.reject(error);
  };

  try {
    const response = await client(options);
    return onSuccess(response);
  } catch (error) {
    return onError(error);
  }
};

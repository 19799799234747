import React from "react";
import { useController } from "react-hook-form";
function LinkTextinput({
  register,
  label,
  name,
  control,
  className,
  lines,
  rows,
  type,
  max,
  getOptionLabel,
  getOptionValue = (val) => val,
  disabled,
  inputprops,
  ...rest
}) {
  const {
    field: { value, onChange },
    fieldState: { error },
  } = useController({
    name,
    control,
  });

  return (
    <div className="border border-[#BCBBBB] p-2 w-[300px] text-[skyblue] pointer-cursor rounded-[5px] truncate">
      <a rel="noreferrer" href={value} target="_blank">{value}</a>
    </div>
  );
}
export default LinkTextinput;

import React, { useEffect } from "react";
import swal from "sweetalert";

import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useFormContext } from "react-hook-form";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { Button, CircularProgress } from "@mui/material";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";

import {
  IncreamentCurrentStep,
  decreamntCurrentStep,
} from "../../redux/reducer/StepperReducer";
import { ApplicationError } from "../../redux/reducer/formerror/FormErrors";
import { TextViewerBox } from "../../redux/reducer/textviewer/TextViewerReducer";

import { PreProcessApplicantPostData } from "../../utils/PreProcessApplicantPostData";

import { updateRoleApi } from "../../apis/updateroleapi/UpdateroleApi";
import { ApplicationDataSendApi } from "../../apis/applicationdatasendapi/ApplicationDataSendApi";
import { BASE_URL_NODE } from "../../config";
import axios from "axios";

function PagesFooter({ page }) {
  const dispatch = useDispatch();

  const Navigate = useNavigate();
  const {
    getValues,
    formState: { errors },
    trigger,
    watch,
  } = useFormContext();

  const { mutateAsync, isLoading } = useMutation(ApplicationDataSendApi);
  const { mutateAsync: mutateSaveApplication, isLoading: saveisLoading } =
    useMutation(ApplicationDataSendApi);
  const { mutateAsync: updateRoleMutateAsync } = useMutation(updateRoleApi);

  const formatMonth = (month) => {
    return month < 10 ? `0${month}` : month;
  };
  const formatDate = (date) => {
    return date < 10 ? `0${date}` : date;
  };

  const queryClient = useQueryClient();

  const handleSaveData = async () => {
    // scrollToTop();

    var data = getValues();
    data = PreProcessApplicantPostData(data);

    let response;
    const ApplicantEmail =
    JSON.parse(localStorage.getItem("user"))?.username ||
    JSON.parse(localStorage.getItem("india_user"))?.email
    try{
      response = await axios.get(
        `${BASE_URL_NODE}/application/${ApplicantEmail}`
      );
      }catch{}

    const draftdate = new Date().toISOString().substring(0, 10);

    const dataofbirth =
      data && new Date(data?.date_of_birth)
        ? new Date(data?.date_of_birth)?.getFullYear() +
          "-" +
          formatMonth(new Date(data?.date_of_birth)?.getMonth() + 1) +
          "-" +
          formatDate(new Date(data?.date_of_birth)?.getDate())
        : "";

    const teacherSince =
      data && new Date(data?.teacher_since)
        ? new Date(data?.teacher_since)?.getFullYear() +
          "-" +
          formatMonth(new Date(data?.teacher_since)?.getMonth() + 1) +
          "-" +
          formatDate(new Date(data?.teacher_since)?.getDate())
        : "";

    // console.log("data?.image?.url,",data?.image?.url,)

    const url = data?.image?.url

    if (data.image) {
      delete data.image;
    }

    var savedata = {
      ...data,
      profile_picture:url,
      date_of_birth: dataofbirth === "NaN-NaN-NaN" ? "1900-01-01" : dataofbirth,
      teacher_since:
        teacherSince === "NaN-NaN-NaN" ? "1900-01-01" : teacherSince,
      application_status: 1,
      harmony_email:
        JSON.parse(localStorage.getItem("user"))?.username ||
        JSON.parse(localStorage.getItem("india_user"))?.email,
      draft_timestamp: draftdate,
      ttp_info_id: parseInt(localStorage.getItem("role_info")) || response?.data?.ttp_info_id,
    };
    

    // //<-------------------------Api call for save and draft of the application--------------------->
    try {
      const res = await mutateSaveApplication(savedata);
      if(res?.ok)
      {
        console.log("hellooo")
        await queryClient.invalidateQueries({
          queryKey: ["image"],
        });
      }
      console.log("send save data api", res);
    } catch (error) {
      console.log(error, "error is");
    }
  };



 
  const watchvalue = watch();
  useEffect(() => {
    if (watchvalue?.physical?.walking === true) {
      watchvalue.walking = true;
    }
    dispatch(ApplicationError(watchvalue));
  }, [watchvalue]);

  const handleClickSubmit = async () => {

    //this function is used to scroll the screen upwards
    scrollToTop();

    // make the api call because i wnat ttp_info id from the appliction data when i click save previously and logout

    let response;
    const ApplicantEmail =
    JSON.parse(localStorage.getItem("user"))?.username ||
    JSON.parse(localStorage.getItem("india_user"))?.email
    try{
      response = await axios.get(
        `${BASE_URL_NODE}/application/${ApplicantEmail}`
      );
      }catch{}


      // trigger is used to find all the form values

    const isAllFieldsFilled = await trigger();

    if (!isAllFieldsFilled) {
      dispatch(ApplicationError(errors));
      dispatch(TextViewerBox(true));
    } else {
      var data = getValues();

      data = PreProcessApplicantPostData(data);

      const date = new Date().toISOString().substring(0, 10);

      const dataofbirth =
        data && new Date(data.date_of_birth)
          ? new Date(data.date_of_birth)?.getFullYear() +
            "-" +
            formatMonth(new Date(data.date_of_birth)?.getMonth() + 1) +
            "-" +
            formatDate(new Date(data.date_of_birth)?.getDate())
          : "";

      const teacherSince =
        data && new Date(data.teacher_since)
          ? new Date(data.teacher_since)?.getFullYear() +
            "-" +
            formatMonth(new Date(data.teacher_since)?.getMonth() + 1) +
            "-" +
            formatDate(new Date(data.teacher_since)?.getDate())
          : "";


      //postdata is used to send tha application data to the api i.e, submitapplication api

      const url = data?.image?.url

      if (data.image) {
        delete data.image;
      }
      
      
      var postData = {
        ...data,
        profile_picture:url,
        date_of_birth:
          dataofbirth === "NaN-NaN-NaN" ? "1900-01-01" : dataofbirth,
        teacher_since:
          teacherSince === "NaN-NaN-NaN" ? "1900-01-01" : teacherSince,
        applicant_teacher_code: "string",
        applicant_country: "string",
        recommending_teacher_remarks: "",
        evaluator_remarks: "",
        country_coordinator_remarks: "",
        sahaj_coordinator_remarks: "",
        ttp_office_remarks: "",
        application_status: 2,
        application_journey: 1,
        submit_timestamp: date,
        harmony_email:
          JSON.parse(localStorage.getItem("user"))?.username ||
          JSON.parse(localStorage.getItem("india_user"))?.email,
        ttp_info_id: parseInt(localStorage.getItem("role_info")) || response?.data?.ttp_info_id,
        medical_treatment_details: "",
        psychiatric_treatment_details: "",
      };

      // <------------------------Api for submit application---------------------->

      try {
        const resData = await mutateAsync(postData);
        if (resData.ok) {
          await swal("Submitted successfully", { icon: "success" });

          var postUpdatedata = {
            teacher_code: postData.applicant_teacher_code,
            name: postData.name,
            phone: postData.phone,
            email: postData.email,
            country: postData.country,
            role: 1,
          };

          // <----------------------- Api call for update role -------------------->

          try {
            const res = await updateRoleMutateAsync(postUpdatedata);
            console.log(res)
            Navigate("/submitsuccessfully");
          } catch (error) {
            console.log(error, "error is");
          }
        }
      } catch (error) {
        swal("Something went wrong!!!!!!", { icon: "error" });
      }
    }
  };

  //<---------------------Scrollup function when we click next/previous/submit/save---------------------->

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleNextClick = () => {
    dispatch(IncreamentCurrentStep());
    scrollToTop();
  };

  const handlePreviousClick = () => {
    dispatch(decreamntCurrentStep());
    scrollToTop();
  };


  return (
    <div>
      <div className="flex gap-5">
        {page !== 1 && (
          <div className=" bg-gray-300 rounded-[5px] mb-3">
            <Button
              startIcon={
                <ArrowCircleLeftIcon
                  style={{
                    color: "black",
                    fontSize: "28px",
                    textAlign: "center",
                  }}
                />
              }
              onClick={handlePreviousClick}
              style={{
                color: "black",
                display: "flex",
                alignItems: "center",
                width: "130px",
                height: "40px",
              }}
            >
              Previous
            </Button>
          </div>
        )}

        {page !== 4 && (
          <div className=" bg-gray-300 text-center rounded-[5px] mb-3">
            <Button
              endIcon={
                <ArrowCircleRightIcon
                  style={{
                    color: "black",
                    fontSize: "28px",
                    textAlign: "center",
                  }}
                />
              }
              onClick={handleNextClick}
              style={{
                color: "black",
                display: "flex",
                alignItems: "center",
                width: "90px",
                height: "40px",
              }}
            >
              Next
            </Button>
          </div>
        )}

        <div className=" bg-gray-300 text-center rounded-[5px] mb-3">
          <Button
            startIcon={
              <SaveOutlinedIcon
                style={{
                  color: "black",
                  fontSize: "20px",
                  textAlign: "center",
                }}
              />
            }
            style={{
              color: "black",
              display: "flex",
              alignItems: "center",
              width: "90px",
              height: "40px",
              disabled: { saveisLoading },
            }}
            onClick={() => handleSaveData()}
          >
            {saveisLoading ? <CircularProgress size="24px" /> : "Save"}
          </Button>
        </div>

        {page === 4 && (
          <div>
            <Button
              type="submit"
              variant="contained"
              color="success"
              disabled={isLoading}
              onClick={() => {
                handleClickSubmit();
              }}
              startIcon={<CheckCircleRoundedIcon />}
            >
              {isLoading ? (
                <CircularProgress size="24px" />
              ) : (
                "Submit Application"
              )}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}

export default PagesFooter;

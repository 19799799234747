import React from "react";
import { useController } from "react-hook-form";
import {
  RadioGroup,
  FormControlLabel,
  Radio,
  FormHelperText,
  FormControl,
} from "@mui/material";

function Radiogroup({
  id,
  label,
  name,
  control,
  options,
  disabled = false,
  direction,
  getOptionValue = (val) => val,
  ...rest
}) {
  const {
    field: { value, onChange },
    fieldState: { error },
  } = useController({
    name,
    control,
    rules: { required: true },
  });

  return (
    <div>
      <FormControl disabled={disabled} error={true}>
        <RadioGroup
          id={id}
          value={value}
          onChange={(e) => {
            onChange(getOptionValue(e.target.value)); //getOptionValue is callback function
          }}
          row={direction}
        >
          {/* <div className="flex flex-col"> */}
          {options.map((option) => (
            <FormControlLabel
              key={option.value}
              value={option.value}
              control={<Radio />}
              label={option.label}
            />
          ))}
          {/* </div> */}
        </RadioGroup>

        <FormHelperText>
          {error && <div className="text-[#D43535]">{error.message}</div>}
        </FormHelperText>
      </FormControl>
    </div>
  );
}

export default Radiogroup;

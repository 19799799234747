import * as yup from "yup";
import swal from "sweetalert";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import logo from "../../assets/images/artofliving_logo.png";

import Textinput from "../../commomComponents/form/textinputfield/Textinput";
import Formfield from "../../commomComponents/form/Formfield";
import SelectDropdown from "../../commomComponents/form/singleselectdropdown/SelectDropdown";

import { ForgotPasswordApi } from "../../apis/auth/ForgotPasswordApi";
import { CountryDropdownApi } from "../../apis/countrydropdownapi/CountryDropdownApi";

import { Button } from "@mui/material";
import { CircularProgress } from "@mui/material";
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import Navbar from "../navbar/Navbar";

const schema = yup.object().shape({
  Email: yup.string().email().required(),
});

function ForgotPassword() {
  const navigate = useNavigate();
  const { mutateAsync, isLoading } = useMutation(ForgotPasswordApi);

  const onSubmit = async (data) => {
    var datainfo = {
      username: data.Email,
      db_country: data.country_name,
    };

    try {
      const { data } = await mutateAsync(datainfo);
      const res = data.response_message;

      if (res === "ok") {
        await swal(
          "We've sent a password reset link to your email. Please check your email to access the link",
          { icon: "success" }
        );
        navigate("/");
      }
    } catch (error) {
      await swal("Invalid Email/Country Name", { icon: "error" });
      navigate(0);
    }
  };

  return (
    <div className="flex h-auto min-h-screen bg-[whitesmoke]" id="forgot-form">
      <div className="flex flex-col w-[100%] h-auto" id="forgotpage">
        <div id="forgot-page-navabar">
          <Navbar track={3}></Navbar>
        </div>
        <div className="m-auto" id="forgotpage-form">
          <div
            className="flex flex-col w-[460px] h-auto shadow-lg items-center p-6 rounded-[10px] bg-[white]"
            id="forgotpassword-card"
          >
            <img src={logo} alt="logo" id="forgot-password-logo"></img>
            <hr className="w-[420px] mt-4" id="forgotpage-driver" />
            <div
              className="font-[bold] text-[32px] mt-5"
              id="forgotpage-heading"
            >
              Forgot Password
            </div>
            <Formfield onSubmit={onSubmit} schema={schema}>
              <div>
                <div className=" " id="formfield-1">
                  <div className="text-[#898989]" id="forgot-email-label">
                    Email
                  </div>
                  <Textinput
                    name="Email"
                    label="Email"
                    className="w-[420px]"
                    size="small"
                    id="forgotpage-email-input"
                  />
                </div>
                <div>
                  {" "}
                  <i
                    className="font-semibold text-[red] text-[12px]"
                    id="login-note"
                  >
                    ***Harmony Email
                  </i>
                </div>
              </div>

              <div className="countryfield">
                <div className="text-[#898989] mt-4" id="country-field-label">
                  Select Country
                </div>
                <SelectDropdown
                  name="country_name"
                  label="Select Country"
                  className="w-[420px] h-[40px] mt-2"
                  getoptionpromise={CountryDropdownApi}
                  getOptionValue={(options, opt) => opt}
                  id="country-field-dropdown"
                />
              </div>

              <div className="mt-8">
                <Button
                  variant="contained"
                  className="w-[420px] h-[40px] text-[16px]"
                  type="submit"
                  disabled={isLoading}
                  id="forgotpage-button"
                >
                  {isLoading ? <CircularProgress size={24} /> : "Submit"}
                </Button>
              </div>
            </Formfield>
          </div>
          <div
            className="flex bg-[black] bg-opacity-10 rounded-lg mb-[650px] absolute left-10 top-[40px] cursor-pointer w-[45px] h-[45px] rounded-[45px] justify-center items-center"
            id="forgotpage-backbutton"
          >
            <ArrowBackRoundedIcon
              color="black"
              size="large"
              onClick={() => navigate("/")}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ForgotPassword;

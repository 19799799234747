import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { ButtonBase, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { ttpalldata } from "../../redux/reducer/announcettpupdate/AnnounceTTPData";

export default function Togglebuttonswitch({ params }) {
  const dispatch = useDispatch();

  var { ttp_all_data } = useSelector((state) => state?.ttp_all_data);

  const value = ttp_all_data.find(
    (ttp_all_data) => ttp_all_data.id === params.id
  );

  const handleChange = (event, alignment) => {
    if (alignment !== null) {
      const updatedData = ttp_all_data.map((item) =>
        item.id === params.id ? { ...item, registration_open: alignment } : item
      );
      dispatch(ttpalldata(updatedData));
    }
  };

  const handleButtonClick = (event, newValue) => {
    if (newValue === false && value?.registration_open === false) {
      return;
    }
    handleChange(event, newValue);
  };

  return (
    <ToggleButtonGroup
      color="primary"
      value={value?.registration_open}
      exclusive
      onChange={handleChange}
      aria-label="Platform"
      size="small"
    >
      <ToggleButton
        value={true}
        color="success"
        component={ButtonBase}
        disableRipple
        onClick={handleButtonClick}
      >
        Open
      </ToggleButton>
      <ToggleButton
        value={false}
        color="error"
        component={ButtonBase}
        disableRipple
        onClick={handleButtonClick}
      >
        Close
      </ToggleButton>
    </ToggleButtonGroup>
  );
}

import * as yup from "yup";
import swal from "sweetalert";
import React from "react";
import { useSelector } from "react-redux";

import { Button } from "@mui/material";

import Formfield from "../../commomComponents/form/Formfield";
import SelectDropdown from "../../commomComponents/form/singleselectdropdown/SelectDropdown";

import { Evaluators } from "../../apis/evaluators/Evaluators";

import { AssignEvaluatorApi } from "../../apis/assignevaluatorapi/AssignEvaluatorApi";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";

function AssignEvaluatorDropdown({ handleClose, selectedRows }) {
  const { mutateAsync } = useMutation(AssignEvaluatorApi);
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const schema = yup.object().shape({
    evaluator_email: yup
      .string()
      .matches(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        "Enter the valid email"
      )
      .required("Evaluator field is required"),
  });

  const { assignEvaluator } = useSelector((state) => state.assignEvaluator);

  const onSubmit = async (data) => {
    const postEvaluatorData = {
      evaluator_email: data?.evaluator_email,
      ids: assignEvaluator,
    };

    const res = await mutateAsync(postEvaluatorData);
    try {
      if (res.ok) {
        await swal("Assign Evaluator successfully", { icon: "success" });
        queryClient.invalidateQueries({
          queryKey: ["assignEvaluator"],
        });
        navigate(0);
      }
    } catch (error) {
      console.log("error", error);
    }
    // dispatch(handleAssignEvaluator([]));
    // handleClose();
  };

  return (
    <div className="flex flex-col items-center p-4 gap-6">
      <div className="text-[26px] font-semibold">Assign Evaluator</div>
      <Formfield onSubmit={onSubmit} schema={schema}>
        <div className="flex flex-col gap-3">
          <div className="text-[18px]">Select Evaluator</div>
          <SelectDropdown
            name="evaluator_email"
            label="Select Evaluator"
            placeholder="Select Evaluator"
            className="w-[420px] mt-2"
            getoptionpromise={Evaluators}
            getOptionValue={(options, opt) => options[opt]}
            id="page1-country-dropdown"
          />
        </div>
        <div className="mt-6">
          <Button
            variant="contained"
            className="w-[420px] h-[40px] text[16px]"
            type="submit"
          >
            Assign Evaluator
          </Button>
        </div>
      </Formfield>
    </div>
  );
}

export default AssignEvaluatorDropdown;

import axios from "axios";
import { BASE_URL_NODE } from "../../config";

export const GetRoleIndexApi = async (params1) => {
  const email = params1.queryKey[1];
  return await axios.get(
    `${BASE_URL_NODE}/login_applicant`,
    {
      params: { email: email },
    }
  );
};

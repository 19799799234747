import { createSlice } from "@reduxjs/toolkit";
const initialState = {};
export const TtcReducer = createSlice({
  name: "ttcapplicationdata",
  initialState: initialState,
  reducers: {
    setTtcApplicationData: (state, action) => {
      state = Object.assign(state, action.payload);
    },
  },
});
export const { setTtcApplicationData } = TtcReducer.actions;
export default TtcReducer.reducer;

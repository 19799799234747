import { createSlice } from '@reduxjs/toolkit'
const initialState = {
  currentStep: 1
}
export const StepperReducer = createSlice({
  name: 'stepper',
  initialState: initialState,
  reducers: {
    IncreamentCurrentStep: state => {
      state.currentStep = state.currentStep + 1
    },
    decreamntCurrentStep: state => {
      state.currentStep = state.currentStep - 1
    },
    setCurrentStep: (state, action) => {
      state.currentStep = action.payload.currentStep
    }
  }
})

// Action creators are generated for each case reducer function
export const { IncreamentCurrentStep, decreamntCurrentStep, setCurrentStep } = StepperReducer.actions

export default StepperReducer.reducer

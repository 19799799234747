import React from "react";
import * as yup from "yup";
import Navbar from "../navbar/Navbar";
import logo from "../../assets/images/artofliving_logo.png";
import Formfield from "../../commomComponents/form/Formfield";
import Textinput from "../../commomComponents/form/textinputfield/Textinput";
import { Button } from "@mui/material";

function IndiaForgotPassword() {
  const schema = yup.object().shape({
    Email: yup.string().email().required(),
  });
  const onSubmit = (data) => {
    console.log(data);
  };
  return (
    <div
      className="flex h-auto min-h-screen bg-[whitesmoke]"
      id="india-forgot-form"
    >
      <div className="flex flex-col w-[100%] h-auto" id="india-forgotpage">
        <div id="india-forgot-page-navabar">
          <Navbar track={3}></Navbar>
        </div>
        <div className="m-auto" id="india-forgotpage-form">
          <div
            className="flex flex-col w-[460px] h-auto shadow-lg items-center p-6 rounded-[10px] bg-[white]"
            id="forgotpassword-card"
          >
            <img src={logo} alt="logo" id="forgot-password-logo"></img>
            <hr className="w-[420px] mt-4" id="forgotpage-driver" />
            <div
              className="font-[bold] text-[32px] mt-5"
              id="forgotpage-heading"
            >
              Forgot Password
            </div>
            <Formfield onSubmit={onSubmit} schema={schema}>
              <div className=" " id="formfield-1">
                <div className="text-[#898989]" id="forgot-email-label">
                  Email
                </div>
                <Textinput
                  name="Email"
                  label="Email"
                  className="w-[420px]"
                  size="small"
                  id="forgotpage-email-input"
                />
              </div>
              <Button
                variant="contained"
                className="w-[420px] h-[40px] text[16px] !mt-[20px]"
                type="submit"
                id="login-submit-button"
              >
                Login
              </Button>
            </Formfield>
          </div>
        </div>
      </div>
    </div>
  );
}

export default IndiaForgotPassword;

import ExcelJS from 'exceljs';

// Function to convert JSON data to Excel
const convertToExcel = async (sheetName, arrayOfObjects, columnsToIgnore, columnsToTransform, columnsToDelete) => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet(sheetName);

    arrayOfObjects = arrayOfObjects.map(obj => {
        return Object.fromEntries(Object.entries(obj).filter(([key])=>!columnsToDelete.includes(key)))
    })
    
    const columns = Object.keys(arrayOfObjects[0]).map(key => ({ header: camelize(key), key }));
    worksheet.columns = columns;

    // setting ignored column values as empty strings
    arrayOfObjects = arrayOfObjects.map(obj => {
        const obj1 = { ...obj }
        columnsToIgnore.forEach(columnName => {
            if (obj1.hasOwnProperty(columnName)) {
                obj1[columnName] = "";
            }
        })
        return obj1;
    })

    // Convert boolean values to "Yes" or "No"
    arrayOfObjects = arrayOfObjects.map(obj => {
        for (const key in obj) {
            if (columnsToTransform.includes(key)) {
                obj[key] = obj[key] ? 'Yes' : 'No';
            }
        }
        return obj;
    });

    worksheet.addRows(arrayOfObjects);
    return workbook;
}

function setDefaultFont(worksheet) {
    worksheet.eachRow(row => row.font = { size: 10, name: 'Arial' });
    worksheet.getRow(1).font = { size: 10, name: 'Arial Black' }
}

function saveXLSX(workbook, bookName) {
    setDefaultFont(workbook.worksheets[0]);
    workbook.xlsx.writeBuffer().then(buffer => {
        const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = bookName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    });
}


async function addImageToWorksheet(workbook, imageString, row, col) {
    const worksheet = workbook.worksheets[0];
    const imageId = workbook.addImage({
        base64: imageString,
        extension: "png"
    });

    const dimensions = await getImageDimensions(imageString);
    worksheet.getRow(row + 1).height = 0.98 * dimensions.height
    worksheet.getColumn(col + 1).width = 0.148 * dimensions.width
    worksheet.addImage(imageId, {
        tl: { row: row + 0.02, col: col + 0.95 },
        ext: dimensions,
    });

}

function getImageDimensions(imageBase64) {
    return new Promise(function (resolved, rejected) {
        var i = new Image()
        i.onload = function () {
            const widthRatio = i.naturalWidth / (i.naturalWidth + i.naturalHeight);
            const heightRatio = i.naturalHeight / (i.naturalWidth + i.naturalHeight);
            const scale = 250;
            resolved({ width: scale * widthRatio, height: scale * heightRatio })
        };
        i.src = imageBase64
    })
}

function autoAdjustColumnWidth(worksheet, excludeArray) {
    worksheet.columns.forEach(column => {
        const lengths = column.values.map(v => v.toString().length);
        let maxLength = Math.max(...lengths.filter(v => typeof v === 'number'));
        // const maxLength = column.values.at(1).toString().length
        if (excludeArray.includes(column.values.at(1))) {
            // console.log(column.values.at(1));
            maxLength = column.values.at(1).length;
        }

        column.width = maxLength + 8;
    });
}

function camelize(name) {
    if (typeof name === 'string') {
        return name.replace(/_/g, ' ').split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
    } else {
        return name;
    }
}

function moveKeyToPosition(obj, key, newPosition) {
    if (!obj) return obj;
    let entries = Object.entries(obj);
    const oldPosition = Object.keys(obj).indexOf(key)
    const removedEntryArray = entries.splice(oldPosition, 1)
    entries.splice(newPosition, 0, [removedEntryArray[0][0], removedEntryArray[0][1]]);
    return Object.fromEntries(entries);
}

export { convertToExcel, addImageToWorksheet, saveXLSX, autoAdjustColumnWidth, moveKeyToPosition };
import React from "react";
import { useNavigate } from "react-router-dom";

import Logo_img from "../../assets/images/artofliving_logo.png";

import { Button } from "@mui/material";
import ListIcon from "@mui/icons-material/List";
import LogoutIcon from "@mui/icons-material/Logout";
import HistoryIcon from "@mui/icons-material/History";

function Navbar({ track }) {
  const Navigate = useNavigate();

  const handleLogoutClick = () => {
    localStorage.removeItem("user");
    localStorage.removeItem("india_role");
    localStorage.removeItem("india_user");
    localStorage.removeItem("token");
    localStorage.removeItem("role");
    localStorage.removeItem("user_type");
    localStorage.removeItem("role_info");
    localStorage.clear();
    Navigate("/");
  };

  const handleTrackClick = () => {
    Navigate("/TrackApplication");
  };

  const handleAssignClick = () => {
    Navigate("/Assignrole");
  };

  const handleAnnounceClick = () => {
    Navigate("/Announcettp");
  };

  const handleTableClick = () => {
    Navigate("/Announcedttp_programs");
  };

  const handlehistoryClick = () => {
    Navigate("/history");
  };

  const handleEvaluationhistoryClick = () => {
    Navigate("/evaluation_history");
  };

  const handleAssignEvaluatorClick = () => {
    Navigate("/assignEvalautor");
  };

  return (
    <div className="flex w-[100%] h-[85px] bg-white">
      <div className="flex w-[100%] h-[85px] bg-white flex-row justify-between justify-center items-center px-12 shadow-lg fixed z-[9999]">
        <div className="">
          <img src={Logo_img} />
        </div>
        <div className="flex gap-8">
          {track === 2 ? (
            <div className="cursor-pointer">
              <Button
                variant="outlined"
                size="large"
                startIcon={<ListIcon />}
                color="primary"
                onClick={() => handleTrackClick()}
              >
                Track Application
              </Button>
            </div>
          ) : (
            ""
          )}

          {track === 2 ? (
            <div className="cursor-pointer">
              <Button
                variant="outlined"
                size="large"
                onClick={() => handleAssignEvaluatorClick()}
              >
                Assign Evaluator
              </Button>
            </div>
          ) : (
            ""
          )}

          {track === 2 ? (
            <div className="cursor-pointer">
              <Button
                variant="outlined"
                size="large"
                onClick={() => handleAssignClick()}
              >
                Assign Role
              </Button>
            </div>
          ) : (
            ""
          )}

          {track === 4 || track === 2 ? (
            <div
            // className="cursor-pointer text-[16px] text-[blue]"
            // onClick={() => {
            //   Navigate("/Announcedttp_programs");
            // }}
            >
              <Button
                variant="outlined"
                size="large"
                onClick={() => handleTableClick()}
              >
                Ttp Programs
              </Button>
            </div>
          ) : (
            ""
          )}

          {track === 2 ? (
            <div className="cursor-pointer">
              <Button
                variant="outlined"
                size="large"
                onClick={() => handleAnnounceClick()}
              >
                Announce TTP
              </Button>
            </div>
          ) : (
            ""
          )}

          {track === 5 ? (
            <div className="cursor-pointer">
              <Button
                variant="outlined"
                size="large"
                startIcon={<HistoryIcon />}
                onClick={() => handlehistoryClick()}
              >
                My Recommendation History
              </Button>
            </div>
          ) : (
            ""
          )}

          {track === 6 ? (
            <div className="cursor-pointer">
              <Button
                variant="outlined"
                size="large"
                startIcon={<HistoryIcon />}
                onClick={() => handleEvaluationhistoryClick()}
              >
                My Evaluation History
              </Button>
            </div>
          ) : (
            ""
          )}
          {track === 7 ? (
            <div className="cursor-pointer">
              <Button
                variant="outlined"
                size="large"
                startIcon={<HistoryIcon />}
                onClick={() => handlehistoryClick()}
              >
                My Recommendation History
              </Button>
            </div>
          ) : (
            ""
          )}
          {track === 8 ? (
            <div className="cursor-pointer">
              <Button
                variant="outlined"
                size="large"
                startIcon={<HistoryIcon />}
                onClick={() => handlehistoryClick()}
              >
                My Recommendation History
              </Button>
            </div>
          ) : (
            ""
          )}
          {track === 9 ? (
            <div className="cursor-pointer">
              <Button
                variant="outlined"
                size="large"
                startIcon={<HistoryIcon />}
                onClick={() => handlehistoryClick()}
              >
                My Recommendation History
              </Button>
            </div>
          ) : (
            ""
          )}
          {track === 1 ||
          track === 2 ||
          track === 4 ||
          track === 5 ||
          track === 6 ||
          track === 7 ||
          track === 8 ||
          track === 9 ? (
            <div className="cursor-pointer">
              <Button
                variant="outlined"
                size="large"
                endIcon={<LogoutIcon />}
                onClick={() => handleLogoutClick()}
              >
                Logout
              </Button>
            </div>
          ) : (
            ""
          )}
          {track === 3 ? (
            <div
              className="cursor-pointer text-[16px] text-[blue]"
              onClick={() => {
                Navigate("/");
              }}
            >
              <Button
                variant="outlined"
                size="large"
                endIcon={<LogoutIcon />}
                onClick={() => handleLogoutClick()}
              >
                Go to Login Page
              </Button>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
}

export default Navbar;

import React from "react";
import * as yup from "yup";
import swal from "sweetalert";

import { useController } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { Button, CircularProgress, FormControl } from "@mui/material";
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";

import Navbar from "../../components/navbar/Navbar";

import logo from "../../assets/images/artofliving_logo.png";

import Formfield from "../../commomComponents/form/Formfield";
import Textinput from "../../commomComponents/form/textinputfield/Textinput";
import Radiogroup from "../../commomComponents/form/radiobuttons/Radiogroup";
import Datefield from "../../commomComponents/form/basicdatepicker/BasicDatePicker";

import { AnnounceTTPApi } from "../../apis/announcettpapi/AnnounceTTPApi";
import { CountryDropdownApi } from "../../apis/countrydropdownapi/CountryDropdownApi";
import MultiSelectDropdown from "../../commomComponents/form/multiselectdropdown/MultiSelectDropdown";

function AnnounceTTP() {
  const { mutateAsync, isLoading } = useMutation(AnnounceTTPApi);

  const navigate = useNavigate();

  const queryClient = useQueryClient();

  const formatMonth = (month) => {
    return month < 10 ? `0${month}` : month;
  };
  const formatDate = (date) => {
    return date < 10 ? `0${date}` : date;
  };

  const onSubmit = async (data) => {
    const startdate =
      data && new Date(data.start_date)
        ? new Date(data.start_date)?.getFullYear() +
          "-" +
          formatMonth(new Date(data.start_date)?.getMonth() + 1) +
          "-" +
          formatDate(new Date(data.start_date)?.getDate())
        : "";

    const enddate =
      data && new Date(data.end_date)
        ? new Date(data.end_date)?.getFullYear() +
          "-" +
          formatMonth(new Date(data.end_date)?.getMonth() + 1) +
          "-" +
          formatDate(new Date(data.end_date)?.getDate())
        : "";
    const { data: countryresponse } = await CountryDropdownApi();
  
    let countries;
    if (data?.country?.length == Object.values(countryresponse).length) {
      countries = true;
    }


    const postdataannouce = {
      id:1,
      name: data?.name,
      start_date: startdate,
      end_date: enddate,
      country: countries == true ? ["All Countries"] : data?.country,
      registration_open: data?.registration_open,
    };

    if (postdataannouce.end_date > postdataannouce.start_date) {
      const res = await mutateAsync(postdataannouce);
      try {
        if (res.ok) {
          await swal("Announced TTP successfully", { icon: "success" });
          queryClient.invalidateQueries({
            queryKey: ["announcettp"],
          });
          navigate(0);
        }
      } catch (error) {
        console.log("error", error);
      }
    } else {
      swal("Please enter the date after the start date", { icon: "error" });
    }
  };

  const schema = yup.object().shape({
    name: yup.string().required("TTP description field is required"),

    start_date: yup
      .date()
      .default(() => new Date())
      .typeError("Please enter a valid date")
      .required("Start date field is required"),

    end_date: yup
      .date()
      .min(yup.ref("start_date"), "End date cannot be before the start date")
      .typeError("Please enter a valid date")
      .required("End date is required"),

    // country: yup.array().required("Country field is required"),
    country: yup
      .array()
      .min(1, "At least one country is to be selected")
      .required("Country field is required"),
    registration_open: yup
      .boolean()
      .required("Registration open field is required"),
  });

  const options = [
    { label: "Yes", value: true },
    { label: "No", value: false },
  ];

  return (
    <div className="flex h-auto min-h-screen bg-[whitesmoke]">
      <div className="w-[100%] h-auto flex flex-col">
        <div>
          {" "}
          <Navbar track={4}></Navbar>
        </div>

        <div className="m-auto">
          <div className="flex flex-col w-[680px] h-auto shadow-lg p-6 rounded-[5px] bg-[white] gap-4">
            <div className="flex flex-col gap-2 items-center justify-center">
              <img src={logo} alt="logo"></img>
              <hr className="w-[700px] mt-4" />
            </div>

            <Formfield onSubmit={onSubmit} schema={schema}>
              <div className="flex flex-col gap-2 mt-4">
                <div className="font-semibold">TTP Description</div>
                <Textinput
                  name="name"
                  className="w-[630px]"
                  size="small"
                  rows={3}
                  lines={true}
                />
              </div>

              <Datefields />

              <div>
                <div className="text-[black] mt-[20px] font-semibold">
                  Country
                </div>
                <div className="mt-2">
                  <MultiSelectDropdown
                    name="country"
                    className="w-[630px] h-[40px] mt-2"
                    getoptionpromise={CountryDropdownApi}
                    getOptionValue={(options, opt) => options[opt]}
                  />
                </div>
              </div>

              <div className="flex mt-[20px]">
                <div className="flex text-[black] font-semibold flex-[0.5]">
                  Registration Open
                </div>
                <div className="justify-center">
                  <Radiogroup
                    name="registration_open"
                    label="psychiatric"
                    options={options}
                    direction={true}
                    getOptionValue={(value) => value === "true"}
                  />
                </div>
              </div>

              <div className="mt-8">
                <Button
                  variant="contained"
                  className="w-[650px] h-[40px] text[16px] mt-8"
                  type="submit"
                  disabled={isLoading}
                >
                  {isLoading ? <CircularProgress size={24} /> : "Announce TTP"}
                </Button>
              </div>
            </Formfield>
          </div>
          <div
            className="flex bg-[black] bg-opacity-10 rounded-lg mb-[650px] absolute left-10 top-[100px] cursor-pointer w-[45px] h-[45px] rounded-[45px] justify-center items-center"
            onClick={() => navigate(-1)}
          >
            <ArrowBackRoundedIcon color="black" size="large" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default AnnounceTTP;

export const Datefields = () => {
  const {
    field: { value },
    formState: { errors },
  } = useController({ name: "start_date" });

  return (
    <FormControl error={false}>
      <div className="flex mt-4 gap-[28px]">
        <div className="flex flex-col flex-[0.5] gap-3">
          <div className="font-semibold">Starting Date</div>
          <div>
            <Datefield
              name="start_date"
              className="w-[300px]"
              size="small"
              disablepast={true}
            />
          </div>
        </div>

        <div className="flex flex-col flex-[0.5] gap-3">
          <div className="font-semibold">Ending Date</div>
          <div>
            <Datefield
              name="end_date"
              className="w-[300px]"
              size="small"
              disablepast={true}
              minDate={value}
            />
          </div>
        </div>
      </div>
    </FormControl>
  );
};

import React from "react";
import Navbar from "../navbar/Navbar";

function SubmittedMessage() {
  return (
    <div className="flex flex-col w-[100%] h-screen bg-[whitesmoke]">
      <div>
        <Navbar track={1}></Navbar>
      </div>
      <div className="flex justify-center items-center h-full w-full">
        <div className="flex w-auto h-[120px] p-6 items-center justify-center bg-[white] text-[18px] rounded-lg font-semibold">
          Your Application has been already submitted successfully
        </div>
      </div>
      
    </div>
  );
}

export default SubmittedMessage;

import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  role_data: "",
};
export const RoleReducer = createSlice({
  name: "role",
  initialState: initialState,
  reducers: {
    RoleData: (state, action) => {
    //   state.Logindata = action.payload;
      return {
        ...state,
        role_data:action.payload
      }
    },
  },
});
export const { RoleData } = RoleReducer.actions;
export default RoleReducer.reducer;

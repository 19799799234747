import React from "react";
import image1 from "../../../assets/images/success-icon.png";
import Navbar from "../../navbar/Navbar";
export default function SubmitApplicationPage() {
  return (
    <div className="w-[100%] h-screen flex flex-col bg-[whitesmoke]">
      <div>
        <Navbar track={1}></Navbar>
      </div>
      <div className="flex justify-center items-center h-screen">
        <div className="flex flex-col gap-2 justify-center items-center w-[600px] h-[350px] shadow-lg rounded-[10px]  bg-[white]">
          <div className="text-[32px] font-[bold]">Application Submitted</div>
          <div className="text-[#898989]">we will reach you soon......</div>
          <div>
            <img src={image1} className="w-[75x] h-[75px]"></img>
          </div>
          <div className="text-[24px]">Thank You</div>
        </div>
      </div>
    </div>
  );
}

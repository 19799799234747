import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  application_all_data: "",
};
export const ApplicationAllDataReducer = createSlice({
  name: " application_all_data",
  initialState: initialState,
  reducers: {
    ApplicationFormData: (state, action) => {
    //   state.Logindata = action.payload;
      return {
        ...state,
        application_all_data:action.payload
      }
    },
  },
});
export const { ApplicationFormData } = ApplicationAllDataReducer.actions;
export default ApplicationAllDataReducer.reducer;

import * as React from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import { useQuery } from "@tanstack/react-query";
import { useController } from "react-hook-form";
import { FormHelperText } from "@mui/material";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function MultipleSelectCheckmarks({
  register,
  label,
  name,
  control,
  className,
  options,
  placeholder,
  params,
  getoptionpromise = () => {},
  getOptionLabel,
  getOptionValue,
  ...rest
}) {
  const { data: option = {} } = useQuery([name, params], getoptionpromise, {
    select: (data) => data.data,
  });
  const {
    field: { value = [], onChange },
    fieldState: { error },
  } = useController({
    name,
    control,
    rules: { required: true },
  });

  const [selectedOptions, setSelectedOptions] = React.useState([]);
  const [selectAll, setSelectAll] = React.useState(false);

  const handleSelectChange = (event) => {
    const selectedValues = event.target.value;
    onChange(selectedValues);
    const allOptionsSelected =
      selectedValues.length === Object.keys(option).length;
    setSelectAll(allOptionsSelected);
  };

  const handleSelectAll = () => {
    if (selectAll) {
      onChange([]);
    } else {
      setSelectedOptions(Object.values(option));
      onChange(Object.values(option));
    }
    setSelectAll(!selectAll);
  };

  return (
    <div>
      <FormControl sx={{ width: 628 }}>
        <Select
          size="small"
          labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          multiple
          value={value}
          error={error}
          onChange={handleSelectChange}
          renderValue={(selected) =>
            selected?.length == Object?.values(option)?.length
              ? "All Countries"
              : selected.join(", ")
          }
          MenuProps={MenuProps}
        >
          <MenuItem onClick={handleSelectAll}>
            <Checkbox checked={selectAll} />
            <ListItemText primary="All Countries" />{" "}
          </MenuItem>
          {Object.keys(option)?.map((opt, index) => {
            const optionValue = getOptionValue(option, opt);
            const isChecked = value.includes(optionValue);
            return (
              <MenuItem value={optionValue} key={index}>
                <Checkbox checked={isChecked} />
                <ListItemText primary={getOptionValue(option, opt)} />
              </MenuItem>
            );
          })}
        </Select>
        <FormHelperText>
          {error && <div className="text-[#D43535]">{error.message}</div>}
        </FormHelperText>
      </FormControl>
    </div>
  );
}

import React, { useEffect} from "react";
import {
  Checkbox,
  FormControlLabel,
  FormControl,
  FormHelperText,
} from "@mui/material";
import { useController } from "react-hook-form";
function Checkboxs({
  label,
  name,
  control,
  options,
  className,
  direction,
  defaultvalue,
  validateCheckBoxFunction,
  ...rest
}) {
  const {
    field: { value, onChange },
    fieldState: { error },
  } = useController({
    name,
    control,
    mode: "onSubmit",
  });
 
  useEffect(() => {
    onChange(value || false);
  }, []);

  return (
    <div>
      <FormControl error={true}>
        <div className={className}>
          <FormControlLabel
            control={
              <Checkbox
                className="mt-2"
                checked={value}
                onChange={onChange}
                defaultChecked={defaultvalue}
              />
            }
            label={label}
            {...rest}
          />
        </div>
        <FormHelperText>
          <div className="-mt-8 ml-4">{error && error.message}</div>
        </FormHelperText>
      </FormControl>
    </div>
  );
}

export default Checkboxs;

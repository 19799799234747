import React from "react";
import { Select, MenuItem, FormControl, FormHelperText } from "@mui/material";
import { useController } from "react-hook-form";
import { useQuery } from "@tanstack/react-query";

function Selectdropdownnew({
  register,
  label,
  name,
  control,
  className,
  options,
  placeholder,
  params,
  getoptionpromise = () => {},
  getOptionLabel,
  getOptionValue,
  ...rest
}) {
  const { data: option = [] } = useQuery([name, params], getoptionpromise, {
    select: (data) => data.data,
  });
  const {
    field: { value, onChange },
    fieldState: { error },
  } = useController({
    name,
    control,
    rules: { required: true },
  });

  return (
    <div className="">
      <FormControl error={false}>
        <Select
          {...rest}
          onChange={(e) => {
            onChange(e.target.value);
          }}
          value={value}
          error={error}
          size="small"
          className={className}
          MenuProps={{ PaperProps: { style: { maxHeight: "200px" } } }}
        >
          {option?.map((data) => {
            return (
              <MenuItem key={data?.id} value={getOptionValue(data)}>
                {data?.role}
              </MenuItem>
            );
          })}
        </Select>
        <FormHelperText>
          {error && <div className="text-[#D43535]">{error.message}</div>}
        </FormHelperText>
      </FormControl>
    </div>
  );
}

export default Selectdropdownnew;

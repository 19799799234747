export const PreProcessApplicantDefaultValues = (data) => {
  return {
    ...data,
    physical: {
      walking: data.walking,
      games: data.games,
      asanas: data.asanas,
      climbing: data.climbing,
      sadhana: data.sadhana,
      seva: data.seva,
    },
    heard: {
      heard_ashtavakra: data.heard_ashtavakra,
      heard_patanjali: data.heard_patanjali,
      heard_narad_bhakti: data.heard_narad_bhakti,
      heard_shiv_sutra: data.heard_shiv_sutra,
    },
    read: {
      read_celebrating_silence: data.read_celebrating_silence,
      read_ashtavakra: data.read_ashtavakra,
      read_walk_like_a_king:data.read_walk_like_a_king
    },
  };
};

import swal from "sweetalert";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import Togglebuttonswitch from "./Togglebuttonswitch";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import Box from "@mui/material/Box";
import { Button } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";

import { AnnounceUpdateApi } from "../../apis/announceupdateapi/AnnounceUpdateApi";
import { AnnouncedTTPDetailsApi } from "../../apis/announcedttpdetailsapi/AnnouncedTTPDetailsApi";

import Navbar from "../../components/navbar/Navbar";
import { ttpalldata } from "../../redux/reducer/announcettpupdate/AnnounceTTPData";
import CustomNoRowsOverlay from "../../commomComponents/form/noformdata/NoFormData";

const columns = [
  // {
  //   field: "id",
  //   headerName: "Id",
  //   width: 100,
  //   headerClassName: "super-app-theme--header",
  // },
  {
    field: "name",
    headerName: "TTP Description",
    width: 250,
    headerClassName: "super-app-theme--header",
    sortable: false,
  },
  {
    field: "start_date",
    headerName: "Starting Date",
    width: 200,
    headerClassName: "super-app-theme--header",
    sortable: false,
  },
  {
    field: "end_date",
    headerName: "Ending Date",
    width: 200,
    headerClassName: "super-app-theme--header",
    sortable: false,
  },
  {
    field: "country",
    headerName: "Country",
    width: 150,
    headerClassName: "super-app-theme--header",
    sortable: false,
  },
  {
    field: "registration_open",
    headerName: "Manage Registration",
    width: 200,
    headerClassName: "super-app-theme--header",
    sortable: false,
    renderCell: (params) => {
      const data = {
        id: params.row.id,
        resgistration_open: params.row.registration_open,
      };
      return <Togglebuttonswitch params={data} />;
    },
  },
];

function AnnonceTtpTable() {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const queryClient = useQueryClient();

  var { ttp_all_data } = useSelector((state) => state?.ttp_all_data);

  const { data = [], isLoading } = useQuery(
    ["get_ttp-details"],
    AnnouncedTTPDetailsApi,
    {
      select: (data) => {
        return data?.data;
      },
    }
  );


  var x = [];
  useEffect(() => {
    if (data.length !== 0) {
      data.map((datattp, index) => {
        const newData = {
          id: datattp?.id,
          registration_open: datattp?.registration_open,
        };
        x = [...x, newData];
      });
      dispatch(ttpalldata(x));
    }
  }, [data]);
  const { mutateAsync } = useMutation(AnnounceUpdateApi);

  const handleUpdatedata = async () => {
    await Promise.all(
      ttp_all_data.map(async (item) => {
        try {
          const res = await mutateAsync(item);

          if (res.ok) {
            queryClient.invalidateQueries({
              queryKey: ["application-table-data"],
            });
            swal("Updated successfully", { icon: "success" });
            // Navigate(0);
          }
        } catch (error) {
          swal("Something went wrong", { icon: "error" });
        }
      })
    );
  };

  function CustomNoRows() {
    return (
      <div className="mt-8">
        <CustomNoRowsOverlay theory="No application found"></CustomNoRowsOverlay>
      </div>
    );
  }

  return (
    <div>
      <div className="flex h-auto min-h-screen bg-[whitesmoke]">
        <div className="w-[100%] h-screen flex flex-col gap-4">
          <div>
            {" "}
            <Navbar track={1}></Navbar>
          </div>
          <div className="m-auto flex overflow-y-auto scrollbar">
            <div className="flex flex-col gap-4">
              <div className="flex">
                <div className="flex">
                  <Box
                    sx={{
                      height: 550,
                      width: "100%",
                      "& .super-app-theme--header": {
                        backgroundColor: "RGB(106 170 234)",
                        color: "white",
                      },
                      fontWeight: 900,
                      fontSize: 16,
                    }}
                  >
                    {isLoading ? (
                      "....Loading"
                    ) : (
                      <DataGrid
                        rows={data}
                        columns={columns}
                        slots={{
                          noRowsOverlay: CustomNoRows,
                        }}
                        pageSize={10}
                        pageSizeOptions={[10, 20, 50, 100]}
                        editMode="row"
                        disableColumnMenu
                      />
                    )}
                  </Box>
                </div>
              </div>
              <div className="flex justify-center items-center mt-4">
                <Button
                  variant="contained"
                  size="large"
                  color="primary"
                  onClick={() => handleUpdatedata()}
                >
                  UpDate
                </Button>
              </div>
            </div>
            <div
              className="flex bg-[black] bg-opacity-10 rounded-lg mb-[650px] absolute left-10 top-[100px] cursor-pointer w-[45px] h-[45px] rounded-[45px] justify-center items-center"
              onClick={() => navigate(-1)}
            >
              <ArrowBackRoundedIcon color="black" size="large" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AnnonceTtpTable;

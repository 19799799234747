import React from "react";
import PagesFooter from "../PagesFooter";
import Textinput from "../../../commomComponents/form/textinputfield/Textinput";
import Datefield from "../../../commomComponents/form/basicdatepicker/BasicDatePicker";
import Typedropdown from "../../../commomComponents/form/singleselectdropdown/Typedropdown";

const Page3 = () => {
  const option1 = [{ parttime: "Part Time" }, { fulltime: "Full Time" }];

  return (
    <div id="page3">
      <div id="page3field">
        <div
          className="flex flex-col gap-6 h-full w-[670px] pt-2"
          id="page3-form"
        >
          <div className="flex items-center mt-3" id="teacher-since-field">
            <div className="flex w-64" id="teacher-since-field-label">
              Teacher since*
            </div>
            <Datefield
              name="teacher_since"
              label="Teacher since"
              className="w-[300px]"
              size="small"
              disableFuture={true}
              disablepast={false}
              id="teacher-since-datepicker"
            />
          </div>

          <div className="flex items-center" id="teacher-type-field">
            <div
              className="text-[16px] flex w-64"
              id="teacher-type-field-label"
            >
              Are you a Part time / Full time teacher?
            </div>
            <Typedropdown
              name="teacher_type"
              label="parttime/fulltime"
              className="w-[300px] mt-2"
              options={option1}
              id="teacher-type-dropdown"
            />
          </div>

          <div className="flex items-center" id="programs-taught-field">
            <div className="flex w-64" id="programs-taught-field-ladel">
              Programs taught*:
            </div>
            <Textinput
              name="programs_taught"
              label="programstaught"
              className="w-[300px]"
              size="small"
              id="programs-taught-field-input"
            />
          </div>

          <div id="happiness-program-field">
            Happiness Program conducted* (last 1 year)
          </div>
          <div className="flex items-center" id="hp-last-year-count-field">
            <div className="flex w-64" id="hp-last-year-count-field-label">
              Number of Happiness Programs*:
            </div>
            <Textinput
              name="hp_last_year_count"
              label="No. of Happiness Programs*:"
              className="w-[300px]"
              size="small"
              type="number"
              getOptionValue={(value) => parseInt(value)}
              id="hp-last-year-count-field-input"
            />
          </div>

          <div className="flex items-center" id="pax-last-year-count-field">
            <div className="flex w-64" id="pax-last-year-count-field-label">
              Number of Participants*:
            </div>
            <Textinput
              name="pax_last_year_count"
              label="Pax"
              className="w-[300px]"
              size="small"
              type="number"
              getOptionValue={(value) => parseInt(value)}
              id="pax-last-year-count-field-input"
            />
          </div>

          <div id="page3-footer">
            <PagesFooter page={3} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Page3;

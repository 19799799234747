import React from "react";
import SelectDropdown from "./SelectDropdown";
import { useController } from "react-hook-form";
import { useStateDropdownApi } from "../../../apis/statedropdownapi/StateDropdownApi";

function StateDropdownData({
  name,
  id,
  error,
  className,
  getoptionpromise = () => {},
  params,
  disabled,
  ...rest
}) {
  const {
    field: { value },
  } = useController({ name: "country" });

  return (
    <div>
      <SelectDropdown
        name="state"
        id={id}
        label="Select Country"
        className="w-[300px] mt-2"
        getoptionpromise={useStateDropdownApi}
        params={value}
        disabled={disabled}
        getOptionValue={(options, opt) => opt}
      />
    </div>
  );
}

export default StateDropdownData;

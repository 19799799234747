/**
 * This Compoment is used to validate the email address which we are giving according to the country we select from the dropdown
 * the api is happen when we select the country and email address length is greater than 9 characters
 */
import React, { useEffect, useState } from "react";
import { TextField } from "@mui/material";
import { useController } from "react-hook-form";
import { useQuery } from "@tanstack/react-query";
import CloseIcon from "@mui/icons-material/Close";
import { CountryDropdownApi } from "../../../apis/countrydropdownapi/CountryDropdownApi";
import { validateTeacherApi } from "../../../apis/validateteacherapi/ValidateTeacherApi";
function TextInputWithApi({
  id,
  register,
  label,
  name,
  control,
  className,
  lines,
  rows,
  type,
  max,
  getOptionLabel,
  getOptionValue = (val) => val,
  disabled,
  inputprops,
  ...rest
}) {
  const {
    field: { value, onChange },
    fieldState: { error },
  } = useController({
    name,
    control,
  });

  const {
    field: { value: countryName },
  } = useController({
    name: "recommending_teacher_country",
    control,
  });

  const [email, setEmail] = useState("");
  const [country, setCountry] = useState("");
  const [close, setClose] = useState("false");


  /**
   * Here we get the CountryName as "Canada", but we want to send the country code to api call, so we write the useEffect here
   * it feches data from the countryDropdownapi, and from that data we can get the country code
   */

  useEffect(() => {
    async function fetchCountry() {
      try {
        const { data: countryResponse } = await CountryDropdownApi();
        const valueWanted = Object.keys(countryResponse).find(
          (key) => countryResponse[key] === countryName
        );
        setCountry(valueWanted);
        setClose(true);
      } catch (error) {
        console.error("Error fetching country:", error);
      }
    }

    if (countryName) {
      fetchCountry();
    }
  }, [countryName, email]);

  const params = {
    email: email,
    country: country,
  };
  

  /**
   * This is the api which is used to verify the email address
   * it takes params, email,country as object
   */

  const { data } = useQuery(["validate-teacher", params], validateTeacherApi, {
    enabled:
      email?.length > 9 && (countryName != null || countryName != undefined),
    select: (data) => data?.data,
  });

  //Here we write this localStorage because of the validation schema from the email address

  localStorage.setItem("api", data);


  //this function is used to set the value of the email, when email is greater than 9 characters and unable the error box

  const handleApiCall = (emailValue) => {
    if (emailValue.length > 9) {
      setEmail(emailValue);
      setClose(true);
    }
  };


   //this function is used to open or close the error popup box

  const handleClose = () => {
    setClose(false);
  };
  
  return (
    <div className="">
      <TextField
        onChange={(e) => {
          onChange(getOptionValue(e.target.value));
          if (e.target.value?.length > 6) {
            handleApiCall(e.target.value);
          }
        }}
        id={id}
        value={value}
        error={error && (data !== 1 && data !== 2) }
        helperText={error && error?.message}
        className={className}
        multiline={lines}
        maxRows={max}
        rows={rows}
        type={type}
        onKeyDown={(evt) =>
          ["e", "E", "+", "-"].includes(evt.key) &&
          type == "number" &&
          evt.preventDefault()
        }
        disabled={disabled}
        onWheel={(e) => e.target.blur()}
        inputProps={
          type === "number" ? { inputMode: "numeric", pattern: "[0-9]*" } : {}
        }
        {...rest}
      />
      <div>
        {close === true && data === 0 && (
          <div className="flex p-[10px] text-[#d43535] rounded-[3px] bg-[#F8D6D6] justify-between items-center mt-[10px]">
            <div className="text-[#d43535] text-[12px]">
              Enter correct Harmony email address
            </div>
            <div>
              <CloseIcon size="small" onClick={handleClose} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
export default TextInputWithApi;

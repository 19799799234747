import React from "react";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
export default function Formfield({
  defaultValues,
  schema,
  children,
  onSubmit,
}) {
  var methods = useForm({
    defaultValues,
    resolver: yupResolver(schema),
    // mode: "all",
    mode: "onChange",
    criteriaMode: "all",
  });

  return (
    <div className="">
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>{children}</form>
      </FormProvider>
    </div>
  );
}

/**
 * It is used to select the date from the calender, Present it is used in dateofbirth field and teacher since field
 * For this by default in placeholder it have "mm-dd-yyyy" format
 */
import * as React from "react";
import { useController } from "react-hook-form";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { FormControl, FormHelperText, TextField } from "@mui/material";
import dayjs from "dayjs";

export default function BasicDatePicker({
  label,
  id,
  name,
  control,
  disableFuture,
  disablepast,
  minDate,
  defaultValue,
  ...rest
}) {
  const {
    field: { value, onChange },
    fieldState: { error },
  } = useController({
    name,
    control,
    rules: { required: true },
    defaultValue,
  });

  const [dateValue, setDateValue] = React.useState(
    dayjs(value ? dayjs(value) : 'MM-DD-YYYY')
  );
  return (
    <div className="flex gap-16 items-center">
      <FormControl error={true}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            className="w-[300px]"
            disableFuture={disableFuture}
            disablePast={disablepast}
            defaultValue={defaultValue}
            minDate={minDate}
            id={id}
            onChange={(val) => {
              setDateValue(val);
              const date = new Date(new Date(val?.$d)?.setDate(val?.$D));
              onChange(date);
            }}
            value={dateValue}
            renderInput={(params) => {
              return (
                <TextField
                  {...params}
                  size="small"
                  error={error}
                  readOnly={false}
                />
              );
            }}
            {...rest}
          />
        </LocalizationProvider>
        <FormHelperText>{error && <div>{error.message}</div>}</FormHelperText>
      </FormControl>
    </div>
  );
}

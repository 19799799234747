import React from "react";
import PagesFooter from "../PagesFooter";
import { useFormContext } from "react-hook-form";

import Textfieldpage1 from "../../steppercomponent/Textfieldpage1";

import Radiogroup from "../../../commomComponents/form/radiobuttons/Radiogroup";
import Textinput from "../../../commomComponents/form/textinputfield/Textinput";
import CheckBoxGroupComponent from "../../../commomComponents/form/checkboxesgroup/CheckBoxGroup";

const Page2 = () => {
  const options = [
    { label: "Yes", value: true },
    { label: "No", value: false },
  ];
  const {
    formState: { errors },
  } = useFormContext();

  return (
    <div id="page2">
      <div id="page2fields">
        {" "}
        <div
          className="flex flex-col gap-6 h-full pt-2 
        "
          id="page2form"
        >
          <CheckBoxGroupComponent
            header="Physically Fit For*"
            name="physical"
            fields={[
              { name: "walking", label: "Walking" },
              { name: "games", label: "Games" },
              { name: "asanas", label: "Asanas" },
              { name: "climbing", label: "Climbing" },
              { name: "sadhana", label: "Sadhana" },
              { name: "seva", label: "Seva Activities" },
            ]}
            styles="h-[160px] w-[320px]"
            id="physical-checkbox"
          />
          <div className="flex gap-3" id="medical-field">
            <div className="text-[16px] mt-2 w-64" id="medical-label">
              Are you under any prescribed medical treatment?*
            </div>
            <Radiogroup
              name="medical_treatment"
              label="medical"
              options={options}
              direction={true}
              getOptionValue={(value) => {
                return value === "true";
              }}
              id="medical-radiogroup"
            />
          </div>

          <div className="flex gap-3" id="psychiatric-field">
            <div className="text-[16px] mt-2 w-64" id="psychiatric-label">
              Have you undergone any psychiatric treatment?*
            </div>
            <Radiogroup
              name="psychiatric_treatment"
              label="psychiatric"
              options={options}
              direction={true}
              getOptionValue={(value) => value === "true"}
              id="psychiatric-radiogroup"
            />
          </div>

          <div className="flex gap-3" id="vegetarian-field">
            <div className="text-[16px] mt-2 w-[258px]" id="vegetarian-label">
              Do you follow only vegetarian diet?*
            </div>
            <Radiogroup
              name="vegetarian_diet"
              label="vegetarian"
              options={options}
              direction={true}
              getOptionValue={(value) => value === "true"}
              id="vegetarian-radiogroup"
            />
          </div>

          <div className="mt-3 flex" id="issues-fields">
            <div className="flex w-64 mt-2" id="issues-label">
              Any other issues/difficulties
            </div>
            <Textinput
              name="other_issues"
              label="issues"
              className="w-[300px]"
              size="small"
              id="issuses-input"
            />
          </div>

          <div id="program-fields">
            Programs (Part 2 / Sanyam / Blessings) done in past 1 year (from the
            date of application)
          </div>
          <div className="p-5 flex flex-col gap-3" id="advancefields">
            <div className="flex gap-3" id="part2-field">
              <div className="text-[16px] mt-2 w-60" id="part2-label">
                Part2
              </div>
              <div className="flex flex-col gap-2" id="part2-radiogroups">
                <Radiogroup
                  name="advance_program"
                  label="part2"
                  options={options}
                  direction={true}
                  getOptionValue={(value) => value === "true"}
                  id="part2-radio"
                />
                <Textfieldpage1
                  name="advance_program_details"
                  dependentName="advance_program"
                  id="part2-input"
                ></Textfieldpage1>
              </div>
            </div>

            <div className="flex gap-3" id="blessing-field">
              <div className=" text-[16px] mt-2 w-60" id="blessing-label">
                Blessings
              </div>
              <div className="flex flex-col gap-2" id="blessing-radiogroups">
                <Radiogroup
                  name="blessings_program"
                  label="blessings"
                  options={options}
                  direction={true}
                  getOptionValue={(value) => value === "true"}
                  id="blessing-radio"
                />
                <Textfieldpage1
                  name="blessings_program_details"
                  dependentName="blessings_program"
                  id="blessing-input"
                ></Textfieldpage1>
              </div>
            </div>

            <div className="flex gap-3" id="sanyam-field">
              <div className=" text-[16px] w-60 mt-2" id="sanyam-label">
                Sanyam
              </div>
              <div className="flex flex-col gap-2" id="sanyam-radiogroup-field">
                <Radiogroup
                  name="sanyam_program"
                  label="sanyam"
                  options={options}
                  direction={true}
                  getOptionValue={(value) => value === "true"}
                  id="sanyam-radiogroup"
                />
                <Textfieldpage1
                  name="sanyam_program_details"
                  dependentName="sanyam_program"
                  id="sanyam-input"
                ></Textfieldpage1>
              </div>
            </div>
          </div>

          <CheckBoxGroupComponent
            header="I have heard (in past 1 year from the date of application)*"
            name="heard"
            fields={[
              { name: "heard_ashtavakra", label: "Ashtavakra Gita" },
              { name: "heard_patanjali", label: "Patanjali Yoga Sutra" },
              { name: "heard_narad_bhakti", label: "Narad Bhakti Sutra" },
              { name: "heard_shiv_sutra", label: "Shiv Sutra" },
            ]}
            styles="h-[120px] w-[320px]"
            id="heard"
          />

          <div className="-mt-10" id="read-field">
            <CheckBoxGroupComponent
              header="I have read (in past 1 year from the date of application)*"
              name="read"
              fields={[
                {
                  name: "read_celebrating_silence",
                  label: "Celebrating Silence",
                },
                {
                  name: "read_walk_like_a_king",
                  label: "Walk like a King and be a Perfect Servant",
                },
                { name: "read_ashtavakra", label: "Ashtavakra Gita" },
              ]}
              styles="h-[120px] w-[210px]"
              id="read"
            />
          </div>
          <div className="pt-2" id="page2footer">
            <PagesFooter page={2} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Page2;

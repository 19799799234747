import { combineReducers } from "@reduxjs/toolkit";
import { StepperReducer } from "../reducer/StepperReducer";
import { Page1Reducer } from "../reducer/Page1Reducer";
import { LoginReducer } from "./LoginReducer";
import { Page2Reducer } from "./Page2Reducer";
import { Page3Reducer } from "./Page3Reducer";
import { Page4Reducer } from "./Page4Reducer";
import { EvaluationReducer } from "./evaluation/EvaluationReducer";
import { ApplicationAllDataReducer } from "./applicationalldata/ApplicationAllDataReducer";
import { StateDropdownReducer } from "./dropdowndata/StateDropdownReducer";
import { TtcReducer } from "./TtcReducer";
import { RoleReducer } from "./roledata/RoleReducer";
import { AnnounceTTPData } from "./announcettpupdate/AnnounceTTPData";
import { FormError } from "./formerror/FormErrors";
import { TextViewerReducer } from "./textviewer/TextViewerReducer";
import { TtpAllDataReducer } from "./ttpalldatareducer/TtpAllDataReducer";
import { AssignEvaluatorReducer } from "./assignevaluatorreducer/AssignEvaluatorReducer";
const reducer = combineReducers({
  stepper: StepperReducer.reducer,
  page1data: Page1Reducer.reducer,
  page2data: Page2Reducer.reducer,
  page3data: Page3Reducer.reducer,
  page4data: Page4Reducer.reducer,
  login_data: LoginReducer.reducer,
  evaluationpagedata: EvaluationReducer.reducer,
  application_all_data: ApplicationAllDataReducer.reducer,
  statecountry: StateDropdownReducer.reducer,
  ttcapplicationdata: TtcReducer.reducer,
  role_data: RoleReducer.reducer,
  ttp_all_data: AnnounceTTPData.reducer,
  form_all_errors: FormError.reducer,
  Text_Viewer: TextViewerReducer.reducer,
  ttpallDataInitial: TtpAllDataReducer.reducer,
  assignEvaluator: AssignEvaluatorReducer.reducer,
});
export default reducer;

import React from "react";
import * as yup from "yup";
import swal from "sweetalert";

import { useNavigate } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import { Button, CircularProgress } from "@mui/material";
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";

import Navbar from "../../components/navbar/Navbar";

import logo from "../../assets/images/artofliving_logo.png";

import Formfield from "../../commomComponents/form/Formfield";
import Selectdropdownnew from "../../commomComponents/form/selectdropdown/Selectdropdownnew";
import SearchableDropdown from "../../commomComponents/form/searchabledropdown/SearchbleDropdown";


import { AssignRoleApi } from "../../apis/assignroleapi/AssignRoleApi";
import { AssignRoleGetApi } from "../../apis/assignrolegetapi/AssignRoleGetApi";
import { SearchableDropDownsApi } from "../../apis/searchabledropdownapi/SearchableDropDownApi";

const schema = yup.object().shape({
  email: yup
    .string()
    .matches(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      "Enter the valid email"
    )
    .required("User field is required"),
  role: yup.string().required("Role field is required"),
});

export default function Login() {
  const { mutateAsync, isLoading } = useMutation(AssignRoleApi);
  const navigate = useNavigate();

  const Navigate = useNavigate();
  const onSubmit = async (data) => {
   
    const res = await mutateAsync(data);
    try {
      if (res.ok) {
        await swal("Role assigned successfully", { icon: "success" });
        Navigate(0);
      }
    } catch (error) {
      swal("Something went wrong", { icon: "error" });
    }
  };

  return (
    <div className="w-[100%] h-screen flex flex-col">
      <div>
        <Navbar track={1}></Navbar>
      </div>

      <div className="flex w-full h-[100vh] items-center justify-center bg-[whitesmoke]">
        <div className="flex flex-col w-[460px] h-auto shadow-lg items-center p-6 rounded-[5px] bg-[white]">
          <img src={logo} alt="logo"></img>
          <hr className="w-[420px] mt-4" />

          <Formfield onSubmit={onSubmit} schema={schema}>
            <div>
              <div className="text-[black] mt-4">Select User</div>
              <div className="mt-[12px]">
                <SearchableDropdown
                  name="email"
                  label="Select User"
                  placeholder="Select User"
                  className="w-[420px]"
                  getoptionpromise={SearchableDropDownsApi}
                  getOptionValue={(option) => option.email}
                  size="small"
                />
              </div>
            </div>

            <div>
              <div className="text-[black] mt-[20px]">Select Role</div>
              <Selectdropdownnew
                name="role"
                label="Select Role"
                placeholder="Select Role"
                className="w-[420px] h-[40px] mt-2"
                getoptionpromise={AssignRoleGetApi}
                getOptionValue={(data) => {
                  if (data?.role === "applicant") {
                    return data?.id;
                  } else if (data?.role === "recommending teacher") {
                    return data?.id;
                  } else if (data?.role === "evaluator") {
                    return data?.id;
                  } else if (data?.role === "sahaj coordinator") {
                    return data?.id;
                  } else if (data?.role === "country coordinator") {
                    return data?.id;
                  } else if (data?.role === "india sahaj coordinator") {
                    return data?.id;
                  } else {
                    return 7;
                  }
                }}
              />
            </div>

            <div className="mt-4">
              <Button
                variant="contained"
                className="w-[420px] h-[40px] text[16px] mt-8"
                type="submit"
                disabled={isLoading}
              >
                {isLoading ? <CircularProgress size={24} /> : "Assign Role"}
              </Button>
            </div>
          </Formfield>
        </div>
        <div
          className="flex bg-[black] bg-opacity-10 rounded-lg mb-[650px] absolute left-10 top-[100px] cursor-pointer w-[45px] h-[45px] rounded-[45px] justify-center items-center"
          onClick={() => navigate(-1)}
        >
          <ArrowBackRoundedIcon color="black" size="large" />
        </div>
      </div>
    </div>
  );
}

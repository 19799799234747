import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  Text_Viewer: false,
};
export const TextViewerReducer = createSlice({
  name: "Text_Viewer",
  initialState: initialState,
  reducers: {
    TextViewerBox: (state, action) => {
      //   state.Logindata = action.payload;
      return {
        ...state,
        Text_Viewer: action.payload,
      };
    },
  },
});
export const { TextViewerBox } = TextViewerReducer.actions;
export default TextViewerReducer.reducer;

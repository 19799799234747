import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import React, { useState } from "react";
import { FaFileAlt } from "react-icons/fa";
import { useNavigate, useSearchParams } from "react-router-dom";
import swal from "sweetalert";
import * as yup from "yup";

import Formfield from "../../commomComponents/form/Formfield";
import Datefield from "../../commomComponents/form/basicdatepicker/BasicDatePicker";
import CheckboxesComponent from "../../commomComponents/form/groupcheckboxes/CheckboxesComponent";
import ImportantNote from "../../commomComponents/form/importantnote/ImportantNote";
import LinkTextinput from "../../commomComponents/form/linktextfield/LinkTextField";
import Radiogroup from "../../commomComponents/form/radiobuttons/Radiogroup";
import SelectDropdown from "../../commomComponents/form/singleselectdropdown/SelectDropdown";
import StateDropdownData from "../../commomComponents/form/singleselectdropdown/StateDropdownData";
import Typedropdown from "../../commomComponents/form/singleselectdropdown/Typedropdown";
import Textinput from "../../commomComponents/form/textinputfield/Textinput";

import Navbar from "../../components/navbar/Navbar";
import Textfieldpage1 from "../../components/steppercomponent/Textfieldpage1";
import { Phonecodecomponent } from "../../components/ttcapplicationform/page1/PhonecodeComponent";

import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import { Button, TextField } from "@mui/material";
// import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";

// import { TtpInfoApi } from "../../apis/ttpinfoapi/TtpInfoApi";
import { AnnouncedTTPDetailsApi } from "../../apis/announcedttpdetailsapi/AnnouncedTTPDetailsApi";
import { ApplicationDataApi } from "../../apis/applicationreceivedataapi/ApplicationDataApi";
import { CountryDropdownApi } from "../../apis/countrydropdownapi/CountryDropdownApi";
import { UpdateJourneyApi } from "../../apis/updatejourneyapi/UpdateJourneyApi";
import { addImageToWorksheet, autoAdjustColumnWidth, convertToExcel, moveKeyToPosition, saveXLSX } from "../../utils/XLSXHandling";

const schema = yup.object().shape({
  ttp_office_reccomendation: yup
    .boolean()
    .required("Recommendation is required"),
  ttp_office_remarks: yup
    .string()
    .max(500, "Remarks should not exceed 500 characters")
    .required("Remarks is required"),
});

const options = [
  { label: "Male", value: "male" },
  { label: "Female", value: "female" },
  { label: "Other", value: "other" },
];

const options2 = [
  { label: "Yes", value: true },
  { label: "No", value: false },
];

const options5 = [
  { label: "Yes", value: 1 },
  { label: "No", value: 0 },
];

const option3 = [{ parttime: "Part Time" }, { fulltime: "Full Time" }];

const options4 = [
  { label: "Daily", value: "Daily" },
  { label: "Weekly", value: "Weekly" },
  { label: "Monthly", value: "Monthly" },
];
function TtpDeskPage2() {
  const queryClient = useQueryClient();

  const [isEditable] = useState(true);

  const Navigate = useNavigate();

  const { mutateAsync: updatemutateAsync } = useMutation(UpdateJourneyApi);

  const onSubmit = async (data) => {
    const ttpofficeevaluatingdate = new Date().toISOString().substring(0, 10);
    const email =
      JSON.parse(localStorage.getItem("user"))?.username ||
      JSON.parse(localStorage?.getItem("india_user"))?.email;
    var updatejourney = {
      journey: 7,
      name: data?.name,
      applicant_email: data?.email,
      recommending_person_email: email,
      remarks: data?.ttp_office_remarks,
      applicant_harmony_email: data?.harmony_email,
      recommended: data?.ttp_office_reccomendation,
      timestamp: ttpofficeevaluatingdate,
    };

    const res = await updatemutateAsync(updatejourney);
    try {
      if (res.ok) {
        await swal("Submitted successfully", { icon: "success" });
        queryClient.invalidateQueries({
          queryKey: [`application-data-ttp2-${email}`],
        });
        Navigate("/TtpDeskPage1");
      }
    } catch (error) {
      swal("Something went wrong", { icon: "error" });
    }
  };

  //Here i used useSearchParams because i wnt to get the email from the url, so by using this i am getting the email, and making the api call
  const [searchParams] = useSearchParams();
  console.log(searchParams.get('email'), "emaillll adta")

  // const emailData = localStorage.getItem("emailData");

  let { data: applicationData, isLoading } = useQuery(
    ["application-data-ttpdesk22", searchParams.get('email')],
    ApplicationDataApi,
    {
      select: (data) => {
        let modifydata = { ...data.data };

        modifydata["date_of_birth"] = modifydata["date_of_birth"]
          .split("-")
          .reverse()
          .join("-");
        modifydata["teacher_since"] = modifydata["teacher_since"]
          .split("-")
          .reverse()
          .join("-");
        return modifydata;
      },
    }
  );

  applicationData = moveKeyToPosition(applicationData, 'profile_picture', 1);
  const { data: ttpdata = [] } = useQuery(
    ["get_ttp-details"],
    AnnouncedTTPDetailsApi,
    {
      select: (data) => {
        return data?.data;
      },
    }
  );

  const recordWithId = ttpdata?.find(
    (item) => item.id === applicationData?.ttp_info_id
  );

  return (
    <div className="flex h-auto min-h-screen bg-[whitesmoke]">
      <div className="w-[100%] h-screen flex flex-col">
        <div>
          {" "}
          <Navbar track={1}></Navbar>
        </div>
        <div className="flex justify-end">
       <button onClick={async () => await downloadExcel(applicationData)} class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 m-5 rounded">
            Download
          </button>
        </div>


        <div className="m-auto overflow-y-auto scrollbar">
          <div className="flex flex-col w-[850px] h-auto bg-[white] shadow-lg rounded-lg items-center py-8">
            <div className="bg-[#EDE6E6] p-5">
              <div className="flex gap-3 justify-start items-start">
                <div>
                  <FaFileAlt className="w-[35px] h-[35px]" />
                </div>
                <div className="text-[28px] font-bold">TTC Application</div>
              </div>
              <div className="flex items-center justify-center w-[720px] pt-1">
                Note: This TTC Application from should be filled by TTC
                applicants only. Please answer all questions truthfully and with
                deatil and clarity. Your responses will remain confidential and
                will only be reviewed by the TTC Trainers and TTC desk team
              </div>
            </div>

            <div className="flex flex-col h-full w-full items-center justify-center ">
              {isLoading ? (
                <div>Loading...</div>
              ) : (
                <Formfield
                  onSubmit={onSubmit}
                  schema={schema}
                  defaultValues={{
                    ...applicationData,
                  }}
                >
                  <div className="flex flex-col h-auto w-[790px] bg-[white] px-8 overflow-y-scroll scrollbar gap-4">
                    <div className="flex items-center mt-[20px]">
                      <div className="text-[16px] flex w-64">TTP*</div>
                      <TextField
                        disabled={isEditable}
                        value={recordWithId?.name}
                        className="w-[300px]"
                        size="small"
                      ></TextField>
                    </div>

                    <div className="flex items-center mt-2">
                      <div className="text-[16px] flex w-64">
                        Applicant's Name*
                      </div>
                      <Textinput
                        name="name"
                        label="Applicant's Name"
                        className="w-[300px]"
                        size="small"
                        disabled={isEditable}
                      />
                    </div>

                    <div className="flex items-center mt-2">
                      <div className="flex w-64">Date Of Birth*</div>
                      <Datefield
                        name="date_of_birth"
                        label="DateOfBirth"
                        className="w-[300px]"
                        size="small"
                        disabled={isEditable}
                      />
                    </div>

                    <div className="flex">
                      <div className="flex w-64">Gender*</div>
                      <Radiogroup
                        name="gender"
                        label="Gender"
                        options={options}
                        disabled={isEditable}
                      ></Radiogroup>
                    </div>

                    <div
                      className="flex items-center mt-2"
                      id="applicant-name-field"
                    >
                      <div
                        className="text-[16px] flex w-64"
                        id="applicant-name-label"
                      >
                        Applicant's Image*
                      </div>
                      <div className="flex flex-col gap-2">
                        <img
                          src={applicationData?.profile_picture}
                          width={192}
                          height={192}
                        ></img>
                        <div className="text-[12px] text-[red]"><i><b>Max file size: 1MB | Supported file formats: .jpg, .jpeg, .png</b></i></div>
                      </div>
                    </div>

                    <div className="flex">
                      <div className="text-[16px] flex w-64">Address*</div>
                      <Textinput
                        name="address"
                        label="Address"
                        placeholder="Address1"
                        className="w-[450px]"
                        size="small"
                        rows={2}
                        lines={2}
                        disabled={isEditable}
                      />
                    </div>

                    <div className="flex items-center mt-4">
                      <div className="text-[16px] flex w-64">Country*</div>
                      <SelectDropdown
                        name="country"
                        label="Select Country"
                        className="w-[300px] mt-2"
                        getoptionpromise={CountryDropdownApi}
                        getOptionValue={(options, opt) => options[opt]}
                        disabled={isEditable}
                      />
                    </div>

                    <div className="flex items-center mt-4">
                      <div className="text-[16px] flex w-64">State*</div>
                      <StateDropdownData disabled={true} />
                    </div>

                    <div className="flex items-center mt-4">
                      <div className="text-[16px] flex w-64">City*</div>
                      <Textinput
                        name="city"
                        label="Select Country"
                        className="w-[300px] mt-2"
                        size="small"
                        disabled={isEditable}
                      />
                    </div>

                    <div className="flex items-center mt-2">
                      <div className="text-[16px] flex w-64">Postal Code*</div>
                      <Textinput
                        name="postal_code"
                        label="Applicant's Name"
                        className="w-[300px]"
                        size="small"
                        disabled={isEditable}
                      />
                    </div>

                    <div className="flex items-center mt-2">
                      <div className="text-[16px] flex w-64">Email*</div>
                      <Textinput
                        name="email"
                        label="email"
                        className="w-[300px]"
                        size="small"
                        disabled={isEditable}
                      />
                    </div>

                    <div className="flex flex-col mt-2">
                      <div className="flex">
                        <div className="text-[16px] flex w-64">Phone*</div>
                        <Phonecodecomponent name="country_code" />
                        <Textinput
                          name="phone"
                          label="Phone"
                          className="w-[240px]"
                          size="small"
                          type="number"
                          disabled={isEditable}
                        />
                      </div>
                      <div className="text-[14px]">
                        <i>***Preferably Whatsapp Number</i>
                      </div>
                    </div>

                    <div className="mt-3 flex  ">
                      <div className="flex w-64 mt-2">Physically Fit for</div>
                      <div className="flex flex-col flex-wrap h-[180px] w-[320px] gap-3">
                        <div>
                          <CheckboxesComponent
                            name="walking"
                            label="Walking"
                            disabled={isEditable}
                          />
                        </div>
                        <div>
                          <CheckboxesComponent
                            name="games"
                            label="Games"
                            disabled={isEditable}
                          />
                        </div>
                        <div>
                          <CheckboxesComponent
                            name="asanas"
                            label="Asanas"
                            disabled={isEditable}
                          />
                        </div>
                        <div>
                          <CheckboxesComponent
                            name="climbing"
                            label="Climbing"
                            disabled={isEditable}
                          />
                        </div>
                        <div>
                          <CheckboxesComponent
                            name="sadhana"
                            label="Sadhana"
                            disabled={isEditable}
                          />
                        </div>
                        <div>
                          <CheckboxesComponent
                            name="seva"
                            label="Seva activities"
                            disabled={isEditable}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="flex gap-3">
                      <div className="text-[16px] mt-2 w-64">
                        Are you under any prescribed medical treatment?*
                      </div>
                      <Radiogroup
                        name="medical_treatment"
                        label="medical"
                        options={options2}
                        direction={true}
                        disabled={isEditable}
                      />
                    </div>

                    <div className="flex gap-3">
                      <div className="text-[16px] mt-2 w-64">
                        Have you undergone any psychiatric treatment?*
                      </div>
                      <Radiogroup
                        name="psychiatric_treatment"
                        label="psychiatric"
                        options={options2}
                        direction={true}
                        disabled={isEditable}
                      />
                    </div>

                    <div className="flex gap-3">
                      <div className="text-[16px] mt-2 w-64">
                        Do you follow only vegetarian diet?
                      </div>
                      <Radiogroup
                        name="vegetarian_diet"
                        label="vegetarian"
                        options={options2}
                        direction={true}
                        disabled={isEditable}
                      />
                    </div>

                    <div className="mt-3 flex  ">
                      <div className="flex w-64 mt-2">
                        Any other issues/difficulties*
                      </div>
                      <Textinput
                        name="other_issues"
                        label="issues"
                        className="w-[300px]"
                        size="small"
                        disabled={isEditable}
                      />
                    </div>

                    <div>
                      Programs (Part 2 / Sanyam / Blessings) done in past 1 year
                      (from the date of application)
                    </div>
                    <div className="p-5 flex flex-col gap-3">
                      <div className="flex gap-3">
                        <div className="text-[16px] mt-2 w-60">Part2</div>
                        <div className="flex flex-col gap-2">
                          <Radiogroup
                            name="advance_program"
                            label="part2"
                            options={options2}
                            direction={true}
                            disabled={isEditable}
                          />
                          <Textfieldpage1
                            name="advance_program_details"
                            dependentName="advance_program"
                            disabled={true}
                          ></Textfieldpage1>
                        </div>
                      </div>

                      <div className="flex gap-3">
                        <div className=" text-[16px] mt-2 w-60">Blessings</div>
                        <div className="flex flex-col gap-2">
                          <Radiogroup
                            name="blessings_program"
                            label="blessings"
                            options={options2}
                            direction={true}
                            disabled={isEditable}
                          />
                          <Textfieldpage1
                            name="blessings_program_details"
                            dependentName="blessings_program"
                            disabled={true}
                          ></Textfieldpage1>
                        </div>
                      </div>

                      <div className="flex gap-3">
                        <div className=" text-[16px] w-60 mt-2">Sanyam</div>
                        <div className="flex flex-col gap-2">
                          <Radiogroup
                            name="sanyam_program"
                            label="sanyam"
                            options={options2}
                            direction={true}
                            disabled={isEditable}
                          />
                          <Textfieldpage1
                            name="sanyam_program_details"
                            dependentName="sanyam_program"
                            disabled={true}
                          ></Textfieldpage1>
                        </div>
                      </div>
                    </div>

                    <div className="flex gap-3">
                      <div className="text-[16px] mt-2 w-64">
                        I have Heard (In past 1 year from the date of
                        application)
                      </div>
                      <div className="flex flex-col flex-wrap h-[130px] w-[320px]">
                        <div>
                          <CheckboxesComponent
                            name="heard_ashtavakra"
                            label="Ashtavakra Gita"
                            disabled={isEditable}
                          />
                        </div>
                        <div>
                          <CheckboxesComponent
                            name="heard_patanjali"
                            label="Patanjali Yoga Sutra"
                            disabled={isEditable}
                          />
                        </div>
                        <div>
                          <CheckboxesComponent
                            name="heard_narad_bhakti"
                            label="Narad Bhakti Sutra"
                            disabled={isEditable}
                          />
                        </div>
                        <div>
                          <CheckboxesComponent
                            name="heard_shiv_sutra"
                            label="Shiv Sutra"
                            disabled={isEditable}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="flex gap-3">
                      <div className="text-[16px] mt-2 w-64">
                        I have read (In past 1 year from the date of
                        application)
                      </div>
                      <div className="flex flex-wrap h-[120px] w-[400px]">
                        <div>
                          <CheckboxesComponent
                            name="read_celebrating_silence"
                            label="Celebrating Silence"
                            disabled={isEditable}
                          />
                        </div>
                        <div>
                          <CheckboxesComponent
                            name="read_ashtavakra"
                            label="Ashtavakra Gita"
                            disabled={isEditable}
                          />
                        </div>
                        <div>
                          <CheckboxesComponent
                            name="read_walk_like_a_king"
                            label="Walk like a King and be a Perfect Servant"
                            disabled={isEditable}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="flex items-center mt-3">
                      <div className="flex w-[270px]">Teacher since*</div>
                      <Datefield
                        name="teacher_since"
                        label="Teacher since"
                        className="w-[300px]"
                        size="small"
                        disabled={isEditable}
                      />
                    </div>
                    <div className="flex items-center">
                      <div className="text-[16px] flex w-[270px]">
                        Are you a Part time / Full time teacher?
                      </div>
                      <Typedropdown
                        name="teacher_type"
                        label="parttime/fulltime"
                        className="w-[300px] mt-2"
                        options={option3}
                        disabled={isEditable}
                      />
                    </div>

                    <div className="flex items-center">
                      <div className="flex w-[270px]">Programs taught*:</div>
                      <Textinput
                        name="programs_taught"
                        label="programstaught"
                        className="w-[300px]"
                        size="small"
                        disabled={isEditable}
                      />
                    </div>

                    <div>Happiness Program conducted* (last 1 year)</div>
                    <div className="flex items-center">
                      <div className="flex w-[270px]">
                        Number of Happiness Programs*:
                      </div>
                      <Textinput
                        name="hp_last_year_count"
                        label="No. of Happiness Programs*:"
                        className="w-[300px]"
                        size="small"
                        disabled={isEditable}
                      />
                    </div>

                    <div className="flex items-center">
                      <div className="flex w-[270px]">Number of Pax*:</div>
                      <Textinput
                        name="pax_last_year_count"
                        label="Pax"
                        className="w-[300px]"
                        size="small"
                        disabled={isEditable}
                      />
                    </div>

                    <div className="mt-3 flex">
                      <div className="flex w-72">
                        Do you practice Sahaj Samadhi Meditation?
                      </div>
                      <Radiogroup
                        name="sahaj_practice"
                        label="Practice"
                        options={options4}
                        direction={true}
                        disabled={isEditable}
                      ></Radiogroup>
                    </div>

                    <div className="flex items-center">
                      <div className="flex w-72">
                        Guru Puja Phase 1 details*:
                      </div>
                      <Textinput
                        name="guru_pooja_phase1"
                        label="phase1"
                        placeholder="Course Date, Venue, Teacher"
                        className="w-[300px]"
                        size="small"
                        disabled={isEditable}
                      />
                    </div>

                    <div className="flex items-center">
                      <div className="flex w-72">
                        Guru Puja Phase 2 details*:
                      </div>
                      <Textinput
                        name="guru_pooja_phase2"
                        placeholder="Course Date, Venue, Teacher"
                        // label="phase2"
                        className="w-[300px]"
                        size="small"
                        disabled={isEditable}
                      />
                    </div>

                    <div className="flex items-center">
                      <div className="flex w-72">
                        How many public Gurupujas have you done?
                      </div>
                      <Textinput
                        name="public_guru_pooja_count"
                        label="GuruPujasDone"
                        className="w-[300px]"
                        size="small"
                        disabled={isEditable}
                      />
                    </div>

                    <div className="flex items-center gap-8">
                      <div className="flex w-64 border-2 border-[#000000] p-1">
                        Are you thorough with Guru Puja chanting
                        (Pronunciation), Meaning and Performance?
                      </div>
                      <Radiogroup
                        name="guru_pooja_chanting"
                        label="GuruPujaDetail"
                        options={options5}
                        direction={true}
                        disabled={isEditable}
                      />
                    </div>

                    <div className="flex items-center gap-10">
                      <div className="flex flex-col w-64">
                        <div className="flex">Gurupuja Audio Link*:</div>
                        <div className="text-[14px]">
                          (Note: Audio chanting should be clear)
                        </div>
                      </div>
                      <div className="flex">
                        <LinkTextinput name="guru_pooja_audio_link"></LinkTextinput>
                        <ImportantNote note="audio" />
                      </div>
                    </div>

                    <div className="flex items-center gap-10">
                      <div className="flex flex-col w-64">
                        <div className="flex">Gurupuja Video Link*:</div>
                        <div className="text-[14px]">
                          (Note: Video should be visible properly during
                          performance else your application may get rejected)
                        </div>
                      </div>
                      <div className="flex">
                        <LinkTextinput name="guru_pooja_video_link"></LinkTextinput>
                        <ImportantNote note="video" />
                      </div>
                    </div>

                    <div className="flex flex-col gap-4">
                      <div className="">Seva*:</div>

                      <div className="flex gap-2">
                        <div className="flex w-72">
                          Number of Sahaj Samadhi Programs organized:
                        </div>
                        <Textinput
                          name="sahaj_program_count"
                          label="No. of Sahaj Samadhi organized"
                          className="w-[300px]"
                          size="small"
                          disabled={isEditable}
                        />
                      </div>

                      <div className="flex gap-2">
                        <div className="flex w-72">
                          Number of Participants Registered for Sahaj Samadhi
                          Meditation:
                        </div>
                        <Textinput
                          name="sahaj_participant_count"
                          label="No Of Participates for sahajsamadhi"
                          className="w-[300px]"
                          size="small"
                          disabled={isEditable}
                        />
                      </div>
                    </div>

                    <div className="flex flex-col gap-4">
                      <div className="">
                        Why would you like to become a Sahaj Samadhi Meditation
                        Program teacher? <br></br>Explain briefly in not more
                        than 100 words.*
                      </div>
                      <Textinput
                        name="reason"
                        label="Text Area"
                        className="w-[700px]"
                        size="small"
                        rows={3}
                        lines={true}
                        disabled={isEditable}
                      />
                    </div>

                    <div className="flex flex-col gap-4">
                      <div className="font-bold">Recommending Teacher*:</div>
                      <div className="flex gap-2">
                        <div className="flex w-72">Name of Teacher:</div>
                        <Textinput
                          name="recommending_teacher_name"
                          label="No Of Participates for sahajsamadhi"
                          className="w-[300px]"
                          size="small"
                          disabled={isEditable}
                        />
                      </div>

                      <div className="flex gap-2">
                        <div className="flex w-72">
                          Teacher's email address:
                        </div>
                        <div className="flex flex-col">
                          <Textinput
                            name="recommending_teacher_email"
                            label="No. of Sahaj Samadhi organized"
                            className="w-[300px]"
                            size="small"
                            disabled={isEditable}
                          />
                          <div>
                            <i className="font-semibold text-[red] text-[12px]">
                              ***Enter Teacher's Harmony Credentials
                            </i>
                          </div>
                        </div>
                      </div>

                      <div className="flex gap-2">
                        <div className="flex w-72">Teacher's Code:</div>
                        <Textinput
                          name="recommending_teacher_code"
                          label="No. of Sahaj Samadhi organized"
                          className="w-[300px]"
                          size="small"
                          disabled={isEditable}
                        />
                      </div>
                    </div>

                    <div className="flex items-center h-[45px] gap-2">
                      <div>Declaration*:</div>
                      <div className="  mt-2">
                        <input
                          type="checkbox"
                          className="h-[20px] w-[20px]"
                          name="declaration"
                          defaultChecked={true}
                          disabled={isEditable}
                        />
                      </div>
                    </div>

                    <div className="w-[695px] border-black border-2 text-[#898989] flex flex-col p-3 rounded-[5px]">
                      I understand that it is not mandatory for me to become a
                      sahaj Samadhi Dhyan teacher at the end of Sahaj Samadhi
                      Meditation TTP and that if required I will be called to
                      repeat the Sahaj Samadhi Meditation TTP.
                    </div>

                    <div className="flex gap-10">
                      <div className=" text-[16px] w-64 mt-2 font-bold">
                        Recommended by Recommending Teacher*:
                      </div>
                      <div className="flex flex-col">
                        <Radiogroup
                          name="teacher_recommendation"
                          label="Recommended_SSM"
                          options={options2}
                          direction={true}
                          disabled={isEditable}
                        />
                      </div>
                    </div>

                    <div className="flex flex-col gap-4">
                      <div className="font-bold">
                        Recommending Teacher Remarks (Not more than 500
                        characters)*:
                      </div>
                      <div>
                        <Textinput
                          name="recommending_teacher_remarks"
                          id="outlined-multiline-static"
                          multiline
                          rows={3}
                          className="w-[700px]"
                          disabled={isEditable}
                        />
                      </div>
                    </div>

                    <div className="flex gap-10">
                      <div className=" text-[16px] w-64 mt-2 font-bold">
                        Recommended by Evaluator*:
                      </div>
                      <div className="flex flex-col">
                        <Radiogroup
                          name="evaluator_recommendation"
                          label="Recommended_SSM"
                          options={options2}
                          direction={true}
                          disabled={isEditable}
                        />
                      </div>
                    </div>

                    <div className="flex flex-col gap-4">
                      <div className="font-bold">
                        Evaluator Remarks (Not more than 500 characters)*:
                      </div>
                      <div>
                        <Textinput
                          name="evaluator_remarks"
                          id="outlined-multiline-static"
                          multiline
                          rows={3}
                          className="w-[700px]"
                          disabled={isEditable}
                        />
                      </div>
                    </div>

                    <div className="flex gap-10">
                      <div className=" text-[16px] w-64 mt-2 font-bold">
                        Recommended by SahajCoordinator*:
                      </div>
                      <div className="flex flex-col">
                        <Radiogroup
                          name="sahaj_co_recommendation"
                          label="Recommended_SSM"
                          options={options2}
                          direction={true}
                          disabled={isEditable}
                        />
                      </div>
                    </div>

                    <div className="flex flex-col gap-4">
                      <div className="font-bold">
                        SahajCoordinator Remarks (Not more than 500
                        characters)*:
                      </div>
                      <div>
                        <Textinput
                          name="sahaj_coordinator_remarks"
                          id="outlined-multiline-static"
                          multiline
                          rows={3}
                          className="w-[700px]"
                          disabled={isEditable}
                        />
                      </div>
                    </div>

                    <div className="flex gap-10">
                      <div className=" text-[16px] w-64 mt-2 font-bold">
                        Recommended by Country Coordinator*:
                      </div>
                      <div className="flex flex-col">
                        <Radiogroup
                          name="country_co_recommendation"
                          label="Recommended_SSM"
                          options={options2}
                          direction={true}
                          disabled={isEditable}
                        />
                      </div>
                    </div>

                    <div className="flex flex-col gap-4">
                      <div className="font-bold">
                        Country Coordinator Remarks (Not more than 500
                        characters)*:
                      </div>
                      <div>
                        <Textinput
                          name="country_coordinator_remarks"
                          id="outlined-multiline-static"
                          multiline
                          rows={3}
                          className="w-[700px]"
                          disabled={isEditable}
                        />
                      </div>
                    </div>

                    <div className="flex gap-10">
                      <div className=" text-[16px] w-64 mt-2 font-bold">
                        Recommended by India Sahaj Desk Coordinator*:
                      </div>
                      <div className="flex flex-col">
                        <Radiogroup
                          name="india_sahaj_co_recommendation"
                          label="Recommended_SSM"
                          options={options2}
                          direction={true}
                          disabled={isEditable}
                        />
                      </div>
                    </div>

                    <div className="flex flex-col gap-4">
                      <div className="font-bold">
                        India Sahaj Desk Coordinator Remarks (Not more than 500
                        characters)*:
                      </div>
                      <div>
                        <Textinput
                          name="india_sahaj_coordinator_remarks"
                          id="outlined-multiline-static"
                          multiline
                          rows={3}
                          className="w-[700px]"
                          disabled={isEditable}
                        />
                      </div>
                    </div>

                    <div className="flex gap-10">
                      <div className=" text-[16px] w-64 mt-2 font-bold">
                        Your Recommendation*:
                      </div>
                      <div className="flex flex-col">
                        <Radiogroup
                          name="ttp_office_reccomendation"
                          label="Recommended_SSM"
                          options={options2}
                          direction={true}
                        />
                      </div>
                    </div>

                    <div className="flex flex-col gap-4">
                      <div className="font-bold">
                        Your Remarks (Not more than 500 characters)*:
                      </div>
                      <div>
                        <Textinput
                          name="ttp_office_remarks"
                          id="outlined-multiline-static"
                          multiline
                          rows={3}
                          className="w-[700px]"
                        />
                      </div>
                    </div>

                    <div className="flex gap-10">
                      <div>
                        <Button
                          type="submit"
                          variant="contained"
                          color="success"
                          startIcon={<CheckCircleRoundedIcon />}
                          onClick={() => { }}
                        >
                          Submit Application
                        </Button>
                      </div>
                    </div>
                  </div>
                </Formfield>
              )}
            </div>
          </div>
        </div>
        {/* <div
          className="flex bg-[black] bg-opacity-10 rounded-lg mb-[650px] absolute left-10 top-[100px] cursor-pointer w-[45px] h-[45px] rounded-[45px] justify-center items-center"
          onClick={() => Navigate(-1)}
        >
          <ArrowBackRoundedIcon color="black" size="large" />
        </div> */}
      </div>
    </div>
  );
}

async function downloadExcel(applicationData) {
  const sheetName = "Applicant Information";
  const workbook = await convertToExcel(
    sheetName,
    [applicationData],
    ["profile_picture"],
    [
      "country_co_recommendation",
      "evaluator_recommendation",
      "teacher_recommendation",
      "invitation_email_sent",
      "read_walk_like_a_king",
      "read_ashtavakra",
      "read_celebrating_silence",
      "heard_shiv_sutra",
      "heard_narad_bhakti",
      "heard_patanjali",
      "heard_ashtavakra",
      "sanyam_program",
      "blessings_program",
      "advance_program",
      "vegetarian_diet",
      "psychiatric_treatment",
      "medical_treatment",
      "seva",
      "asanas",
      "walking",
      "climbing",
      "games",
      "sadhana",
      "ttp_office_reccomendation",
      "evaluator_response",
      "sahaj_coordinator_response",
      "country_coordinator_response",
      "india_sahaj_coordinator_response",
      "ttp_desk_response",
    ],
    ["applicant_teacher_code", "applicant_country"]
  );
  
  await addImageToWorksheet(
    workbook,
    applicationData.profile_picture,
    1,
    Object.keys(applicationData).indexOf("profile_picture")
  );
  autoAdjustColumnWidth(workbook.worksheets[0], [
    "Programs Taught",
    "Guru Pooja Audio Link",
    "Guru Pooja Video Link",
    "Guru Pooja Phase1",
    "Guru Pooja Phase2",
    "Reason",
    "Recommending Teacher Remarks",
    "Ttp Office Remarks",
  ]);
  saveXLSX(workbook, `Applicant - ${applicationData.name}`);
}

export default TtpDeskPage2;

import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  page3data: "",
};
export const Page3Reducer = createSlice({
  name: "page3data",
  initialState: initialState,
  reducers: {
    Page3FormData: (state, action) => {
      state.page3data = action.payload;
    },
  },
});
export const { Page3FormData } = Page3Reducer.actions;
export default Page3Reducer.reducer;
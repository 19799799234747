import { Checkbox, FormControl, FormControlLabel } from "@mui/material";
import { useController } from "react-hook-form";

export default function CheckBoxGroupComponent({
  id,
  header,
  name,
  fields,
  styles,
}) {
  const {
    field: { value = {}, onChange },
    fieldState: { error },
  } = useController({ name: name });
  return (
    <div>
      <div className="mt-3 flex flex-col" id={id}>
        <div className="flex" id="checkbox-fields">
          <div className="flex w-64 mt-2" id="checkbox-header">
            {header}
          </div>
          <div
            className={`flex flex-col flex-wrap pl-3  ${styles}`}
            id="checkboxs"
          >
            {fields.map((field) => {
              return (
                <div>
                  <FormControl error={true}>
                    <div>
                      <FormControlLabel
                        control={
                          <Checkbox
                            onChange={(e) => {
                              if (e.target.checked) {
                                onChange({ ...value, [field["name"]]: true });
                              } else {
                                onChange({ ...value, [field["name"]]: false });
                              }
                            }}
                            checked={value[field.name]}
                            className="mt-2"
                          />
                        }
                        label={field.label}
                      />
                    </div>
                  </FormControl>
                </div>
              );
            })}
          </div>
        </div>
        <div
          className="text-[12px] ml-[285px] -mt-[24px] mb-4 text-[#D43535]"
          id="checkbox-error"
        >
          {error?.message}
        </div>
      </div>
    </div>
  );
}

import * as yup from "yup";

//yup validations for all the fileds in the stepper\\

export const validationSchema = yup.object({
  name: yup
    .string()
    .matches(/^[a-zA-Z0-9.@$#%!&*()-_ ]*$/, "Name field is required")
    .required("Name field is required"),

  image: yup
    .mixed()
    .required("Image is required")
    .test("fileSize", "File size is too big", (value) => {
      if (!value) {
        return true;
      }
      return value.size / 1024 <= 1024;
    })
    .test("fileType", "File format not supported", (value) => {
      if (!value) {
        return true;
      }
      const allowedTypes = [
        "image/jpeg",
        "image/jpg;",
        "image/png;",
        "image/jpg",
        "image/png",
      ];
      return allowedTypes.includes(value.type);
    }),

  date_of_birth: yup
    .date()
    .test(
      "required",
      "Date of birth field is required",
      (value) => value !== "mm-dd-yyyy"
    )
    .required("Date of birth field is required"),

  gender: yup.string().required("Gender field is required"),

  address: yup
    .string()
    .test("max-lines", "Text field must have exactly 2 lines", (value) => {
      const lines = value.split(/\r\n|\r|\n/);
      return lines.length <= 2;
    })
    .required("Address field is required"),

  country: yup.string().required("Country field is required"),

  state: yup.string().required("State field is required"),

  city: yup
    .string()
    .matches(/^[a-zA-Z ]*$/, "City must be string ")
    .required("City field is required"),

  postal_code: yup
    .string()
    .matches(/^[a-zA-Z0-9]*$/, "Postal code must be a valid")
    .max(10, "Postal code must be a valid")
    .required("Postal code field is required"),

  email: yup
    .string()
    // .email()
    .matches(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      "Please enter the valid email address"
    )
    .required("Email field is required"),

  phone: yup
    .string()
    .matches(/^[0-9]*$/, "must be a valid phone number")
    .max(11)
    .min(7)
    .required("Phone field is required"),

  physical: yup
    .object()
    .test("at-least-one-true", "Please select one", (value) =>
      Object.values(value).some((fieldValue) => fieldValue === true)
    )
    .required("Please select one in physically fit"),

  heard: yup
    .object()
    .test("at-least-one-true", "Please select one", (value) =>
      Object.values(value).some((fieldValue) => fieldValue === true)
    )
    .required("Please select one in heard"),

  read: yup
    .object()
    .test("at-least-one-true", "Please select one", (value) =>
      Object.values(value).some((fieldValue) => fieldValue === true)
    )
    .required("Please select one in read"),

  medical_treatment: yup
    .string()
    .required("Medical treatment field is required"),

  psychiatric_treatment: yup
    .string()
    .required("Psychiatric treatment field is required"),

  vegetarian_diet: yup.string().required("Vegetarian or not field is required"),
  teacher_since: yup.string().required(),

  teacher_type: yup.string().required("Teacher type field is required"),

  programs_taught: yup
    .string()
    .matches(/^[a-zA-Z0-9,+' ]*$/, "Program taught field should be valid")
    .required("Program taught field is required"),

  hp_last_year_count: yup
    .number()
    .min(0)
    .test(
      "maxDigits",
      "Sahaj Program Count must have less than 10 digits",
      (value) => (value ? value.toString().length <= 10 : true)
    )
    .integer()
    .required("Number of happiness programs field is required")
    .nullable("Number of happiness programs field is required")
    .typeError("Number of happiness programs field is required"),

  pax_last_year_count: yup
    .number()
    .min(0)
    .test(
      "maxDigits",
      "Number of participant must have less than 10 digits",
      (value) => (value ? value.toString().length <= 10 : true)
    )
    .integer()
    .required("Number of participant field is required")
    .nullable()
    .typeError("Number of participant field is required"),

  public_guru_pooja_count: yup
    .number()
    .min(0)
    .test(
      "maxDigits",
      "Guru puja count must have less than 10 digits",
      (value) => (value ? value.toString().length <= 10 : true)
    )
    .integer()
    .typeError("Guru puja count field is required")
    .required("Guru puja count field is required"),

  guru_pooja_phase1: yup
    .string()
    .matches(/^[A-Za-z0-9,-/ ]+$/, "Guru Pooja Phase1 Details must be vaild ")
    .required("Guru puja phase1 details is required "),

  guru_pooja_phase2: yup
    .string()
    .matches(/^[A-Za-z0-9,-/ ]+$/, "Guru Pooja Phase2 Details must be vaild ")
    .required("Guru puja phase2 details is required "),

  // public_guru_pooja_count: yup
  //   .boolean()
  //   .required("Public guru puja count is required"),

  guru_pooja_chanting: yup
    .number()
    .min(0)
    .test(
      "maxDigits",
      "Guru puja chanting must have less than 10 digits",
      (value) => (value ? value.toString().length <= 10 : true)
    )
    .integer()
    .typeError("Guru puja chanting field is required")
    .required("Guru puja chanting field is required"),

  guru_pooja_audio_link: yup
    .string()
    .matches(
      /(http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/,
      "Link must be a valid url"
    )
    .required("Link must be a required"),

  guru_pooja_video_link: yup
    .string()
    .matches(
      /(http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/,
      "Link must be a valid url"
    )
    .required("Link must be a required"),

  sahaj_participant_count: yup
    .number()
    .min(0)
    .integer()
    .test(
      "maxDigits",
      "Sahaj Participate Count must have less than 10 digits",
      (value) => (value ? value.toString().length <= 10 : true)
    )
    .typeError("Sahaj Participate Count field is required")
    .required("Sahaj participate count field is required"),

  sahaj_program_count: yup
    .number()
    .min(0)
    .integer()
    .test(
      "maxDigits",
      "Sahaj Program Count must have less than 10 digits",
      (value) => (value ? value.toString().length <= 10 : true)
    )
    .typeError("Sahaj Program Count field is required")
    .required("Sahaj program count field is required"),

  recommending_teacher_name: yup
    .string()
    .matches(/^[a-zA-Z0-9 ]*$/, "Teacher name should be valid")
    .required("Recommending teacher name field is required "),

  recommending_teacher_email: yup
    .string()
    .min(10,"")
    // .email()
    .matches(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      "Please enter the valid email address"
    )
    .test("is-not-localStorage-data", "", (value) => {
      // Check if the value comes from localStorage and is 1 or 0
      const dataFromapi = parseInt(localStorage.getItem("api"));
      if (dataFromapi === 1) {
        return true;
      }
      if (dataFromapi === 0) {
        return false;
      }
      if (dataFromapi === 2) {
        return true;
      }
      return true;
    })
    .required("Recommending teacher email field is required "),

  recommending_teacher_code: yup
    .string()
    .max(12, "Teacher Code must be valid")
    .required("Recommending teacher code field is required"),

  recommending_teacher_country: yup.string().required("Recommending Teacher Country field is required"),

  declaration: yup
    .boolean()
    .oneOf([true], "")
    .required("Declaration is required"),

  reason: yup
    .string()
    .max(500, "Remarks should not exceed 500 characters")
    .required("Reason field is required"),

  advance_program: yup.boolean().required("Advance Program is required field"),
  
  blessings_program: yup
    .boolean()
    .required("Blessing Program is required field"),
  sanyam_program: yup.boolean().required("Sanyam is required field"),

  advance_program_details: yup
    .string()
    .when("advance_program", {
      is: (val) => {
        return val === true;
      },
      then: () => yup.string().required("Advance Program Details are required"),
    })
    .optional()
    .nullable(),

  blessings_program_details: yup
    .string()
    .when("blessings_program", {
      is: (val) => {
        return val === true;
      },
      then: () =>
        yup.string().required("Blessing Program Details are required"),
    })
    .optional()
    .nullable(),

  sanyam_program_details: yup
    .string()
    .when("sanyam_program", {
      is: (val) => {
        return val === true;
      },
      then: () => yup.string().required("Sanyam Program Details are required"),
    })
    .optional()
    .nullable(),
});

import React, { useState } from "react";
import "../historyform/Historyform.css";

import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import { SearchOutlined } from "@mui/icons-material";
import { TextField, IconButton } from "@mui/material";
// import { styled } from "@mui/material/styles";

import { HistoryFormApi } from "../../apis/historyformapi/HistoryFormapi";
import { useQuery } from "@tanstack/react-query";
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import { useNavigate } from "react-router-dom";
import Navbar from "../../components/navbar/Navbar";
import CustomNoRowsOverlay from "../form/noformdata/NoFormData";

const columns = [
  // {
  //   field: "id",
  //   headerName: "Id",
  //   headerClassName: "super-app-theme--header",
  //   headerAlign: "center",
  //   align: "center",
  //   width: 80,
  //   sortable: false,
  // },
  {
    field: "ttp",
    headerName: "TTP",
    headerClassName: "super-app-theme--header",
    headerAlign: "center",
    align: "center",
    width: 250,
    sortable: false,
  },
  {
    field: "name",
    headerName: "Name",
    headerClassName: "super-app-theme--header",
    headerAlign: "center",
    align: "center",
    width: 200,
    sortable: false,
  },
  {
    field: "email",
    headerName: "Email",
    headerClassName: "super-app-theme--header",
    headerAlign: "center",
    align: "center",
    width: 287,
    sortable: false,
  },
  {
    field: "country",
    headerName: "Country",
    headerClassName: "super-app-theme--header",
    headerAlign: "center",
    align: "center",
    width: 140,
    sortable: false,
  },
  {
    field: "registration_date",
    headerName: "Registration Date",
    headerClassName: "super-app-theme--header",
    headerAlign: "center",
    align: "center",
    width: 150,
    sortable: false,
  },
  {
    field: "response",
    headerName: "My Recommentation",
    headerClassName: "super-app-theme--header",
    headerAlign: "center",
    align: "center",
    sortable: false,
    width: 180,
    renderCell: (params) => {
      if (params.row.response === 2) {
        return <div>closed</div>;
      } else {
        return <div>Approved</div>;
      }
    },
  },
  {
    field: "remarks",
    headerName: "Remarks",
    headerClassName: "super-app-theme--header",
    headerAlign: "center",
    align: "center",
    width: 250,
    sortable: false,
  },
];

export default function HistoryForm() {
  const [searchvalue, setSearchvalue] = useState("");

  const Navigate = useNavigate();

  const handleInputChange = (e) => {
    const { value, name } = e.target;
    setSearchvalue(value);
  };

  const role =
    localStorage.getItem("role") || localStorage.getItem("india_role");
  const role_email =
    JSON.parse(localStorage.getItem("user"))?.username ||
    JSON.parse(localStorage.getItem("india_user"))?.email;

  const params = {
    recommending_person_email: role_email,
    role: role,
    email: searchvalue,
  };

  const { data = [], isLoading } = useQuery(
    ["history-appliactions", params],
    HistoryFormApi,
    {
      select: (data) => data?.data,
    }
  );

  function CustomNoRows() {
    return (
      <div className="mt-8">
        <CustomNoRowsOverlay theory="No application found"></CustomNoRowsOverlay>
      </div>
    );
  }

  return (
    <div>
      <div className="flex h-auto min-h-screen">
        <div className="w-[100%] h-screen flex flex-col">
          <div>
            <Navbar track={1}></Navbar>
          </div>

          <div className="m-auto flex overflow-y-auto scrollbar">
            <div className="flex flex-col gap-4">
              <div className="flex w-[100%] items-end justify-end">
                <TextField
                  name="email"
                  className="w-[350px]"
                  onChange={handleInputChange}
                  variant="outlined"
                  placeholder="Please enter email for search"
                  InputProps={{
                    endAdornment: (
                      <IconButton>
                        <SearchOutlined />
                      </IconButton>
                    ),
                  }}
                />
              </div>
              {data.length === 0 ? (
                <div className="flex text-[20px] font-semibold justify-center items-center">
                  No application found
                </div>
              ) : (
                <div className="flex justify-center items-center">
                  <Box
                    sx={{
                      height: 630,
                      width: "100%",
                      "& .super-app-theme--header": {
                        backgroundColor: "RGB(106 170 234)",
                        color: "white",
                      },
                      fontWeight: 900,
                      fontSize: 16,
                      align: "center",
                    }}
                  >
                    {isLoading ? (
                      <div>Loading......</div>
                    ) : (
                      <DataGrid
                        rows={data}
                        columns={columns}
                        align="center"
                        slots={{
                          noRowsOverlay: CustomNoRows,
                        }}
                        pageSize={10}
                        pagination={true}
                        pageSizeOptions={[10, 25, 50, 100]}
                        rowCount={data?.length}
                        disableColumnMenu
                      />
                    )}
                  </Box>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div
        className="flex bg-[black] bg-opacity-10 rounded-lg mb-[650px] absolute left-10 top-[100px] cursor-pointer w-[45px] h-[45px] rounded-[45px] justify-center items-center"
        onClick={() => Navigate(-1)}
      >
        <ArrowBackRoundedIcon color="black" size="large" />
      </div>
    </div>
  );
}

import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  page4filedata: "",
  page4data: "",
};
export const Page4Reducer = createSlice({
  name: "page4data",
  initialState: initialState,
  reducers: {
    Page4FormData: (state, action) => {
      state.page4data = action.payload;
      if (action.type === "file") {
        state.page4filedata = action.payload;
      }
    },
  },
});
export const { Page4FormData } = Page4Reducer.actions;
export default Page4Reducer.reducer;

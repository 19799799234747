import React, { useState } from "react";
import "./India_Sahaj_Desk_Coordinator.css";
import { useQuery, useQueryClient } from "@tanstack/react-query";

import Navbar from "../../components/navbar/Navbar";

import { SahajIndiaCoordinatorApi } from "../../apis/sahajindiacoordinatorapi/SahajIndiaCoordinatorApi";
import CustomNoRowsOverlay from "../../commomComponents/form/noformdata/NoFormData";

const handleKeyDown = (e, name) => {
  if (name === "phone") {
    const keyCode = e.keyCode || e.which;
    if (keyCode === 8 || keyCode === 46 || (keyCode >= 37 && keyCode <= 40)) {
      return;
    }
    if (keyCode < 48 || keyCode > 57) {
      e.preventDefault();
    }
  }
};

function IndianSahajDeskCoordinator() {
  const queryClient = useQueryClient();

  const [value, setValue] = useState({});

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (value.length >= 3) {
      setValue((prevState) => ({ ...prevState, [name]: value }));
    } else {
      const newvalue = { ...value };
      delete newvalue[name];
      setValue(newvalue);
    }
  };

  const handleSelect = (e) => {
    const { name, value: selectedValue } = e.target;
    if (selectedValue === "true" || selectedValue === "false") {
      setValue((prevState) => ({ ...prevState, [name]: selectedValue }));
    }
    if (selectedValue === "select") {
      const newvalue = { ...value };
      delete newvalue[name];
      setValue(newvalue);
    }
  };

  const { data, isLoading } = useQuery(
    ["form-data-india", value],
    SahajIndiaCoordinatorApi,
    {
      select: (data) => data?.data,
    }
  );

  return (
    <div className="w-[100%] h-screen flex flex-col">
      <div>
        {" "}
        <Navbar track={9}></Navbar>
      </div>

      <div className="p-4 mt-8 border-1 border-[black] overflow-y-auto scrollbar">
        <div className="w-full h-[700px] overflow-x-auto border-2 border-inherit">
          <table className="table-auto">
            <thead>
              <th className="thead_th">Application</th>
              {columns?.map((item, Index) => (
                <th key={Index} className="thead_th">
                  <div className="flex flex-col gap-2 p-1">
                    <div>{item.name}</div>
                    {item.search === true ? (
                      <div>
                        <input
                          type="search"
                          name={item.dbname}
                          className="w-[160px] h-[40px] p-1 rounded-lg"
                          placeholder={item.placeholder}
                          onChange={handleInputChange}
                          onKeyDown={(e) => handleKeyDown(e, item.dbname)}
                        ></input>
                      </div>
                    ) : (
                      ""
                    )}
                    {item?.dropdown === true ? (
                      <div>
                        <select
                          name={item.dbname}
                          className="h-[40px] w-[150px] p-1 rounded-lg"
                          onChange={handleSelect}
                        >
                          <option value="select">Select</option>
                          <option value={true}>Recommended</option>
                          <option value={false}>Not Recommended</option>
                        </select>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </th>
              ))}
            </thead>
            {isLoading ? (
              <div>Loading.....</div>
            ) : data?.length === 0 ? (
              <tbody>
                <tr>
                  <td
                    colSpan={columns.length - 15}
                    className="ml-[300px] border-none"
                  >
                    <div className="mt-32 text-[20px] font-semibold">
                      <CustomNoRowsOverlay theory="No application found" />
                    </div>
                  </td>
                </tr>
              </tbody>
            ) : (
              <tbody className="ttpdesk_tbody">
                {data?.map((data, Index) => (
                  <tr>
                    <td  id="tbody_td">
                      <a
                        href={`/india_sahaj_Coordinatorpage2?email=${data?.harmony_email}`}
                        className="text-[skyblue] underline cursor-pointer"
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={(e) => {
                          e.preventDefault();
                          // localStorage.setItem(
                          //   "emailData",
                          //   JSON.stringify(data?.harmony_email)
                          // );
                          window.open(`/india_sahaj_Coordinatorpage2?email=${data?.harmony_email}`, "_blank");
                          queryClient.invalidateQueries({
                            queryKey: ["application-data-ttp"],
                          });
                        }}
                        
                      >
                        View Application
                      </a>
                    </td>
                    {columns?.map((info, id) => (
                      <td>
                        {info?.render
                          ? info?.render(data[info.dbname])
                          : data[info.dbname]}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            )}
          </table>
        </div>
        {/* )} */}
      </div>
    </div>
  );
}

export default IndianSahajDeskCoordinator;

const columns = [
  {
    name: "Name",
    dbname: "name",
    search: true,
    dropdown: false,
    placeholder: "enter 3 char to search",
  },
  {
    name: "Email",
    dbname: "email",
    search: true,
    dropdown: false,
    placeholder: "enter 3 char to search",
  },
  {
    name: "Mobile",
    dbname: "phone",
    search: true,
    dropdown: false,
    placeholder: "enter 3 digits to search",
    onKeyDown: handleKeyDown,
  },
  {
    name: "Country",
    dbname: "country",
    search: true,
    dropdown: false,
    placeholder: "enter 3 char to search",
  },
  {
    name: "Teacher recommendation",
    dbname: "teacher_recommendation",
    render: (value) =>
      value
        ? "Recommended"
        : value === false
        ? "Not Recommended"
        : value === "null"
        ? ""
        : "",
    search: false,
    dropdown: true,
  },
  {
    name: "Evaluator recommendation",
    dbname: "evaluator_recommendation",
    render: (value) =>
      value
        ? "Recommended"
        : value === false
        ? "Not Recommended"
        : value === "null"
        ? ""
        : "",
    search: false,
    dropdown: true,
  },
  {
    name: "Recommending teacher remarks",
    dbname: "recommending_teacher_remarks",
    search: false,
    dropdown: false,
  },
  {
    name: "Evaluator remarks",
    dbname: "evaluator_remarks",
    search: false,
    dropdown: false,
  },
  {
    name: "State",
    dbname: "state",
    search: false,
    dropdown: false,
  },
  {
    name: "City",
    dbname: "city",
    search: false,
    dropdown: false,
  },
  {
    name: "Teacher Since",
    dbname: "teacher_since",
    search: false,
    dropdown: false,
  },
  {
    name: "Full time/Part Time Teacher",
    dbname: "teacher_type",
    search: false,
    dropdown: false,
  },
  {
    name: "Programs Taught",
    dbname: "programs_taught",
    search: false,
    dropdown: false,
  },
  {
    name: "No. of Happiness Program",
    dbname: "hp_last_year_count",
    search: false,
    dropdown: false,
  },
  {
    name: "No. of Pax",
    dbname: "pax_last_year_count",
    search: false,
    dropdown: false,
  },
  {
    name: "No. Of Sahaj Samadhi Organized",
    dbname: "sahaj_program_count",
    search: false,
    dropdown: false,
  },
  {
    name: "No. of pax registered for Sahaj Samadhi",
    dbname: "sahaj_participant_count",
    search: false,
    dropdown: false,
  },
  {
    name: "Guru Puja Phase 1 details",
    dbname: "guru_pooja_phase1",
    search: false,
    dropdown: false,
  },
  {
    name: "Guru Puja Phase 2 details",
    dbname: "guru_pooja_phase2",
    search: false,
    dropdown: false,
  },
  {
    name: "Guru Puja Audio Link",
    dbname: "guru_pooja_audio_link",
    search: false,
    dropdown: false,
  },
  {
    name: "Guru Puja Video Link",
    dbname: "guru_pooja_video_link",
    search: false,
    dropdown: false,
  },
];

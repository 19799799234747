import { BASE_URL_NODE } from "../../config"

export const updateRoleApi = async (dataall) => {
  const res = await fetch(`${BASE_URL_NODE}/updaterole`, {
    method: "POST",
    body: JSON.stringify(dataall),
    headers: {
      "Content-type": "application/json",
    },
  });
  return res;
};

import axios from "axios";
import { BASE_HARMOANY_NODE_URL } from "../../config";

export const ForgotPasswordApi = async (formdata) => {
  const headers = {
    Authorization: "56499d8fcaa45b2d05000001ebc4ffeab0954b59a8a07c104968541d",
  };
  return await axios.post(
    `${BASE_HARMOANY_NODE_URL}/authentication/forgot-password`,
    formdata,
    {
      headers,
    }
  );
};

/**
 * this component is used to upload the image, crop and compression of the image(>1mb) along with validations
 * In this 2 packages are used react-cropper(Crop the image),compressorjs(compression)
 */
import React, { useState, createRef, useEffect } from "react";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import { useController } from "react-hook-form";
import { Button } from "@mui/material";
import Compressor from "compressorjs";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import CloseIcon from "@mui/icons-material/Close";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { ApplicationDataApi } from "../../../apis/applicationreceivedataapi/ApplicationDataApi";
import { useQuery } from "@tanstack/react-query";

/**
 * this const is used to change the primary and secoundary color of the function i.e, we can customize the button
 */
const theme = createTheme({
  palette: {
    primary: {
      main: "#eaeaea",
    },
    secondary: {
      main: "#eaeaea",
    },
  },
});

export const CropImageField = ({
  id,
  register,
  label,
  name,
  control,
  className,
  lines,
  rows,
  type,
  max,
  getOptionLabel,
  getOptionValue = (val) => val,
  disabled,
  inputprops,
  ...rest
}) => {
  const [close, setClose] = useState(false);
  const [fileData, setFileData] = useState("");
  const [cropData, setCropData] = useState("");
  const [preview, setPreview] = useState(true);
  const [visiable, setVisiable] = useState(false);

  const cropperRef = createRef();

  const {
    field: { value, onChange },
    fieldState: { error },
  } = useController({
    name,
    control,
  });

  const harmony_email =
    JSON.parse(localStorage.getItem("user"))?.username ||
    JSON.parse(localStorage.getItem("india_user"))?.email;


    //when we save the application ,logout and when we login ae can get the application image from the api call

  const { data:imageData } = useQuery(
    ["application-image", harmony_email],
    ApplicationDataApi,
    {
      select: (data) => {
        return data?.data;
      },
    }
   
  );


  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setFileData(file);
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const imageDataURL = reader.result;
        setVisiable(true);
        setPreview(true);
        setClose(true);
        onChange({ url: imageDataURL, size: file.size, type: file.type });
      };
      reader.readAsDataURL(file);
    }
  };

  // this function is used to update the value url with the cropped image file
  const getCropData = () => {
    if (typeof cropperRef.current?.cropper !== "undefined") {
      const newCropData = cropperRef.current?.cropper
        .getCroppedCanvas()
        .toDataURL();
      setCropData(newCropData);
      setPreview(true);
      setVisiable(!visiable);
      onChange({ url: newCropData, size: value?.size, type: value?.type });
    }
  };

  // used to clear the image
  const handleClearImage = () => {
    onChange(null);
    setCropData(null);
    setPreview(false);
  };

  // this function is used to compress the file if it greater than 1mb, in this compressor pacakge is used
  const handleCompressFile = () => {
    if (error?.type === "fileSize") {
      new Compressor(fileData, {
        quality: 0.4,
        success(compressedResult) {
          const reader = new FileReader();
          reader.readAsDataURL(compressedResult);
          reader.onload = () => {
            const imageDataURL = reader.result;
            setPreview(true);
            setVisiable(true);
            onChange({
              url: imageDataURL,
              size: compressedResult.size,
              type: compressedResult.type,
            });
          };
        },
        error(err) {
          console.error(err.message);
        },
      });
    }
  };

  // handle close is used to close the error box by click the cross icon

  const handleClose = () => {
    setClose(!close);
  };

  // useEffect is used to close the error message box after 10 sec after it displaying
  useEffect(() => {
    let timer;
    if (error && close) {
      timer = setTimeout(() => {
        setClose(false);
      }, 10000);
    }
    return () => clearTimeout(timer);
  }, [error, close]);


  useEffect(() => {
    if (imageData?.profile_picture != "None" && value === undefined) {
      const base64Data = imageData?.profile_picture?.replace(
        /^data:image\/\w+;base64,/,
        ""
      );

      // Check if the string contains padding ('=' characters) and add them if needed
      const missingPadding = base64Data?.length % 4;
      if (missingPadding) {
        base64Data += "=".repeat(4 - missingPadding);
      }

      try {
        const decodedData = atob(base64Data);

        const fileSizeInBytes = decodedData?.length;
        const fileSizeInKB = fileSizeInBytes / 1024; // Size in KB

        // Get the header from the data URL
        const header = imageData?.profile_picture?.substring(5, 15);

        setPreview(true);
        // setImage(data?.profile_picture);
        onChange({
          url: imageData?.profile_picture,
          size: fileSizeInKB,
          type: header,
        });
      } catch (error) {
        console.error("Error decoding Base64:", error);
        // Handle the error, such as setting a default image or showing an error message
      }
    }
  }, [imageData?.profile_picture, preview]);

  return (
    <div>
      {visiable === true && (error === null || error === undefined) ? (
        <div>
          <Cropper
            ref={cropperRef}
            style={{ height: "400px", width: "400px" }}
            zoomTo={0.5}
            initialAspectRatio={1}
            src={value?.url}
            viewMode={1}
            minCropBoxHeight={10}
            minCropBoxWidth={10}
            background={false}
            responsive={true}
            autoCropArea={1}
            checkOrientation={false}
            guides={true}
          />
          <h1>
            <button style={{ float: "left" }} onClick={getCropData}>
              Save Image
            </button>
          </h1>
        </div>
      ) : (
        (value === undefined || value === null || error != null) && (
          <div className="flex gap-2 flex-col">
            <label htmlFor="upload-photo">
              <input
                style={{ display: "none" }}
                id="upload-photo"
                name="upload-photo"
                error={error}
                onChange={handleImageChange}
                type="file"
                accept=".jpg, .jpeg, .png"
              />
              <ThemeProvider theme={theme}>
                <Button
                  startIcon={
                    <CloudUploadIcon
                      style={{
                        fontSize: "22px",
                      }}
                    />
                  }
                  style={{
                    color: "#898989",
                    border: `${
                      error ? "1px solid #d43535" : "1px solid #c4c4c4"
                    }`,
                    display: "flex",
                    justifyContent: "flex-start",
                    fontSize: "12px",
                    width: "250px",
                    padding: "9px 12px",
                  }}
                  size="small"
                  variant="contained"
                  component="span"
                  color="secondary"
                >
                  Upload Image
                </Button>
              </ThemeProvider>
            </label>
            <div className="text-[13px]">
              Max file size: 1MB | Supported file formats: .jpg, .jpeg, .png
            </div>
          </div>
        )
      )}
      {visiable === false &&
      preview === true &&
      value?.url &&
      (imageData?.profile_picture != "None" || imageData?.profile_picture === "None") ? (
        <div>
          {/* 2 inches = 192px */}
          <img
            style={{ width: "192px", height: "192px" }}
            src={value?.url ? value?.url : imageData?.profile_picture}
          />
          <div
            onClick={handleClearImage}
            aria-label="Clear image"
            className="cursor-pointer pt-1 pl-1"
          >
            Clear Image
          </div>
        </div>
      ) : (
        ""
      )}
      {error && close && error?.type !== "nullable" && (
        <div className="flex bg-[#ffffff] gap-3 p-2 -mt-[70px] ml-[255px] !z-10 absolute shadow-inner border border-[#d43535] text-[#d43535] text-[12px] rounded-[4px]">
          <div className="flex items-center">{error?.message}</div>
          <div onClick={handleClose}>
            <CloseIcon size="small" />
          </div>
        </div>
      )}
      {(error?.type !== "fileSize" &&
        (error?.type === "image/jpg" ||
          error?.type === "image/jpg;" ||
          error?.type === "image/png;" ||
          error?.type === "image/jpeg" ||
          error?.type === "image/png")) ||
        ((error?.type === "nullable" || error?.type === "optionality") && (
          <p className="text-[#d43535] text-[12px] pl-4 pt-1">
            {error?.message}
          </p>
        ))}
      {error?.type === "fileSize" ? (
        <Button
          className="mt-2"
          onClick={handleCompressFile}
          variant="outlined"
        >
          Compress the Image
        </Button>
      ) : (
        ""
      )}
    </div>
  );
};

export default CropImageField;

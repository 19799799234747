import axios from "axios";
import { BASE_URL_NODE } from "../../config";

export const TtpFormApi = async (data) => {
  const params1 = data.queryKey[1];
  return await axios.get(
    `${BASE_URL_NODE}/ttpdeskapplications`,
    { params: params1 }
  );
};

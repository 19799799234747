import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  ttp_all_data: [],
};
export const AnnounceTTPData = createSlice({
  name: "ttp_all_data",
  initialState: initialState,
  reducers: {
    ttpalldata: (state, action) => {
    //   state.Logindata = action.payload;
      return {
        ...state,
       ttp_all_data:action.payload
      }
    },
  },
});
export const {  ttpalldata } = AnnounceTTPData.actions;
export default AnnounceTTPData.reducer;
import React, { useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { useController } from "react-hook-form";

import { PhoneCodeApi } from "../../../apis/phonecodeapi/PhoneCodeApi";

import Textinput from "../../../commomComponents/form/textinputfield/Textinput";

export const Phonecodecomponent = ({
  id,
  name,
  error,
  className,
  getoptionpromise = () => {},
  handleNameChange = () => {},
  params,
  ...rest
}) => {
  const {
    field: { value },
  } = useController({ name: "country" });

  const {
    field: { onChange },
  } = useController({ name: "country_code" });

  const { data } = useQuery(["phone", value], PhoneCodeApi, {
    select: (data) => data.data[value],
  });

  useEffect(() => {
    if (data) {
      onChange("+" + data);
    }
  }, [data]);

  return (
    <div>
      <div className="flex">
        <Textinput
          name={name}
          params={value}
          disabled={true}
          className="w-[68px]"
          size="small"
          onChange={handleNameChange}
          id={id}
        />
      </div>
    </div>
  );
};

import React from "react";
import * as yup from "yup";
import swal from "sweetalert";
import { Navigate } from "react-router-dom";

import { useNavigate } from "react-router-dom";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useDispatch } from "react-redux";
import { Button, CircularProgress } from "@mui/material";

import logo from "../../assets/images/artofliving_logo.png";

import Formfield from "../../commomComponents/form/Formfield";
import Textinput from "../../commomComponents/form/textinputfield/Textinput";

import { getRole } from "../../apis/auth/RoleApi";
import { LoginApi } from "../../apis/auth/LoginApi";
import { CountryDropdownApi } from "../../apis/countrydropdownapi/CountryDropdownApi";

import { RoleData } from "../../redux/reducer/roledata/RoleReducer";
import { setCurrentStep } from "../../redux/reducer/StepperReducer";

import GetRole from "../getrole/GetRole";
import CountryDropdownWithoutIndia from "../../commomComponents/form/singleselectdropdown/CountryDropdownWithoutIndia";

export default function LoginLayout() {
  const queryClient = useQueryClient();
  const { mutateAsync, isLoading } = useMutation(LoginApi);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  dispatch(setCurrentStep({ currentStep: 1 }));

  const onSubmit = async (data) => {
    var formdata = {
      username: data.Email,
      password: data.Password,
      db_country: data.country_name,
      application_key: "123",
    };

    try {
      const { data } = await mutateAsync(formdata);
      // console.log(data?.contact?.name,data?.contact?.phone,"response dataaaa...............................")
      const { token } = data;

      if (token) {
        localStorage.setItem("token", token);
        localStorage.setItem("user", JSON.stringify(formdata));

        try {
          const { data: countryresponse } = await CountryDropdownApi();
          const countryName = countryresponse[formdata.db_country];

          const params = {
            email: formdata.username,
            country: countryName,
            name: data?.contact?.name,
            phone: data?.contact?.phone
          };

          const { data: response } = await getRole(params);
          queryClient.resetQueries(); 
          queryClient.invalidateQueries();
          const { role } = response;
          localStorage.setItem("role", JSON.stringify(role));
          dispatch(RoleData(role));
          if (role === 1) {
            return <GetRole />;
          } else if (role === 2) {
            return <Navigate to="/RecommendedTeacher_page1" />;
          } else if (role === 3) {
            return <Navigate to="/EvaluatorPage1" />;
          } else if (role === 4) {
            return <Navigate to="/sahaj_coordinator_page1" />;
          } else if (role === 5) {
            return <Navigate to="/country_Coordinatorpage1" />;
          } else if (role === 6) {
            return <Navigate to="/india_sahaj_Coordinatorpage1" />;
          } else if (role === 7) {
            return <Navigate to="/TtpDeskPage1" />;
          } else {
            return <Navigate to="/" />;
          }
        } catch (error) {
          console.log(error, ".....");
          swal("something went wrong");
        }
      } else {
        await swal("Invalid Email/Password/Country Name", { icon: "error" });
        navigate(0);
      }
    } catch (error) {
      await swal("Invalid Email/Password/Country Name", { icon: "error" });
      navigate(0);
    }
  };

  const schema = yup.object().shape({
    Email: yup.string().email().required(),
    Password: yup.string().required(),
  });

  const handlePassword = () => {
    navigate("/Forgot_Password");
  };

  return (
    <div id="login-page" className="mt-[40px]">
      <div className="flex flex-col w-[100%] h-auto" id="login-page-navbar">
        <div className="m-auto" id="form-section">
          <div
            className="flex flex-col w-[460px] h-auto shadow-lg items-center justify-center p-6 rounded-[5px] bg-[white]"
            id="login-form-section"
          >
            <img src={logo} alt="logo" id="logo-img"></img>

            <hr className="w-[420px] mt-4" id="divider" />

            <Formfield onSubmit={onSubmit} schema={schema}>
              <div id="form-feilds">
                <div className="text-[#898989] mt-4" id="email-label">
                  Email
                </div>
                <Textinput
                  name="Email"
                  label="Email"
                  className="w-[420px] mt-4"
                  size="small"
                  id="email-input"
                />
                <div id="login-note1">
                  <i
                    className="font-semibold text-[red] text-[12px]"
                    id="login-note"
                  >
                    ***Login with Harmony Credentials
                  </i>
                </div>
              </div>

              <div id="login-password-fields">
                <div className="text-[#898989] mt-4" id="password-label">
                  Password
                </div>
                <Textinput
                  name="Password"
                  label="Password"
                  className="w-[420px] mt-8"
                  size="small"
                  type="password"
                  id="password-input"
                />
              </div>

              <div id="login-country-fields">
                <div className="text-[#898989] mt-4" id="country-label">
                  Select Country
                </div>
                <CountryDropdownWithoutIndia
                  name="country_name"
                  label="Select Country"
                  className="w-[420px] h-[40px] mt-2"
                  getoptionpromise={CountryDropdownApi}
                  getOptionValue={(options, opt) => opt}
                  id="country-dropdown"
                />
              </div>

              {/* <ImageField
                name="image"
                type="file"
                className="w-[420px] mt-8"
                size="small"
              /> */}

              <div
                className="flex w-full justify-end py-4 text-[18px] text-[#1976d2]"
                id="form-forgotfield"
              >
                <div
                  onClick={handlePassword}
                  className="cursor-pointer"
                  id="forgot-password"
                >
                  Forgot Password?
                </div>
              </div>

              <Button
                variant="contained"
                className="w-[420px] h-[40px] text[16px]"
                type="submit"
                disabled={isLoading}
                id="login-submit-button"
              >
                {isLoading ? <CircularProgress size={24} /> : "Log In"}
              </Button>
            </Formfield>
          </div>
        </div>
      </div>
    </div>
  );
}

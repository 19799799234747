export const List1 = [
  "date_of_birth",
  "name",
  "image",
  "gender",
  "phone",
  "country",
  "state",
  "email",
  "postal_code",
  "country_code",
  "city",
  "address",
];
export const List2 = [
  "physical",
  "medical_treatment",
  "psychiatric_treatment",
  "vegetarian_diet",
  "heard",
  "read",
  "advance_program",
  "blessings_program",
  "sanyam_program",
  "advance_program_details",
  "blessings_program_details",
  "sanyam_program_details",
];
export const List3 = [
  "teacher_since",
  "teacher_type",
  "programs_taught",
  "hp_last_year_count",
  "pax_last_year_count",
];

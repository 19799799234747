import { BASE_URL_NODE } from "../../config";

export const AnnounceUpdateApi = async (dataall) => {
  const res = await fetch(`${BASE_URL_NODE}/update_ttp_registration_status`, {
    method: "POST",
    body: JSON.stringify(dataall),
    headers: {
      "Content-type": "application/json",
    },
  });
  return res;
};
import axios from "axios";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";

import Formfield from "../../commomComponents/form/Formfield";
import Stepper from "../../commomComponents/stepper/Stepper";

import Page1 from "./page1/Page1";
import Page2 from "./page2/Page2";
import Page3 from "./page3/Page3";
import Page4 from "./page4/Page4";

import { BASE_URL_NODE } from "../../config";

import { validationSchema } from "./validationSchema";

import { PreProcessApplicantDefaultValues } from "../../utils/PreProcessApplicantDefaultValues";

export const TtcApplicationFormIndexPage = () => {
  const {
    formState: { errors },
  } = useForm();

  const [responseData, setResponseData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    fetchData();
  }, []);

  // <-------------------api call for get application data--------------------->

  var harmony_email =
    JSON.parse(localStorage.getItem("user"))?.username ||
    JSON.parse(localStorage.getItem("india_user"))?.email;

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL_NODE}/application/${harmony_email}`
      );
      var x = response.data;
      Object.keys(x).forEach((key) => {
        if (x[key] === "None") {
          delete x[key];
        }
      });
      x["date_of_birth"] = x["date_of_birth"]?.split("-").reverse().join("-");
      x["teacher_since"] = x["teacher_since"]?.split("-").reverse().join("-");
      x["draft_timestamp"] = x["draft_timestamp"]
        ?.split("-")
        .reverse()
        .join("-");
      x = PreProcessApplicantDefaultValues(x);
      setResponseData(x);
      setIsLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  const todaydate = new Date();

  const formatMonth = (month) => {
    return month < 10 ? `0${month}` : month;
  };
  const formatDate = (date) => {
    return date < 10 ? `0${date}` : date;
  };

  const defaultdate =
    todaydate && new Date(todaydate)
      ? new Date(todaydate)?.getFullYear() +
        "-" +
        formatMonth(new Date(todaydate)?.getMonth() + 1) +
        "-" +
        formatDate(new Date(todaydate)?.getDate())
      : "";

  var status = responseData.application_status;

  return (
    <div>
      <div>
        <div>
          {isLoading ? (
            <div>Loading...</div>
          ) : (
            <div>
              <div>
                <Formfield
                  schema={validationSchema}
                  defaultValues={
                    status === 1
                      ? responseData
                      : {
                          start_date: { defaultdate },
                          // date_of_birth:'mm-dd-yyyy'
                        }
                  }
                >
                  <Stepper stepsData={stepsData} />
                </Formfield>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default TtcApplicationFormIndexPage;

const stepsData = [
  {
    stepComponent: <Page1 />,
  },
  {
    stepComponent: <Page2 />,
  },
  {
    stepComponent: <Page3 />,
  },
  {
    stepComponent: <Page4 />,
  },
];

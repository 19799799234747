/**
 * This Dropdown is different from the normal dropdown beacause in this we can able to search and select the particulat option
 * If their is no data, then it show in the dropdown "No Data"
 */
import * as React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { useQuery } from "@tanstack/react-query";
import { useController } from "react-hook-form";
import { FormControl, FormHelperText } from "@mui/material";

export default function SearchableDropdown({
  register,
  label,
  name,
  control,
  className,
  options,
  placeholder,
  params,
  getoptionpromise = () => {},
  getOptionLabel,
  getOptionValue,
  ...rest
}) {
  const { data: option = [] } = useQuery([name, params], getoptionpromise, {
    select: (data) => data?.data,
  });


  const {
    field: { value, onChange },
    fieldState: { error },
  } = useController({
    name,
    control,
    rules: { required: true },
  });

  return (
    <div>
      <FormControl error={false}>
        <Autocomplete
          name={name}
          disablePortal
          id="combo-box-demo"
          onChange={(e, v) => {
            onChange(v?.email);
          }}
          value={value}
          options={option}
          getOptionLabel={(option) => option?.email}
          getOptionValue={option}
          size="small"
          sx={{ width: 420 }}
          renderInput={(params) => <TextField {...params} label={label} />}
        />
        <FormHelperText>
          {error && <div className="text-[#D43535]">{error.message}</div>}
        </FormHelperText>
      </FormControl>
    </div>
  );
}


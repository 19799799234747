import "./TtpDeskPage1.css";

import { useQuery, useQueryClient } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";

import { TtpFormApi } from "../../apis/ttpformapi/TtpFormApi";

import { AnnouncedTTPDetailsApi } from "../../apis/announcedttpdetailsapi/AnnouncedTTPDetailsApi";
import CustomNoRowsOverlay from "../../commomComponents/form/noformdata/NoFormData";
import Navbar from "../../components/navbar/Navbar";
import { autoAdjustColumnWidth, convertToExcel, saveXLSX } from "../../utils/XLSXHandling";

const handleKeyDown = (e, name) => {
  if (name === "phone") {
    const keyCode = e.keyCode || e.which;
    if (keyCode === 8 || keyCode === 46 || (keyCode >= 37 && keyCode <= 40)) {
      return;
    }
    if (keyCode < 48 || keyCode > 57) {
      e.preventDefault();
    }
  }
};

function Table() {
  const queryClient = useQueryClient();

  const [value, setValue] = useState({});


  const [selectedOptionId, setSelectedOptionId] = useState(0);

  const [options, setOptions] = useState([]);

  const name = options[selectedOptionId]?.name || '';

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await AnnouncedTTPDetailsApi()

        const fetchedOptions = await response.data;

        const data = fetchedOptions.map((option) => ({
          ...option,
          startDate: new Date(option.start_date),
        }));

        const sortedOptions = data.sort((a, b) => b.startDate - a.startDate);
        setOptions(sortedOptions);
      } catch (error) {
        console.error("Error fetching options:", error);
      }
    };

    fetchData();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "phone") {
      const digitsOnly = value.replace(/\D/g, "");
      setValue((prevState) => ({ ...prevState, [name]: digitsOnly }));
    } else {
      if (value.length >= 3) {
        setValue((prevState) => ({ ...prevState, [name]: value }));
      } else {
        const newvalue = { ...value };
        delete newvalue[name];
        setValue(newvalue);
      }
    }
  };

  const handleSelect = (e) => {
    const { name, value: selectedValue } = e.target;
    if (selectedValue === "true" || selectedValue === "false") {
      setValue((prevState) => ({ ...prevState, [name]: selectedValue }));
    }
    if (selectedValue === "select") {
      const newvalue = { ...value };
      delete newvalue[name];
      setValue(newvalue);
    }
  };

  const { data, isLoading } = useQuery(["form-data", value], TtpFormApi, {
    select: (data) => data?.data,
  });

  const applicantsDetails = data ? data.filter(applicant=>applicant.ttp_id === options[selectedOptionId]?.id) : [];
  const isDownloadButtonVisible = !isLoading && data && data.length > 0;
  return (
    <div className="w-[100%] h-screen flex flex-col">
      <div>
        <Navbar track={2}></Navbar>
      </div>
      <div className="flex justify-between">
        <div className="flex justify-start">
          <select value={selectedOptionId} onChange={(e) => setSelectedOptionId(parseInt(e.target.value))} class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 m-5 rounded">
            {/* <option value="" class="invisible">Select an option</option> */}
            {options.map((option, index) => (
              <option key={index} value={index} className="bg-white text-gray-700 hover:bg-blue-500 hover:text-white">
                {option.name} | {option.startDate.toLocaleDateString('en-US', { month: 'short', day: '2-digit' })} {option.startDate.getFullYear()}
              </option>
            ))}
          </select>
        </div>

        <div class="flex justify-end">
          {isDownloadButtonVisible && (
            <button onClick={async () => await downloadExcel(applicantsDetails)} class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 m-5 rounded">
              Download
            </button>
          )}
        </div>
      </div>
      <p className="ml-10">{applicantsDetails.length} Applicants</p>

      <div className="p-4 mt-0 border-1 border-[black] overflow-y-auto scrollbar">
        <div className="w-full h-[700px] overflow-x-auto border-2 border-inherit">
          <table className="table-auto">
            <thead>
              <th className="thead_th">Applications</th>
              {columns?.map((item, Index) => (
                <th key={Index} className="thead_th">
                  <div className="flex flex-col gap-2 p-1">
                    <div>{item.name}</div>
                    {item.search === true ? (
                      <div>
                        <input
                          type="search"
                          name={item.dbname}
                          className="w-[160px] h-[40px] p-1 rounded-lg"
                          placeholder={item.placeholder}
                          onChange={handleInputChange}
                          onKeyDown={(e) => handleKeyDown(e, item.dbname)}
                        ></input>
                      </div>
                    ) : (
                      ""
                    )}
                    {item?.dropdown === true ? (
                      <div>
                        <select
                          name={item.dbname}
                          className="h-[40px] w-[150px] p-1 rounded-lg"
                          onChange={handleSelect}
                        >
                          <option value="select">Select</option>
                          <option value={true}>Recommended</option>
                          <option value={false}>Not Recommended</option>
                        </select>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </th>
              ))}
            </thead>
            {isLoading ? (
              <div>Loading.......</div>
            ) : data?.length === 0 ? (
              <tbody>
                <tr>
                  <td
                    colSpan={columns.length - 15}
                    className="ml-[300px] border-none"
                  >
                    <div className="mt-32 text-[20px] font-semibold">
                      <CustomNoRowsOverlay theory="No application found" />
                    </div>
                  </td>
                </tr>
              </tbody>
            ) : (
              <tbody className="ttpdesk_tbody">
                {applicantsDetails.map(applicant => {
                    return (
                      <tr>
                        <td id="tbody_td">
                          <a
                            href={`TtpDeskPage2/?email=${applicant?.harmony_email}`}
                            className="text-[skyblue] underline cursor-pointer"
                            target="_blank"
                            rel="noopener noreferrer"
                            onClick={(e) => {
                              e.preventDefault();
                              window.open(`/TtpDeskPage2/?email=${applicant?.harmony_email}`, "_blank");
                              queryClient.invalidateQueries({
                                queryKey: ["application-data-ttpdesk"],
                              });
                            }}
                          >
                            View Application
                          </a>
                        </td>
                        {columns?.map((info, id) => (
                          <td>
                            {info.render
                              ? info.render(applicant[info.dbname])
                              : applicant[info.dbname]}
                          </td>
                        ))}
                      </tr>)
                  
                })}

              </tbody>
            )}
          </table>
        </div>
      </div>
    </div>
  );
  async function downloadExcel(data) {
    // Filter out applicants that don't have ttp_id
    const validApplicants = data.filter(applicant => applicant?.ttp_id !== null || undefined ||'');

    if (validApplicants.length === 0) {
      console.error("No data available to download.");
      return;
    }
    const filteredData = data.map(applicant => {
      const { ttp_id, ...rest } = applicant;
      return rest;
    });

    // download excel sheet using filteredData : pending
    const sheetName = 'Applicants Lists';
    const workbook = await convertToExcel(sheetName, filteredData, [], [
      "teacher_recommendation",
      "evaluator_recommendation"
    ], []);
    autoAdjustColumnWidth(workbook.worksheets[0],
      [
        "Programs Taught",
        "Guru Pooja Audio Link",
        "Guru Pooja Video Link",
        "Guru Pooja Phase1",
        "Guru Pooja Phase2",
        "Recommending Teacher Remarks",
        "Evaluator Remarks"
      ])
    saveXLSX(workbook, `${name}`);
  }
}

export default Table;

const columns = [
  {
    name: "Name",
    dbname: "name",
    search: true,
    dropdown: false,
    placeholder: "enter 3 char to search",
    // onkeydown: false,
  },
  {
    name: "Email",
    dbname: "email",
    search: true,
    dropdown: false,
    placeholder: "enter 3 char to search",
    // onkeydown: false,
  },
  {
    name: "Mobile",
    dbname: "phone",
    search: true,
    dropdown: false,
    placeholder: "enter 3 digits to search",
    onKeyDown: handleKeyDown,
    // onKeyDown: true,
  },
  {
    name: "Country",
    dbname: "country",
    search: true,
    dropdown: false,
    placeholder: "enter 3 char to search",
    // onkeydown: false,
  },
  {
    name: "Teacher recommendation",
    dbname: "teacher_recommendation",
    render: (value) =>
      value
        ? "Recommended"
        : value === false
          ? "Not Recommended"
          : value === "null"
            ? ""
            : "",
    search: false,
    dropdown: true,
    placeholder: "",
  },
  {
    name: "Evaluator recommendation",
    dbname: "evaluator_recommendation",
    render: (value) =>
      value
        ? "Recommended"
        : value === false
          ? "Not Recommended"
          : value === "null"
            ? ""
            : "",
    search: false,
    dropdown: true,
    placeholder: "",
  },
  {
    name: "Recommending teacher remarks",
    dbname: "recommending_teacher_remarks",
    search: false,
    dropdown: false,
    placeholder: "",
  },
  {
    name: "Evaluator remarks",
    dbname: "evaluator_remarks",
    search: false,
    dropdown: false,
    placeholder: "",
  },
  {
    name: "State",
    dbname: "state",
    search: false,
    dropdown: false,
    placeholder: "",
  },
  {
    name: "City",
    dbname: "city",
    search: false,
    dropdown: false,
    placeholder: "",
  },
  {
    name: "Teacher Since",
    dbname: "teacher_since",
    search: false,
    dropdown: false,
    placeholder: "",
  },
  {
    name: "Full time/Part Time Teacher",
    dbname: "teacher_type",
    search: false,
    dropdown: false,
    placeholder: "",
  },
  {
    name: "Programs Taught",
    dbname: "programs_taught",
    search: false,
    dropdown: false,
    placeholder: "",
  },
  {
    name: "Number of Happiness Program",
    dbname: "hp_last_year_count",
    search: false,
    dropdown: false,
    placeholder: "",
  },
  {
    name: "Number of participants",
    dbname: "pax_last_year_count",
    search: false,
    dropdown: false,
    placeholder: "",
  },
  {
    name: "Numbe Of Sahaj Samadhi Organized",
    dbname: "sahaj_program_count",
    search: false,
    dropdown: false,
    placeholder: "",
  },
  {
    name: "Number of participants registered for Sahaj Samadhi",
    dbname: "sahaj_participant_count",
    search: false,
    dropdown: false,
    placeholder: "",
  },
  {
    name: "Guru Puja Phase 1 details",
    dbname: "guru_pooja_phase1",
    search: false,
    dropdown: false,
    placeholder: "",
  },
  {
    name: "Guru Puja Phase 2 details",
    dbname: "guru_pooja_phase2",
    search: false,
    dropdown: false,
    placeholder: "",
  },
  {
    name: "Guru Puja Audio Link",
    dbname: "guru_pooja_audio_link",
    search: false,
    dropdown: false,
    placeholder: "",
  },
  {
    name: "Guru Puja Video Link",
    dbname: "guru_pooja_video_link",
    search: false,
    dropdown: false,
    placeholder: "",
  },];
import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  evaluationpagedata: "",
};
export const EvaluationReducer = createSlice({
  name: "evaluationpagedata",
  initialState: initialState,
  reducers: {
    PageFormData: (state, action) => {
      state.evaluationpagedata = action.payload;
    },
  },
});
export const { PageFormData } = EvaluationReducer.actions;
export default EvaluationReducer.reducer;

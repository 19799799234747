import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  Logindata: "",
};
export const LoginReducer = createSlice({
  name: "login_data",
  initialState: initialState,
  reducers: {
    LoginFormData: (state, action) => {
    //   state.Logindata = action.payload;
      return {
        ...state,
        Logindata:action.payload
      }
    },
  },
});
export const { LoginFormData } = LoginReducer.actions;
export default LoginReducer.reducer;

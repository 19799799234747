import React from "react";
import PagesFooter from "../PagesFooter";
import { useController, useFormContext } from "react-hook-form";

import Textinput from "../../../commomComponents/form/textinputfield/Textinput";
import Radiogroup from "../../../commomComponents/form/radiobuttons/Radiogroup";
import ImportantNote from "../../../commomComponents/form/importantnote/ImportantNote";
import SelectDropdown from "../../../commomComponents/form/singleselectdropdown/SelectDropdown";
import CheckboxesComponent from "../../../commomComponents/form/groupcheckboxes/CheckboxesComponent";

import { CountryDropdownApi } from "../../../apis/countrydropdownapi/CountryDropdownApi";
import TextInputWithApi from "../../../commomComponents/form/textinputfield/TextInputWithApi";

const Page4 = () => {
  const options = [
    { label: "Daily", value: "Daily" },
    { label: "Weekly", value: "Weekly" },
    { label: "Monthly", value: "Monthly" },
  ];

  const option1 = [
    { label: "Yes", value: 1 },
    { label: "No", value: 0 },
  ];

  const {
    formState: { isSubmitted },
  } = useFormContext();

  const {
    field: { value },
  } = useController({ name: "declaration" });

  

  return (
    <div id="page4">
      <div>
        <div className="flex flex-col gap-6 h-full w-[670px] pt-2">
          <div className="mt-3 flex">
            <div className="flex w-72">
              Do you practice Sahaj Samadhi Meditation?
            </div>
            <Radiogroup
              name="sahaj_practice"
              label="Practice"
              options={options}
              direction={true}
            ></Radiogroup>
          </div>

          <div className="flex items-center">
            <div className="flex w-72">Guru Puja Phase 1 details*:</div>
            <Textinput
              name="guru_pooja_phase1"
              label="phase1"
              placeholder="Course Date, Venue, Teacher Name"
              className="w-[300px]"
              size="small"
            />
          </div>

          <div className="flex items-center">
            <div className="flex w-72">Guru Puja Phase 2 details*:</div>
            <Textinput
              name="guru_pooja_phase2"
              placeholder="Course Date, Venue, Teacher Name"
              className="w-[300px]"
              size="small"
            />
          </div>

          <div className="flex items-center">
            <div className="flex w-72">
              How many public Gurupujas have you done?*
            </div>
            <Textinput
              name="public_guru_pooja_count"
              label="GuruPujasDone"
              className="w-[300px]"
              size="small"
              type="number"
              getOptionValue={(value) => parseInt(value)}
            />
          </div>

          <div className="flex items-center gap-8">
            <div className="flex w-64 border-2 border-[#000000] p-1">
              Are you thorough with Guru Puja chanting (Pronunciation), Meaning
              and Performance?*
            </div>
            <Radiogroup
              name="guru_pooja_chanting"
              label="GuruPujaDetail"
              options={option1}
              direction={true}
              getOptionValue={(value) => parseInt(value)}
            />
          </div>

          <div className="flex items-center gap-10">
            <div className="flex flex-col w-64">
              <div className="flex">Gurupuja Audio Link*:</div>
              <div className="text-[14px]">
                (Note: Audio chanting should be clear)
              </div>
            </div>
            <div className="flex">
              <Textinput
                name="guru_pooja_audio_link"
                label="AudioFile"
                className="w-[300px]"
                size="small"
              />
              <ImportantNote note="audio" />
            </div>
          </div>

          <div className="flex items-center gap-10">
            <div className="flex flex-col w-64">
              <div className="flex">Gurupuja Video Link*:</div>
              <div className="text-[14px]">
                (Note: Video should be visible properly during performance else
                your application may get rejected)
              </div>
            </div>
            <div className="flex justify-center">
              <Textinput
                name="guru_pooja_video_link"
                label="VideoFile"
                className="w-[300px]"
                size="small"
              />
              <ImportantNote note="video" />
            </div>
          </div>

          <div className="flex flex-col gap-4">
            <div className="">Seva*:</div>
            <div className="flex gap-2">
              <div className="flex w-72">
                Number of Sahaj Samadhi Programs organized:
              </div>
              <Textinput
                name="sahaj_program_count"
                label="No. of Sahaj Samadhi organized"
                className="w-[300px]"
                size="small"
                type="number"
                getOptionValue={(value) => parseInt(value)}
              />
            </div>
            <div className="flex gap-2">
              <div className="flex w-72">
                Number of Participants Registered for Sahaj Samadhi Meditation:
              </div>
              <Textinput
                name="sahaj_participant_count"
                label="No Of Participates for sahajsamadhi"
                className="w-[300px]"
                size="small"
                type="number"
                getOptionValue={(value) => parseInt(value)}
              />
            </div>
          </div>

          <div className="flex flex-col gap-4">
            <div className="font bold">
              Why would you like to become a Sahaj Samadhi Meditation Program
              teacher? <br></br>Explain briefly in not more than 500
              characters.*
            </div>
            <Textinput
              name="reason"
              label="Text Area"
              className="w-[600px]"
              size="small"
              rows={3}
              lines={true}
            />
          </div>

          <div className="flex flex-col gap-4">
            <div className="font-bold">Recommending Teacher*:</div>
            <div className="flex gap-2">
              <div className="flex w-72">Name of Teacher:</div>
              <Textinput
                name="recommending_teacher_name"
                label="No Of Participates for sahajsamadhi"
                className="w-[300px]"
                size="small"
              />
            </div>

            <div className="flex gap-2 mt-4">
              <div className="flex w-72">Teacher's email address:</div>
              <div className="flex flex-col">
                <TextInputWithApi
                  name="recommending_teacher_email"
                  label="No. of Sahaj Samadhi organized"
                  className="w-[300px]"
                  size="small"
                />
                <div>
                  <i className="font-semibold text-[red] text-[12px]">
                    ***Enter Teacher's Harmony Credentials
                  </i>
                </div>
              </div>
            </div>

            <div className="flex gap-2">
              <div className="flex w-72">Teacher's Code:</div>
              <Textinput
                name="recommending_teacher_code"
                label="No. of Sahaj Samadhi organized"
                className="w-[300px]"
                size="small"
              />
            </div>
          </div>

          <div className="flex items-center mt-4" id="country-field">
            <div className="text-[16px] flex w-[295px]" id="country-label">
              Teacher's Country*
            </div>
            <SelectDropdown
              name="recommending_teacher_country"
              label="Select Country"
              placeholder="Select country"
              className="w-[300px] mt-2"
              getoptionpromise={CountryDropdownApi}
              getOptionValue={(options, opt) => options[opt]}
              id="page1-country-dropdown"
            />
          </div>

          <div className="flex flex-col">
            <div className="flex items-center h-[45px] gap-2">
              <div>Declaration*:</div>
              <div className="h-[45px] flex items-center mt-1">
                <CheckboxesComponent name="declaration" />
                <div>
                  {!value && isSubmitted && (
                    <div className="text-[#d32f2f] text-[12px] -ml-5">
                      Declaration field is required
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="-mt-2 text-[#898989] font-semibold">
              I here to declared that all the above information is true
            </div>
          </div>

          <div className="w-[680px] border-black border-2 text-[#898989] flex flex-col p-3">
            I understand that it is not mandatory for me to become a SSDY
            teacher at the end of Sahaj Samadhi Meditation TTP and that if
            required I will be called to repeat the Sahaj Samadhi Meditation
            TTP.
          </div>

          <div>
            <PagesFooter page={4} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Page4;

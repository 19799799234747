import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  assignEvaluator: [],
};
export const AssignEvaluatorReducer = createSlice({
  name: "assignEvaluator",
  initialState: initialState,
  reducers: {
    handleAssignEvaluator: (state, action) => {
      return {
        ...state,
        assignEvaluator: action.payload,
      };
    },
  },
});
export const { handleAssignEvaluator } = AssignEvaluatorReducer.actions;
export default AssignEvaluatorReducer.reducer;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";

import { Box } from "@mui/system";
import { Button } from "@mui/material";
import Modal from "@mui/material/Modal";
import { DataGrid } from "@mui/x-data-grid";
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";

import Navbar from "../../components/navbar/Navbar";
import { handleAssignEvaluator } from "../../redux/reducer/assignevaluatorreducer/AssignEvaluatorReducer";
import { GetParticipateEvaluator } from "../../apis/getparticipateevaluatorsapi/GetParticipateEvaluator";
import { useQuery } from "@tanstack/react-query";
import AssignEvaluatorDropdown from "./AssignEvaluatorDropdown";

function AssignEvaluator() {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [selectedRows, setSelectedRows] = useState([]);
  const { assignEvaluator } = useSelector((state) => state.assignEvaluator);

  const columns = [
    // { field: "id", headerName: "ID", width: 100 },
    { field: "ttp", headerName: "TTP", width: 200, sortable: false },
    { field: "name", headerName: "Name", width: 200, sortable: false },
    {
      field: "email",
      headerName: "Email",
      type: "email",
      width: 200,
      sortable: false,
    },
    {
      field: "evaluator_email",
      headerName: "Evaluator",
      width: 250,
      sortable: false,
    },
    {
      field: "evaluator_response",
      headerName: "Evaluation Status",
      width: 250,
      sortable: false,
      renderCell: (params) => {
        if (params?.row?.evaluator_response === 0) {
          return <div>Pending</div>;
        } else if (params?.row?.evaluator_response === 1) {
          return <div>Evaluated</div>;
        } else {
          return <div>Rejected</div>;
        }
      },
    },
  ];

  const { data = [], isLoading } = useQuery(
    ["get-participate-api"],
    GetParticipateEvaluator,
    {
      select: (data) => data?.data,
    }
  );

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleRowClick = (
    params, // GridRowParams
    event, // MuiEvent<React.MouseEvent<HTMLElement>>
    details // GridCallbackDetails
  ) => {
    console.log(params, details, "params,details");
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 470,
    height: "auto",
    bgcolor: "white",
    border: "none",
    borderRadius: 4,
    boxShadow: 24,
    padding: 4,
  };

  useEffect(() => {
    dispatch(handleAssignEvaluator(assignEvaluator));
  }, [assignEvaluator]);

  return (
    <div className="w-[100%] h-auto min-h-screen flex flex-col">
      <div>
        <Navbar track={1}></Navbar>
      </div>

      <div className="flex w-full h-auto min-h-screen items-center justify-center bg-[whitesmoke]">
        <div className="flex flex-col">
          <div className="px-8 mt-4 flex ml-auto">
            <Button
              variant="contained"
              className="w-[250px] h-[40px] text[16px]"
              size="large"
              onClick={handleOpen}
              disabled={assignEvaluator.length >= 1 ? false : true}
            >
              Assign Evaluator
            </Button>
          </div>
          <Box
            sx={{
              height: 645,
              width: "100%",
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: "RGB(106 170 234)",
                color: "white",
              },

              fontWeight: 900,
              fontSize: 16,
              padding: 4,
            }}
          >
            <DataGrid
              rows={data}
              columns={columns}
              initialState={{
                pagination: {
                  paginationModel: { page: 0, pageSize: 10 },
                },
              }}
              pageSizeOptions={[10, 25, 50, 100]}
              checkboxSelection
              onRowClick={handleRowClick}
              disableColumnMenu
              onRowSelectionModelChange={(ids) => {
                dispatch(handleAssignEvaluator(ids));
                const selectedIDs = new Set(ids);
                const selectedRowData = data.filter((row) =>
                  selectedIDs.has(row.id)
                );
                setSelectedRows(selectedRowData);
                dispatch(handleAssignEvaluator(ids));
              }}
              rowSelectionModel={assignEvaluator}
              classes={{ header: "custom-header" }}
            />
          </Box>
        </div>
        <div
          className="flex bg-[black] bg-opacity-10 rounded-lg mb-[650px] absolute left-10 top-[100px] cursor-pointer w-[45px] h-[45px] rounded-[45px] justify-center items-center"
          onClick={() => navigate(-1)}
        >
          <ArrowBackRoundedIcon color="black" size="large" />
        </div>
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <AssignEvaluatorDropdown
            handleClose={handleClose}
            selectedRows={selectedRows}
          />
        </Box>
      </Modal>
    </div>
  );
}

export default AssignEvaluator;

import { any } from "prop-types";
import React, { useState } from "react";
import "./stepper.css";
import { useSelector } from "react-redux";
import { FaFileAlt } from "react-icons/fa";
import Navbar from "../../components/navbar/Navbar";
import TextView from "../textview/TextView";
const Stepper = (props) => {


  const [complete, setComplete] = useState(false);

  const { currentStep } = useSelector((state) => state.stepper);

  const { Text_Viewer } = useSelector((state) => state.Text_Viewer);

  return (
    <div className="w-[100%] h-auto min-h-screen bg-[#f5f5f5]">
      <div className="flex h-auto">
        {" "}
        <Navbar track={1}></Navbar>
      </div>

      <div className="m-auto bg-[smokewhite]">
        <div className="flex w-full h-auto justify-center items-center overflow-y-auto scrollbar">
          <div className="flex h-auto flex-col rounded-lg shadow-lg bg-[white] px-[40px] py-[30px]">
            {Text_Viewer ? (
              <div className="flex w-[850px] h-auto">
                <TextView></TextView>{" "}
              </div>
            ) : (
              ""
            )}
            <div className="p-5 bg-[#EDE6E6] mt-4 ">
              <div className="flex gap-3 justify-start items-start">
                <div>
                  <FaFileAlt className="w-[35px] h-[35px]" />
                </div>
                <div className="text-[28px] font-bold">TTC Application</div>
              </div>
              <div className="flex items-center justify-center w-[730px] pt-1">
                Note: This TTC Application form should be filled by TTC
                applicants only. Please answer all questions truthfully and with
                detail and clarity. Your responses will remain confidential and
                will only be reviewed by the TTC Trainers and TTC desk team
              </div>
            </div>

            <div className="Steps w-auto m-auto mt-[9px] p-5">
              {props.stepsData?.map((_step, i) => (
                <div
                  key={i}
                  className={`step-item ${currentStep === i + 1 && "active"} 
                ${(i + 1 < currentStep || complete) && "complete"} `}
                >
                  <div className="step">
                    {i + 1 < currentStep || complete ? (
                      <div className="complete p">{i + 1}</div>
                    ) : (
                      i + 1
                    )}
                  </div>
                </div>
              ))}
            </div>
            <div className="flex justify-center flex-1 overflow-y-auto scrollbar w-full">
              {props.stepsData[currentStep - 1].stepComponent}
            </div>
            <div className="flex justify-start w-full gap-10 px-[40px]"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Stepper;

Stepper.propTypes = {
  stepsData: any,
};

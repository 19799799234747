import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  page2data: "",
};
export const Page2Reducer = createSlice({
  name: "page2data",
  initialState: initialState,
  reducers: {
    Page2FormData: (state, action) => {
      state.page2data = action.payload;
    },
  },
});
export const { Page2FormData } = Page2Reducer.actions;
export default Page2Reducer.reducer;
import React, { useState } from "react";
import {
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel,
  FormLabel,
} from "@mui/material";
import LoginLayout from "./LoginLayout";
import IndiaLoginPage from "./IndiaLoginPage";
import Navbar from "../navbar/Navbar";

function SelectCountry() {
  const [selectedOption, setSelectedOption] = useState("RestOfWorld");

  const handleRadioChange = (event) => {
    setSelectedOption(event.target.value);
  };

  return (
    <div className="flex h-auto min-h-screen bg-[whitesmoke]">
      <div className="flex flex-col w-[100%]">
        <div id="navbar-section">
          {" "}
          <Navbar></Navbar>
        </div>
        <div className="flex justify-center items-center mt-16">
          <FormControl component="fieldset">
            <div className="flex w-[460px] justify-between">
              <div className="flex justify-start items-center">
                <div className="text-[black] font-[600]">
                  Select your country:
                </div>
              </div>
              <div>
                <RadioGroup
                  aria-label="country"
                  name="country"
                  value={selectedOption}
                  onChange={handleRadioChange}
                  row={true}
                >
                  <FormControlLabel
                    value="India"
                    control={<Radio />}
                    label="India"
                  />
                  <FormControlLabel
                    value="RestOfWorld"
                    control={<Radio />}
                    label="Rest Of The World"
                  />
                </RadioGroup>
              </div>
            </div>
          </FormControl>
        </div>

        {selectedOption === "India" ? (
          // Render the login page for India
          <IndiaLoginPage />
          // <div>hekkki</div>
        ) : (
          // Render the page for Rest of Other Countries
          <LoginLayout />
        )}
      </div>
    </div>
  );
}

export default SelectCountry;

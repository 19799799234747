import { BASE_URL_NODE } from "../../config";

export const AssignEvaluatorApi = async (dataAll) => {
  const res = await fetch(`${BASE_URL_NODE}/update_evaluator`, {
    method: "POST",
    body: JSON.stringify(dataAll),
    headers: {
      "Content-type": "application/json",
    },
  });
  return res;
};
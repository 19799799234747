import React from "react";
import { TextField } from "@mui/material";
import { useController } from "react-hook-form";
function Textinput({
  id,
  register,
  label,
  name,
  control,
  className,
  lines,
  rows,
  type,
  max,
  getOptionLabel,
  getOptionValue = (val) => val,
  disabled,
  inputprops,
  ...rest
}) {
  const {
    field: { value, onChange },
    fieldState: { error },
  } = useController({
    name,
    control,
  });

  return (
    <div className="">
      <TextField
        onChange={(e) => {
          onChange(getOptionValue(e.target.value));
        }}
        id={id}
        value={value}
        error={error}
        helperText={error && error?.message}
        className={className}
        multiline={lines}
        maxRows={max}
        rows={rows}
        type={type}
        onKeyDown={(evt) =>
          ["e", "E", "+", "-"].includes(evt.key) &&
          type === "number" &&
          evt.preventDefault()
        }
        disabled={disabled}
        onWheel={(e) => e.target.blur()}
        inputProps={
          type === "number" ? { inputMode: "numeric", pattern: "[0-9]*" } : {}
        }
        {...rest}
      />
    </div>
  );
}
export default Textinput;

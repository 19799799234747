/**
 * this component is used to display the error after we click the submit button
 * example if we don't fill the name column, click submit button, it will show that name field as error
 */
import React from "react";
import labeldata from "./labels.json";
import { useSelector } from "react-redux";
import { List1, List2, List3 } from "./Lists";

function TextView() {
  var { form_all_errors } = useSelector((state) => state?.form_all_errors);

  let pageErrors = {};

  const inValidFields = Object.keys(form_all_errors).filter(
    (fieldName) =>
      (form_all_errors[fieldName] === undefined && labeldata[fieldName]) ||
      form_all_errors[fieldName] === "" ||
      Number.isNaN(form_all_errors[fieldName])
  );

  if (form_all_errors["image"] === null) {
    inValidFields.push("image");
  }


  if (form_all_errors["declaration"] === false) {
    inValidFields.push("declaration");
  }

  if (
    form_all_errors?.physical?.walking === false ||
    form_all_errors?.physical?.climbing === false ||
    form_all_errors?.physical?.seva === false ||
    form_all_errors?.physical?.games === false ||
    form_all_errors?.physical?.sadhana === false ||
    form_all_errors?.physical?.asanas === false
  ) {
    inValidFields.push("physical");
  }

  if (
    form_all_errors?.heard?.heard_ashtavakra === false ||
    form_all_errors?.heard?.heard_narad_bhakti === false ||
    form_all_errors?.heard?.heard_patanjali === false ||
    form_all_errors?.heard?.heard_shiv_sutra === false
  ) {
    inValidFields.push("heard");
  }

  if (
    form_all_errors?.read?.read_ashtavakra === false ||
    form_all_errors?.read?.read_celebrating_silence === false ||
    form_all_errors?.read?.read_walk_like_a_king === false
  ) {
    inValidFields.push("read");
  }

  const apiResponse = parseInt(localStorage.getItem("api"))
  if(apiResponse === 0)
  {
    inValidFields.push("recommending_teacher_email");
  }

  //This is used for save and draft action because after save all the fields got false by default

  const inValidField = inValidFields.filter((fieldName) => {
    if (
      (form_all_errors?.physical?.walking === true ||
        form_all_errors?.physical?.climbing === true ||
        form_all_errors?.physical?.seva === true ||
        form_all_errors?.physical?.games === true ||
        form_all_errors?.physical?.sadhana === true ||
        form_all_errors?.physical?.asanas === true) &&
      fieldName === "physical"
    ) {
      return false; // Remove "physical" from the array
    }

    if (
      (form_all_errors?.read?.read_ashtavakra === true ||
        form_all_errors?.read?.read_celebrating_silence === true ||
        form_all_errors?.read?.read_walk_like_a_king === true) &&
      fieldName === "read"
    ) {
      return false; // Remove "read" from the array
    }

    if (
      (form_all_errors?.heard?.heard_ashtavakra === true ||
        form_all_errors?.heard?.heard_narad_bhakti === true ||
        form_all_errors?.heard?.heard_patanjali === true ||
        form_all_errors?.heard?.heard_shiv_sutra === true) &&
      fieldName === "heard"
    ) {
      return false; // Remove "heard" from the array
    }
    return true; // Keep all other fields in the array
  });

  // This function is used to insert page number for particular field 

  function addToPageErrors(pageErrors, pageNumber, fieldName) {
    if (!pageErrors[pageNumber]) {
      pageErrors[pageNumber] = [];
    }
    pageErrors[pageNumber].push(fieldName);
  }

  //This function is used to group the error of particular page with page number

  inValidField.forEach((fieldName) => {
    if (List1.includes(fieldName)) {
      addToPageErrors(pageErrors, 1, fieldName);
    } else if (List2.includes(fieldName)) {
      addToPageErrors(pageErrors, 2, fieldName);
    } else if (List3.includes(fieldName)) {
      addToPageErrors(pageErrors, 3, fieldName);
    } else {
      addToPageErrors(pageErrors, 4, fieldName);
    }
  });

  const errorMessage = Object.keys(pageErrors)
    .map((pageNumber) => `Page ${pageNumber}`)
    .join(", ");



  return (
    <div>
      {errorMessage && (
        <div className="flex flex-col w-full p-4">
          
          <div className="text-[17px] font-semibold text-[#D43535]">
            Please fill the following required fields:
          </div>

          {Object.keys(pageErrors).map((pageNumber) => {
            const errors = pageErrors[pageNumber];
            return (
              <div className="text-[#D43535]" key={pageNumber}>
                <b>Page {pageNumber}: </b>
                {errors.map((fieldName) => labeldata[fieldName]).join(", ")}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}

export default TextView;

import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  page1data: "",
};
export const Page1Reducer = createSlice({
  name: "page1data",
  initialState: initialState,
  reducers: {
    Page1FormData: (state, action) => {
      state.page1data = action.payload;
    },
  },
});
export const { Page1FormData } = Page1Reducer.actions;
export default Page1Reducer.reducer;

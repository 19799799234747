import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  form_all_errors: {},
};
export const FormError = createSlice({
  name: "form_all_errors",
  initialState: initialState,
  reducers: {
    ApplicationError: (state, action) => {
    //   state.Logindata = action.payload;
      return {
        ...state,
        form_all_errors:action.payload
      }
    },
  },
});
export const { ApplicationError } = FormError.actions;
export default FormError.reducer;
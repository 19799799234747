import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  ttpallDataInitial: [],
};
export const TtpAllDataReducer = createSlice({
  name: "ttpallDataInitial",
  initialState: initialState,
  reducers: {
    handleTtpalldata: (state, action) => {
      //   state.Logindata = action.payload;
      return {
        ...state,
        ttpallDataInitial: action.payload,
      };
    },
  },
});
export const { handleTtpalldata } = TtpAllDataReducer.actions;
export default TtpAllDataReducer.reducer;

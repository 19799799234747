import * as React from "react";
import "./SahajCoordinatorPage1.css";
import { useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { PendingSahajCoordinatorApi } from "../../apis/pendingsahajcoordinatorapi/PendingSahajCoordinatorApi";
import Navbar from "../../components/navbar/Navbar";
import CustomNoRowsOverlay from "../../commomComponents/form/noformdata/NoFormData";

function SahajCoordinatorPage1() {
  const email =
    JSON.parse(localStorage.getItem("user"))?.username ||
    JSON.parse(localStorage?.getItem("india_user"))?.email;

  const { data = [] } = useQuery(
    [`application-data-sahaj-recommended-${email}`, email],
    PendingSahajCoordinatorApi,
    {
      select: (data) => data?.data,
    }
  );

  const navigate = useNavigate();

  return (
    <div className="w-[100%] h-screen">
      <Navbar track={8}></Navbar>

      {data?.length === 0 ? (
        <div className="flex text-[20px] font-semibold mt-40 justify-center">
          <CustomNoRowsOverlay theory="You don't have any pending applications"></CustomNoRowsOverlay>
        </div>
      ) : (
        <div className=" flex flex-col items-center h-auto justify-center">
          <div className="flex text-[20px] font-semibold  mt-10 -ml-[300px]">
            Pending Recommendations
          </div>

          <div className="w-[600px] mt-10 ml-10">
            <table>
              <tr>
                <th>Applicant Name</th>
                <th>Country</th>
                <th>Application</th>
              </tr>
              <tbody>
                {data?.map((item) => (
                  <tr key={item?.id}>
                    <td>{item?.name}</td>
                    <td>{item?.country}</td>
                    <td>
                      <a
                        href={`/sahaj_coordinator_page2?email=${item?.harmony_email}`}
                        className="text-[skyblue] underline cursor-pointer"
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={(e) => {
                          e.preventDefault();
                          // localStorage.setItem(
                          //   "emailData",
                          //   JSON.stringify(item?.harmony_email)
                          // );
                          window.open(`/sahaj_coordinator_page2?email=${item?.harmony_email}`, "_blank");
                        }}
                      >
                        View application & recommend applicant
                      </a>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
}

export default SahajCoordinatorPage1;

import React from "react";
import { Select, MenuItem, FormControl, FormHelperText } from "@mui/material";
import { useController } from "react-hook-form";
// import info from "../commomComponents/DropdownOptions.json";

function Typedropdown({
  register,
  label,
  name,
  control,
  className,
  options,
  ...rest
}) {


  const {
    field: { value, onChange },
    fieldState: { error },
  } = useController({
    name,
    control,
    rules: { required: true },
  });

  return (
    <div className="">
      <FormControl error={false}>
        <Select
          {...rest}
          onChange={onChange}
          value={value}
          error={error}
          size="small"
          className={className}
          MenuProps={{ PaperProps: { style: { maxHeight: "200px" } } }}
        >
          {options?.map((item,Index) => {
            return (
              <MenuItem value={Object.keys(item)[0]} >
                {Object.values(item)[0]}
              </MenuItem>
            );
          })}
        </Select>
        <FormHelperText>
          {error && <div className="text-[#D43535]">{error.message}</div>}
        </FormHelperText>
      </FormControl>
    </div>
  );
}

export default Typedropdown;

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";

import MouseOverPopover from "./ResponseData";
import Navbar from "../../components/navbar/Navbar";

import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import { SearchOutlined } from "@mui/icons-material";
import { TextField, IconButton } from "@mui/material";
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";

import CustomNoRowsOverlay from "../../commomComponents/form/noformdata/NoFormData";

import { TrackApplicationsApi } from "../../apis/trackapplicationapi/TrackApplicationApi";

const columns = [
  {
    field: "ttp",
    headerName: "TTP",
    headerClassName: "super-app-theme--header",
    width: 250,
    sortable: false,
  },
  {
    field: "name",
    headerName: "Name",
    headerClassName: "super-app-theme--header",
    width: 200,
    sortable: false,
  },
  {
    field: "email",
    headerName: "Email",
    headerClassName: "super-app-theme--header",
    width: 287,
    sortable: false,
  },
  {
    field: "country",
    headerName: "Country",
    headerClassName: "super-app-theme--header",
    width: 180,
    sortable: false,
  },
  {
    field: "application_journey",
    headerName: "Status",
    headerClassName: "super-app-theme--header",
    width: 150,
    sortable: false,
    renderCell: (params) => {
      console.log(params?.row, "params data");
      if (params.row.application_status === 1) {
        return <div>Saved as Draft</div>;
      }
      if (params.row.application_status === 2) {
        return <div>Submitted</div>;
      }
    },
  },
  {
    field: "recommending_teacher",
    headerName: "Recommending Teacher",
    headerClassName: "super-app-theme--header",
    width: 180,
    sortable: false,
    renderCell: (params) => {
      if (params?.row?.recommending_teacher_response === 1) {
        return <div>Yes</div>;
      } else if (params?.row?.recommending_teacher_response === 0) {
        return <div>Pending</div>;
      } else {
        const data = {
          email: params?.row?.recommending_teacher_email,
          phone: params?.row?.recommending_teacher_phone,
        };
        return (
          <div>
            <MouseOverPopover params={data}></MouseOverPopover>
          </div>
        );
      }
    },
  },
  {
    field: "evaluator",
    headerName: "Evaluator",
    sortable: false,
    headerClassName: "super-app-theme--header",
    width: 120,
    renderCell: (params) => {
      if (params?.row?.evaluator_response === 1) {
        return <div>Yes</div>;
      } else if (params?.row?.evaluator_response === 0) {
        return <div>Pending</div>;
      } else {
        const data = {
          email: params?.row?.evaluator_email,
          phone: params?.row?.evaluator_phone,
        };
        return (
          <div>
            <MouseOverPopover params={data}></MouseOverPopover>
          </div>
        );
        // return <div>No</div>;
      }
    },
  },
  {
    field: "sahaj_coordinator",
    headerName: "Sahaj Coordinator",
    headerClassName: "super-app-theme--header",
    width: 180,
    sortable: false,
    renderCell: (params) => {
      if (params?.row?.sahaj_coordinator_response === 1) {
        return <div>Yes</div>;
      } else if (params?.row?.sahaj_coordinator_response === 0) {
        return <div>Pending</div>;
      } else {
        const data = {
          email: params?.row?.sahaj_coordinator_email,
          phone: params?.row?.sahaj_coordinator_phone,
        };
        return (
          <div>
            <MouseOverPopover params={data}></MouseOverPopover>
          </div>
        );
      }
    },
  },
  {
    field: "country_coordinator",
    headerName: "Country Coordinator",
    headerClassName: "super-app-theme--header",
    width: 180,
    sortable: false,
    renderCell: (params) => {
      if (params?.row?.country_coordinator_response === 1) {
        return <div>Yes</div>;
      } else if (params?.row?.country_coordinator_response === 0) {
        return <div>Pending</div>;
      } else {
        const data = {
          email: params?.row?.country_coordinator_email,
          phone: params?.row?.country_coordinator_phone,
        };
        return (
          <div>
            <MouseOverPopover params={data}></MouseOverPopover>
          </div>
        );
      }
    },
  },
  {
    field: "india_coordinator",
    headerName: "India Sahaj Coordinator",
    headerClassName: "super-app-theme--header",
    width: 180,
    sortable: false,
    renderCell: (params) => {
      if (params?.row?.india_sahaj_coordinator_response === 1) {
        return <div>Yes</div>;
      } else if (params?.row?.india_sahaj_coordinator_response === 0) {
        return <div>Pending</div>;
      } else {
        const data = {
          email: params?.row?.india_sahaj_coordinator_email,
          phone: params?.row?.india_sahaj_coordinator_phone,
        };
        return (
          <div>
            <MouseOverPopover params={data}></MouseOverPopover>
          </div>
        );
      }
    },
  },
  {
    field: "ttp_desk",
    headerName: "TTP Desk",
    headerClassName: "super-app-theme--header",
    width: 150,
    sortable: false,
    renderCell: (params) => {
      if (params?.row?.ttp_desk_response === 1) {
        return <div>Yes</div>;
      } else if (params?.row?.ttp_desk_response === 0) {
        return <div>Pending</div>;
      } else {
        const data = {
          email: params?.row?.ttp_desk_email,
          phone: params?.row?.ttp_desk_phone,
        };
        return (
          <div>
            <MouseOverPopover params={data}></MouseOverPopover>
          </div>
        );
      }
    },
  },
  {
    field: "register_date",
    headerName: "Registration Date",
    headerClassName: "super-app-theme--header",
    width: 200,
    sortable: false,
    renderCell: (params) => {
      if (params.row.register_date === "1900-01-01") {
        return <div>Null</div>;
      } else {
        return <div>{params.row.register_date}</div>;
      }
    },
  },
];

export default function TrackApplicattion() {
  const [searchvalue, setSearchvalue] = useState("");

  const Navigate = useNavigate();

  const handleInputChange = (e) => {
    const { value } = e.target;
    setSearchvalue(value);
  };

  const { data = [], isLoading } = useQuery(
    ["track-appliactions", searchvalue],
    TrackApplicationsApi,
    {
      select: (data) => data?.data,
    }
  );

  function CustomNoRows() {
    return (
      <div className="mt-8">
        <CustomNoRowsOverlay theory="No application found"></CustomNoRowsOverlay>
      </div>
    );
  }

  return (
    <div>
      <div className="flex h-auto min-h-screen">
        <div className="w-[100%] h-screen flex flex-col">
          <div>
            {" "}
            <Navbar track={1}></Navbar>
          </div>

          <div className="flex p-6">
            <div className="flex flex-col gap-4 overflow-y-auto scrollbar">
              <div className="flex w-[100%] items-end justify-end">
                <TextField
                  name="email"
                  className="w-[350px]"
                  onChange={handleInputChange}
                  variant="outlined"
                  placeholder="Please enter email for search"
                  InputProps={{
                    endAdornment: (
                      <IconButton>
                        <SearchOutlined />
                      </IconButton>
                    ),
                  }}
                />
              </div>
              {data.length === 0 ? (
                <div className="flex text-[20px] font-semibold justify-center items-center">
                  No application found
                </div>
              ) : (
                <div className="flex overflow-y-auto scrollbar">
                  <Box
                    sx={{
                      height: 660,
                      width: "100%",
                      "& .super-app-theme--header": {
                        backgroundColor: "RGB(106 170 234)",
                        color: "white",
                      },
                      fontWeight: 900,
                      fontSize: 16,
                    }}
                  >
                    {isLoading ? (
                      <div>Loading......</div>
                    ) : (
                      <DataGrid
                        rows={data}
                        columns={columns}
                        slots={{
                          noRowsOverlay: CustomNoRows,
                        }}
                        pageSize={10}
                        pagination={true}
                        pageSizeOptions={[10, 25, 50, 100]}
                        rowCount={data?.length}
                        disableColumnMenu
                      />
                    )}
                  </Box>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div
        className="flex bg-[black] bg-opacity-10 rounded-lg mb-[650px] absolute left-10 top-[100px] cursor-pointer w-[45px] h-[45px] rounded-[45px] justify-center items-center"
        onClick={() => Navigate(-1)}
      >
        <ArrowBackRoundedIcon color="black" size="large" />
      </div>
    </div>
  );
}
